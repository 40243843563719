import { PaginatedResponse, Brand } from '@types'

import { api } from './axios'

const list = async () => {
  const response = await api.get<PaginatedResponse<Brand>>('/brands', { params: { page: 0, pageSize: 50 } })

  return response.data
}

export const BrandsApi = {
  list,
}
