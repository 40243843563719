import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, Modal, Row, Col, Pagination } from 'antd'

import { Team, TeamType } from '@types'

import { useTeams } from '../../hooks'
import { Chip } from '../../../../components/Chip'
import { TeamItem } from '../../components'

interface Props {
  type: TeamType | TeamType[]
  onChange: (value: Team[] | null) => void
  teams?: Team[] | null
  maxTeams?: number
  visible: boolean
  onClose: () => void
}

const TeamPickerModal = (props: Props) => {
  const { type, onChange, maxTeams = 1, visible, onClose } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const types = Array.isArray(type) ? type : [type]

  const { t } = useTranslation()
  const [selectedTeams, updateSelectedTeams] = useState<Team[]>(props.teams ?? [])

  const { paginationWithFilters, data, changeFilters, changePage } = useTeams({ type: types })
  const { filters } = paginationWithFilters
  const onTeamClick = useCallback(
    (t: Team) => {
      const founded = selectedTeams.find(st => st.name === t.name)
      if (!!founded) {
        updateSelectedTeams(selectedTeams.filter(st => st.name != t.name))
      } else {
        const newTeams = [...selectedTeams, t]
        if (newTeams.length > maxTeams) {
          newTeams.splice(0, newTeams.length - maxTeams)
        }
        updateSelectedTeams(newTeams)
      }
    },
    [selectedTeams, maxTeams],
  )

  const filteredTeams = data?.data ?? []

  const label = useMemo(() => {
    if (types.length != 1) {
      return t('teams.label.all')
    } else if (types.includes(TeamType.NATIONAL_TEAM)) {
      return t('teams.label.national-team')
    } else if (types.includes(TeamType.TEAM)) {
      return t('teams.label.team')
    }

    return ''
  }, [t, types])

  return (
    <Modal
      width={600}
      open={visible}
      title={label}
      onCancel={onClose}
      maskClosable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      destroyOnClose={true}
      closable={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {t('teams.action.back')}
        </Button>,
        <Button
          type='primary'
          key='select'
          onClick={() => {
            onChange(selectedTeams)
            onClose()
          }}
        >
          {t('teams.action.continue')}
        </Button>,
      ]}
    >
      <Form.Item>
        <Input placeholder={t('teams.label.all')} onChange={e => changeFilters({ ...filters, name: e.target.value })} />
      </Form.Item>
      <Row gutter={8}>
        {selectedTeams.map(st => (
          <Chip label={st.name} onDelete={() => onTeamClick(st)} key={`chip-${st.id}`} />
        ))}
      </Row>
      <Row gutter={16} style={{ marginTop: 8 }}>
        {filteredTeams.map(t => (
          <Col span={4} key={`col-${t.id}`}>
            <TeamItem {...t} onClick={() => onTeamClick(t)} />
          </Col>
        ))}
      </Row>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        {data && filteredTeams.length > 0 && (
          <Pagination
            total={data.total}
            pageSize={data.pageSize}
            defaultCurrent={data.page + 1}
            onChange={page => changePage(page - 1)}
            showSizeChanger={false}
          />
        )}
      </div>
    </Modal>
  )
}

export { TeamPickerModal }
