import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'

const useOnDeleteSuccess = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.CHALLENGES)
    notification.success({
      message: t('challenges.notifications.delete_success'),
    })
  }

  const onDeleteRequirementSuccess = (challengeId: number) => {
    queryClient.invalidateQueries([QUERY_KEYS.CHALLENGE, challengeId])
    console.log('challengeId', challengeId)
    notification.success({
      message: t('challenges.notifications.delete_requirement_success'),
    })
  }

  return { onDeleteSuccess, onDeleteRequirementSuccess }
}

export { useOnDeleteSuccess }
