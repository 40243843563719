export type { ApiError } from './errors'
export { ErrorEnum } from './errors'

export type User = {
  id: number
  username: string
  createdAt: Date
}

export interface Account {
  id: number
  alias: string
  code: string
  createdAt: string
  currency: string
  bankName: string
}

export interface ErrorResponse {
  type: string
  message: string
}

export type BackofficeUser = {
  id: number
  username: string
  createdAt: Date
  firstName: string
  lastName: string
}

export interface Country {
  id: number
  createdAt: Date
  name: string
  code: string
  availableToTrade: boolean
  currency: string
}

export interface AuthResponse {
  user: User
  token: string
  refreshToken: string
}

export interface PaginatedResponse<T> {
  data: T[]
  total: number
  page: number
  pageSize: number
  lastPage: number
  next?: string
  previous?: string
}

export type PaginatedRequest<Filters> = {
  page?: number
  pageSize?: number
  filters: Filters
}

export interface TeamsFilters {
  id?: number[]
  name?: string
  type: TeamType[]
}

export interface ReportsFilters {
  tableId: string | undefined
  fromDate: Date | undefined
  toDate: Date | undefined
  page?: number
}
export interface ReportsFiltersParams {
  tableId?: string
  fromDate: Date | undefined
  toDate: Date | undefined
  page?: number
  pageLength?: number
}
export interface SendReportParams {
  tableId?: string
  fromDate: Date | undefined
  toDate: Date | undefined
  page?: number
  perPage: number | undefined
}

export type GameTablesReportsData = {
  id: number
  startDate: string
  endDate: string
  credits: number
  createdAt: string
  amount: number
}

export type Totalizers = {
  totalCredits: string
  totalAmount: string
}
export type UsersResponse = {
  data: AdminUser[]
  lastPage: number
  page: number
  total: number
}

export type AdminUser = {
  id: number
  createdAt: string
  updatedAt: string
  fullName?: string
  username?: string
}

export type AdminUserById = {
  createdAt: string
  firstName: string
  id: number
  lastName: string
  updatedAt: string
  username: string
}

export type UpdatePasswordParams = {
  token: string
  password: string
}

export type Coupon = {
  id: number
  code: string
  credits: number
  dueDate: Date
  createdAt: Date
  available: number
}

export type CouponsFilters = {
  page: number
  couponId: number | undefined
  code: string | undefined
  createdAtFrom: Date | undefined
  createdAtTo: Date | undefined
  dueDateFrom: Date | undefined
  dueDateTo: Date | undefined
}

export interface Brand {
  id: number
  name: string
}
export interface Team {
  id: number
  name: string
  logo: string
  type: TeamType
}

export enum ItemStatus {
  INCOMPLETE = 'Incomplete', //El usuario completó los datos del Item, pero no cargó fotos todavía.
  REVIEW = 'Review', //El usuario completó todo. Queda en revisión por parte del admin
  USERREVIEW = 'User review', //Vuelve al usuario con observaciones
  VALIDATED = 'Validated', //Aprobado por el admin
}
export enum TeamType {
  TEAM = 'Team',
  NATIONAL_TEAM = 'National_Team',
}

export enum ItemEquipmentType {
  FIRST = 'First',
  SECOND = 'Second',
  THIRD = 'Third',
}

export type Metadata = {
  id: number
  name: string
  type: TeamType
  team: Team
  year: number
  season: string
  brand: Brand
  dorsal: number
  equipmentType: ItemEquipmentType
  isSigned: boolean
  signer?: string
  history?: string
  mediaFiles: ItemMediaFiles
  item?: Item
}

export type ItemMediaFiles = {
  frontImage: string
  backImage: string
  rightArmImage: string
  leftArmImage: string
  signatureImage?: string
}

export type Item = {
  id: number
  status: ItemStatus
  metadata: Metadata
}
