import * as React from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Row, Col, Pagination } from 'antd'

import { useMedia } from '../../hooks'
import { MediaPreview } from '../../components'
import { Media, MediaType } from '../../types'

interface Props {
  onChange: (value: Media[] | null) => void
  media?: Media[] | null
  maxMedia?: number
  visible: boolean
  onClose: () => void
  type?: MediaType
}

const MediaPickerModal = (props: Props) => {
  const { onChange, maxMedia = 1, visible, onClose, type } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [page, changePage] = useState(0)
  const { data } = useMedia(page, type)
  const { t } = useTranslation()
  const [selectedItems, updateSelectedItems] = useState<Media[]>(props.media ?? [])

  const onMediaClick = useCallback(
    (m: Media) => {
      const founded = selectedItems.find(st => st.id === m.id)
      if (!!founded) {
        updateSelectedItems(selectedItems.filter(st => st.id != m.id))
      } else {
        const newItems = [...selectedItems, m]
        if (newItems.length > maxMedia) {
          newItems.splice(0, newItems.length - maxMedia)
        }
        updateSelectedItems(newItems)
      }
    },
    [selectedItems, maxMedia],
  )

  const filteredItems = data?.data ?? []

  return (
    <Modal
      width={600}
      open={visible}
      title={'Media Picker'}
      onCancel={onClose}
      maskClosable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      destroyOnClose={true}
      closable={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {t('media.action.back')}
        </Button>,
        <Button
          type='primary'
          key='select'
          onClick={() => {
            onChange(selectedItems)
            onClose()
          }}
        >
          {t('media.action.continue')}
        </Button>,
      ]}
    >
      <Row gutter={8}>
        {selectedItems.map(si => (
          <MediaPreview media={si} key={`media-${si.id}`} maxWidth={100} />
        ))}
      </Row>
      <Row gutter={16} style={{ marginTop: 8 }}>
        {filteredItems.map(media => (
          <Col span={8} key={`col-${media.id}`} style={{ marginBottom: 16 }}>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <MediaPreview
                media={media}
                key={`media-${media.id}`}
                maxWidth={'100%'}
                style={{ height: 150, borderRadius: 8, overflow: 'hidden', backgroundColor: '#aeaeae' }}
              />
            </div>
            <input
              type={'checkbox'}
              checked={!!selectedItems.find(si => si.id === media.id)}
              onClick={() => {
                onMediaClick(media)
              }}
              style={{
                position: 'absolute',
                height: 24,
                width: 24,
                bottom: 8,
                right: 16,
                zIndex: 1,
                margin: 'auto',
                display: 'block',
                cursor: 'pointer',
              }}
            />
          </Col>
        ))}
      </Row>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        {data && filteredItems.length > 0 && (
          <Pagination
            total={data.total}
            pageSize={data.pageSize}
            defaultCurrent={data.page + 1}
            onChange={page => changePage(page - 1)}
            showSizeChanger={false}
          />
        )}
      </div>
    </Modal>
  )
}

export { MediaPickerModal }
