import { useMutation } from 'react-query'

interface HookProps {
  request: (id: string | number) => Promise<void>
  onSuccess?: () => void
  onError?: (error: any) => void
}
const useBaseDelete = ({ request, onError, onSuccess }: HookProps) => {
  const { mutate: deleteRequest, isLoading: isDeleting } = useMutation(request, {
    onSuccess,
    onError,
  })

  return { deleteRequest, isDeleting }
}
export { useBaseDelete }
