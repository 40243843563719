import { useTranslation } from "react-i18next"
import { Button, Col, Row, Table, Tooltip } from "antd"
import { DeleteOutlined, EditOutlined, OrderedListOutlined, PlusOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"

import { Challenge } from "../../types"
import { StyledAlignedTitleRow, StyledTitle } from "../../components"
import { useChallenges } from "../../hooks"
import { ChallengeRequirementItemsModal, DeleteChallengeModal } from "../../modals"


export const ChallengesMain = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const {challenges, isLoading, moveToPage} = useChallenges()
    const [challengeleToDelete, setChallengeleToDelete] = useState<Challenge | undefined>()
    const [challengeToEditMetadata, setChallengeToEditMetadata] = useState<Challenge | undefined>()


    useEffect(() => {
      challenges && console.log(challenges)
    }
    ,[challenges])



    const columns: ColumnsType<Challenge> = [
        {
          title: t('challenges.table_headers.id'),
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: t('challenges.table_headers.name'),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: t('challenges.table_headers.start_date'),
          dataIndex: 'startDate',
          key: 'startDate',
          render: (fecha: Date) => (
            <span>{ new Date(fecha).toLocaleString('es-ES') }</span>
          ),
        },
        {
          title: t('challenges.table_headers.end_date'),
          dataIndex: 'endDate',
          key: 'endDate',
          render: (fecha: Date) => (
            <span>{ new Date(fecha).toLocaleString('es-ES') }</span>
          ),
        },
        {
          title: t('challenges.table_headers.description'),
          dataIndex: 'description',
          key: 'description',
          width: '25%',
          render: (description: string) => (
            <span>{description.length > 50 ? `${description.slice(0,50)}...` : description}</span>
          ),

        },

        {
          title: t('common.action'),
          key: 'action',
          render: (item: Challenge) => (
            <>
                <Row>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('challenges.buttons.tooltips.edit')}>
                            <Button
                            type='primary'
                            onClick={() => {
                              navigate(`/challenges/update/${item.id}`)
                            }}
                            icon={<EditOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('challenges.buttons.tooltips.items')}>
                            <Button
                            type='primary'
                            onClick={() => {
                              setChallengeToEditMetadata(item)
                            }}
                            icon={<OrderedListOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('challenges.buttons.tooltips.delete')}>
                            <Button
                            type='primary'
                            danger
                            onClick={() => {
                              setChallengeleToDelete(item)
                            }}
                            icon={<DeleteOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                </>
          ),
        },
      ]


  return (
    <Row>
    <Col span={24} style={{ marginBottom: '2%' }}>
      <Col span={24}>
        <StyledAlignedTitleRow>
          <Col style={{ marginRight: '1%' }}>
            <StyledTitle>{t('menu.challenges')}</StyledTitle>
          </Col>
          <Col style={{ marginBottom: '1%' }}>
              <Tooltip title={t('challenges.buttons.tooltips.new_challenge')}>
                <Button type='primary' onClick={() => navigate('/challenges/new')}>
                  <PlusOutlined rev='' />
                  {t('challenges.buttons.new_challenge')}
                </Button>
              </Tooltip>
            </Col>
        </StyledAlignedTitleRow>
      </Col>
      <Col span={24}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={challenges?.data}
          rowKey='id'
          pagination={{
            
            position: ['bottomCenter'],
            total: (challenges?.total || 0),
            current: (challenges?.page || 0)+1,
            onChange: (page) => {
              moveToPage(page)
            }
          }}
        />
      </Col>
    </Col>
    <DeleteChallengeModal
      visible={!!challengeleToDelete}
      onClose={() => setChallengeleToDelete(undefined)}
      challenge={challengeleToDelete}
    />
    <ChallengeRequirementItemsModal
      visible={!!challengeToEditMetadata}
      onClose={() => setChallengeToEditMetadata(undefined)}
      challenge={challengeToEditMetadata}
    />

    {/* <EditQuizzModal visible={editQuizzModalOpen} onClose={onCloseEditModal} quizById={quizzById} isLoading={isLoadingQuizzById} />
    {quizzById && <QuestionsQuizzModal 
                    allowEdit={new Date(quizzById.startDate) >= (new Date())}
                    visible={questionsQuizzModalOpen} 
                    onClose={onCloseQuestionsQuizzModal} 
                    quizzId={quizzById?.id} 
                    isLoading={isLoadingQuizzById} />}

    <CreateQuizzModal visible={createModalOpen} onClose={onCloseCreateModal} />
    <DeleteQuizzModal
        visible={deleteQuizzModal}
        onClose={onCloseDeleteClick}
        quizzId={deleteQuizzId}
        quizz={deleteQuizz}
      />*/}
  </Row> 
  )
}

