import { Button, Col, Divider, Form, Input, Modal, Pagination, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import * as React from 'react'
import { useCallback, useState } from 'react'

import { Chip } from '../../../../components/Chip'
import { Client } from '../../types'
import { useClients } from '../../hooks/useClients'

interface ChooseClientModalProps {
  visible: boolean
  onClose: () => void
  onClientsSelected: (client: Client[]) => void
  maxItems?: number
}
const ChooseClientModal = ({ visible, onClose, onClientsSelected, maxItems = 1 }: ChooseClientModalProps) => {
  const { t } = useTranslation()
  const [selectedItems, updateSelectedItems] = useState<Client[]>([])

  const { paginationWithFilters, data, changeFilters, changePage } = useClients({})
  const { filters } = paginationWithFilters

  const onItemClick = useCallback(
    (item: Client) => {
      const founded = selectedItems.find(si => si.username === item.username)
      if (!!founded) {
        updateSelectedItems(selectedItems.filter(si => si.username != item.username))
      } else {
        const newItems = [...selectedItems, item]
        if (newItems.length > maxItems) {
          newItems.splice(0, newItems.length - maxItems)
        }
        updateSelectedItems(newItems)
      }
    },
    [selectedItems, maxItems],
  )
  const [form] = Form.useForm()

  const _onClose = () => {
    onClose()
    changeFilters({ username: '' })
    changePage(0)
    form.resetFields()
  }
  const filteredItems = data?.data ?? []

  return (
    <Modal
      open={visible}
      title={t('clients.modals.choose.title')}
      onCancel={onClose}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key='cancel' onClick={_onClose}>
          {t('common.modals.button.cancel')}
        </Button>,
        <Button
          danger
          type='primary'
          key='delete'
          onClick={() => {
            onClientsSelected(selectedItems)
            _onClose()
          }}
        >
          {t('common.modals.button.continue')}
        </Button>,
      ]}
    >
      <Form form={form}>
        <Form.Item name={'username'}>
          <Input
            placeholder={t('clients.modals.choose.title')}
            onChange={e => changeFilters({ ...filters, username: e.target.value })}
          />
        </Form.Item>
      </Form>
      <Row gutter={8}>
        {selectedItems.map(item => (
          <Chip label={item.username} onDelete={() => onItemClick(item)} key={`chip-${item.id}`} />
        ))}
      </Row>
      <Divider style={{ borderTopColor: '#cecece' }} />
      <Row gutter={16} style={{ marginTop: 8 }}>
        {filteredItems.map(item => (
          <Col span={12} key={`col-${item.id}`}>
            <Button onClick={() => onItemClick(item)} style={{ margin: 4 }}>
              {item.username}
            </Button>
          </Col>
        ))}
      </Row>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', marginTop: 8 }}>
        {data && filteredItems.length > 0 && (
          <Pagination
            total={data.total}
            pageSize={data.pageSize}
            defaultCurrent={data.page + 1}
            onChange={page => changePage(page - 1)}
          />
        )}
      </div>
    </Modal>
  )
}

export { ChooseClientModal }
