import { useEffect, useState } from "react";

import { ChallengesApi } from '../../hooks/api'

export const useCombos = () => {

    const [yearsOptions, setYearsOptions] = useState<{ value: number; label: number }[]>([])
    const [brandsOptions, setBrandsOptions] = useState<{ value: string; label: string }[]>([])
    const [signersOptions, setSignersOptions] = useState<{ value: string; label: string }[]>([])
    const [seasonsOptions, setSeasonsOptions] = useState<{ value: string; label: string }[]>([])
    const [itemTypesOptions, setItemTypesOptions] = useState<{ value: string; label: string }[]>([])
    const [dorsalsOptions, setDorsalsOptions] = useState<{ value: string; label: string }[]>([])
    const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<{ value: string; label: string }[]>([])
    const [teamsOptions, setTeamsOptions] = useState<{ value: string; label: string }[]>([])
    const [countriesOptions, setCountriesOptions] = useState<{ value: string; label: string }[]>([])  
    const [namesOptions, setNamesOptions] = useState<{ value: string; label: string }[]>([])


        //completa los menus desplegables con la informacion de la base de datos	
        useEffect(() => {


            const fetchNames = async () => {
                const response = await ChallengesApi.getAllCollectibleNames()
                const names = response.map((name) => {
                    return {
                        value: name.name,
                        label: name.name,
                    }
                })
                setNamesOptions(names)
    
            }

            const fetchYears = async () => {
                const response = await ChallengesApi.getAllCollectibleYears()
                const years = response.map((year) => {
                    return {
                        value: year.year,
                        label: year.year,
                    }
                }).filter((year) => year.value !== null && year.value !== undefined && year.value !== 0)

                //console.log(years)
                setYearsOptions(years)
    
            }
    
            const fetchBrands = async () => {
                const response = await ChallengesApi.getAllCollectibleBrands()
                const brands = response.map((brand) => {
                    return {
                        value: brand.brand.toString(),
                        label: brand.name,
                    }
                })
                setBrandsOptions(brands)
            }
    
            const fetchSigners = async () => {
                const response = await ChallengesApi.getAllCollectibleSigner()
                const signers = response.map((signer) => {
                    return {
                        value: signer.signer,
                        label: signer.signer,
                    }
                })
                setSignersOptions(signers)
            }
    
            const fetchSeasons = async () => {
                const response = await ChallengesApi.getAllCollectibleSeasons()
                const seasons = response.map((season) => {
                    return {
                        value: season.season,
                        label: season.season,
                    }
                })
                setSeasonsOptions(seasons)
            }
    
            const fetchItemTypes = async () => {
                const response = await ChallengesApi.getAllCollectibleItemTypes()
                const itemTypes = response.map((itemType) => {
                    return {
                        value: itemType,
                        label: itemType,
                    }
                })
                setItemTypesOptions(itemTypes)
            }
    
            const fetchDorsals = async () => {
                const response = await ChallengesApi.getAllCollectibleDorsals()
                const dorsals = response.map((dorsal) => {
                    return {
                        value: dorsal.dorsal,
                        label: dorsal.dorsal,
                    }
                }).filter((dorsal) => dorsal.value !== null)    
                setDorsalsOptions(dorsals)
            }
    
            const fetchEquipmentTypes = async () => {
                const response = await ChallengesApi.getAllCollectibleEquipmentTypes()
                const equipmentTypes = response.map((equipmentType) => {
                    return {
                        value: equipmentType,
                        label: equipmentType,
                    }
                })
                setEquipmentTypeOptions(equipmentTypes)
            }
    
            const fetchTeams = async () => {
                const response = await ChallengesApi.getAllCollectibleTeams()
                const {data} = response
                const teams = data.map((team) => {
                    return {
                        value: team.id.toString(),
                        label: team.name,
                    }
                })
                setTeamsOptions(teams)
            }
    
            const fetchCountries = async () => {
                const response = await ChallengesApi.getAllCollectibleCountries()
                const {data} = response
                const countries = data.map((country) => {
                    return {
                        value: country.id.toString(),
                        label: country.name,
                    }
                })
                setCountriesOptions(countries)
            }
    
            fetchYears()
            fetchBrands()
            fetchSigners() 
            fetchSeasons()
            fetchItemTypes()
            fetchDorsals()
            fetchEquipmentTypes()
            fetchTeams()
            fetchCountries()
            fetchNames()
            
        }
        ,[])

        return {
            yearsOptions,
            brandsOptions,
            signersOptions,
            seasonsOptions,
            itemTypesOptions,
            dorsalsOptions,
            equipmentTypeOptions,
            teamsOptions,
            countriesOptions,
            namesOptions
        }
}
