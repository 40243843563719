import { useMemo } from 'react'
import { Col, Row } from 'antd'

import {
  GlobalRankingWidgetType,
  ImageWidgetType,
  NewsHorizontalWidgetType,
  NewsVerticalWidgetType,
  TextWidgetType,
  VideoWidgetType,
  Widget,
  WidgetType,
} from '../../types'
import { ImageWidget } from '../ImageWidget'
import { VideoWidget } from '../VideoWidget'
import { TextWidget } from '../TextWidget'
import { NewsVerticalWidget } from '../NewsVerticalWidget'
import { NewsHorizontalWidget } from '../NewsHorizontalWidget'
import { GlobalRankingWidget } from '../GlobalRankingWidget'

const WidgetPreview = (props: Widget) => {
  const preview = useMemo(() => {
    const { type, ...widgetProps } = props
    switch (type) {
      case WidgetType.VIDEO:
        return <VideoWidget {...(widgetProps as VideoWidgetType)} />
      case WidgetType.IMAGE:
        return <ImageWidget {...(widgetProps as ImageWidgetType)} />
      case WidgetType.NEWS_VERTICAL:
        return <NewsVerticalWidget {...(widgetProps as NewsVerticalWidgetType)} />
      case WidgetType.NEWS_HORIZONTAL:
        return <NewsHorizontalWidget {...(widgetProps as NewsHorizontalWidgetType)} />
      case WidgetType.GLOBAL_RANKING:
        return <GlobalRankingWidget {...(widgetProps as GlobalRankingWidgetType)} />
      case WidgetType.TEXT:
        return <TextWidget {...(widgetProps as TextWidgetType)} />
      default:
        return <></>
    }
  }, [props])

  return (
    <Col style={{ maxWidth: 600 }}>
      <Row>{preview}</Row>
    </Col>
  )
}
export { WidgetPreview }
