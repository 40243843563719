import { useTranslation } from 'react-i18next'

import { BaseDeleteModal } from '@components'

import { Widget } from '../../types'
import { WidgetsApi } from '../../api'
import { useOnDeleteSuccess } from '../../hooks/useOnDeleteSuccess'

interface DeleteWidgetModalProps {
  visible: boolean
  onClose: () => void
  item?: Widget
}

const DeleteWidgetModal = ({ visible, onClose, item }: DeleteWidgetModalProps) => {
  const { t } = useTranslation()
  const { onDeleteSuccess } = useOnDeleteSuccess()

  return item?.id ? (
    <BaseDeleteModal<Widget>
      visible={visible}
      onClose={onClose}
      itemId={item.id}
      title={t('widgets.modals.delete.title')}
      confirmDeleteText={`${t('widgets.modals.delete.delete_confirmation')} ${item.id} ${t(
        'widgets.modals.delete.delete_confirmation_end',
      )}`}
      request={id => WidgetsApi.deleteById(id)}
      onSuccess={onDeleteSuccess}
    />
  ) : null
}

export { DeleteWidgetModal }
