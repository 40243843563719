import { Button, Input, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledRow } from '..'
import { useUsers } from '../../hooks'

interface ChangePasswordModalProps {
  visible: boolean
  onClose: () => void
  userId?: number
}
interface FormState {
  userId?: number
  password: string
}

const ChangePasswordModal = ({ visible, onClose, userId }: ChangePasswordModalProps) => {
  const { t } = useTranslation()
  const { updateUserPassword, isLoadingUpdatePassword } = useUsers()
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>()
  const [form, updateForm] = useState<FormState>({
    userId,
    password: '',
  })
  useEffect(() => {
    updateForm({
      password: '',
      userId,
    })
    setConfirmPassword('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, onClose])

  return (
    <Modal
      open={visible}
      title={t('users.placeholders.update_password')}
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {t('common.edit.button.cancel')}
        </Button>,
        <Button
          type='primary'
          key='update'
          onClick={() => {
            updateUserPassword(form)
            onClose()
          }}
          disabled={isLoadingUpdatePassword || !form.password || form.password !== confirmPassword}
        >
          {t('common.edit.button.save')}
        </Button>,
      ]}
    >
      <Spin spinning={isLoadingUpdatePassword}>
        <StyledRow>
          <h3>{t('users.placeholders.new_password')}:</h3>
          <Input.Password
            placeholder={t('users.placeholders.new_password')}
            value={form.password}
            onChange={e => updateForm({ ...form, password: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.confirm_password')}:</h3>
          <Input.Password
            placeholder={t('users.placeholders.confirm_password')}
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.currentTarget.value)}
          />
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { ChangePasswordModal }
