import { Media } from '../../Media/types'

export enum WidgetShowType {
  ALWAYS = 'ALWAYS',
  ONLY_ONLINE = 'ONLY_ONLINE',
  ONLY_OFFLINE = 'ONLY_OFFLINE',
}

export interface WidgetsFilters {
  title?: string
  description?: string
  type?: WidgetType[]
  showType?: WidgetShowType[]
}
export enum WidgetType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  NEWS_VERTICAL = 'NEWS_VERTICAL',
  NEWS_HORIZONTAL = 'NEWS_HORIZONTAL',
  GLOBAL_RANKING = 'GLOBAL_RANKING',
}

export type BaseWidgetType = {
  id?: number
  order: number
  enabled: boolean
  showType: WidgetShowType
}
export type ImageWidgetType = BaseWidgetType & {
  type: WidgetType.IMAGE
  imageUrl: string
  actionTarget?: string
}

export type VideoWidgetType = BaseWidgetType & {
  type: WidgetType.VIDEO
  imageUrl?: string
  videoUrl: string
}

type NewsWidgetType = BaseWidgetType & {
  imageUrl: string
  title: string
  description: string
  actionName?: string
  actionTarget?: string
}
export type NewsHorizontalWidgetType = NewsWidgetType & {
  type: WidgetType.NEWS_HORIZONTAL
}

export type NewsVerticalWidgetType = NewsWidgetType & {
  type: WidgetType.NEWS_VERTICAL
}
export type GlobalRankingWidgetType = BaseWidgetType & {
  type: WidgetType.GLOBAL_RANKING
  title: string
  actionName?: string
  actionTarget?: string
}

export type TextWidgetType = BaseWidgetType & {
  type: WidgetType.TEXT
  title: string
  description: string
  actionName?: string
  actionTarget?: string
}

export type Widget =
  | ImageWidgetType
  | VideoWidgetType
  | NewsHorizontalWidgetType
  | NewsVerticalWidgetType
  | GlobalRankingWidgetType
  | TextWidgetType

export type WidgetForm = Partial<Widget> & {
  image?: Media
  video?: Media
}
