import { useEffect, useState } from "react"
import { useMutation } from "react-query"

import { ChallengesApi } from "@api"

enum ChallengeUploaderStatus {
    IDLE,
    UPLOADING,
    SUCCESS,
    ERROR,
  }

const useChallengeImageUploader = (
    onImageUploaded: (url: string) => void, 
    onError: () => void
) => {

    const [status, setStatus] = useState<ChallengeUploaderStatus>(ChallengeUploaderStatus.IDLE)
  
    const { mutate, isLoading } = useMutation(ChallengesApi.upload, {
      onSuccess: url => {
        setStatus(ChallengeUploaderStatus.SUCCESS)
        onImageUploaded(url)
      },
      onError: () => {
        setStatus(ChallengeUploaderStatus.ERROR)
        onError()
      },
    })
  
    useEffect(() => {
      if (isLoading) {
        setStatus(ChallengeUploaderStatus.UPLOADING)
      }
    }, [isLoading])
  
    return { status, upload: mutate }
  }
  
  export { useChallengeImageUploader, ChallengeUploaderStatus }
  