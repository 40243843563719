import { QUERY_KEYS } from '@api'
import { usePaginatedRequestWithFilters } from '@hooks'

import { ClientsApi } from '../../api'
import { Client, ClientsFilters } from '../../types'

export const useClients = (filters: ClientsFilters) => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters, clearFilters } =
    usePaginatedRequestWithFilters<ClientsFilters, Client>(filters, QUERY_KEYS.CLIENTS, ClientsApi.paginate)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters, clearFilters }
}
