import { Button } from 'antd'
import styled from 'styled-components'
import { PropsWithChildren } from 'react'

import { WidgetContent } from '../styled'
import { NewsHorizontalWidgetType } from '../../types'

const NewsHorizontalWidgetImage = styled('img')({
  borderRadius: 8,
  borderColor: '#cecece',
  borderWidth: 1,
  borderStyle: 'solid',
  width: '50%',
  height: '100%',
  objectFit: 'cover',
})

const NewsHorizontalWidgetDetailsColumn = (props: PropsWithChildren<any>) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: 12,
      }}
    >
      {props.children}
    </div>
  )
}
const NewsHorizontalWidget = (props: NewsHorizontalWidgetType) => {
  const { title, description, actionName, imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => open(actionTarget, '_blank') : undefined

  return (
    <WidgetContent onClick={onClick}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10 }}>
        <NewsHorizontalWidgetImage src={imageUrl} />
        <NewsHorizontalWidgetDetailsColumn>
          <h1 style={{ paddingBottom: 3, color: 'white', textTransform: 'uppercase' }}>
            <b>{title}</b>
          </h1>
          {!!description && <div>{description}</div>}
        </NewsHorizontalWidgetDetailsColumn>
      </div>

      {!!actionName && !!onClick && (
        <Button style={{ marginTop: 8, fontWeight: 700, fontSize: 18, width: '100%' }} onClick={onClick}>
          {actionName}
        </Button>
      )}
    </WidgetContent>
  )
}
export { NewsHorizontalWidget }
