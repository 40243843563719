import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Spin } from 'antd'

import { StyledContainer } from '../UpdatePassword/components'
import logo from '../../../assets/images/logo-splash.png'
import { useAuth } from '../hooks'
import { AccountSuccess } from './components/AccountSuccess'
import { AccountFailure } from './components/AccountFailure'

export const VerifyUser = () => {
  const { search } = useLocation()
  const [userVerified, setUserVerified] = useState<boolean | null>()
  const { verifyUser, isVerifying } = useAuth()
  const onSuccess = () => {
    setUserVerified(true)
  }
  const onError = () => {
    setUserVerified(false)
  }
  const token = new URLSearchParams(search).get('token')
  useEffect(() => {
    if (token) {
      verifyUser(token, {
        onSuccess,
        onError,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return (
    <StyledContainer
      style={{
        backgroundColor: `#000000`,
        backgroundRepeat: 'no-repeat',
        height: '100vh',
      }}
    >
      <img src={logo} alt='logo' width={300} />
      <Spin spinning={isVerifying} wrapperClassName='spin' />
      <div style={{ height: '50vh' }}>
        {userVerified === null && <></>}
        {userVerified === true && (
          <div className='steps-content'>
            <AccountSuccess />
          </div>
        )}
        {userVerified === false && (
          <div className='steps-content'>
            <AccountFailure />
          </div>
        )}
      </div>
    </StyledContainer>
  )
}
