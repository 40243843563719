import { api } from '@api'

import { ContractsResponse } from '../types'

const getContracts = async () => {
  const response = await api.get<ContractsResponse>('/contracts')

  return response?.data
}

export const ContractsApi = {
  getContracts,
}
