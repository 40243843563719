import { PaginatedRequest, PaginatedResponse } from '@types'
import { api } from '@api'

import { Client, ClientsFilters } from '../types'

const paginate = async (params: PaginatedRequest<ClientsFilters>) => {
  const { page, filters } = params
  const response = await api.get<PaginatedResponse<Client>>('/clients', { params: { page, ...filters } })

  return response.data
}

export const ClientsApi = { paginate }
