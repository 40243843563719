import { createGlobalStyle } from 'styled-components'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

import { theme } from './theme'
import { fonts } from './fonts'

const GlobalStyles = createGlobalStyle`

  body {
    margin: 0;
    font-family: Nunito-Regular !important;
  }

  .background-body {
    height: 50vh;
    padding-top 20px;
    background: ${theme.primaryColor};
    border-bottom-right-radius: 500px 50px;
    border-bottom-left-radius: 500px 50px;
  }

  .container-body {
    width: 100%;
    max-height: 100vh;
  }

  .container-header-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    height: 100px
  }

  .container-skeleton-btn {
    display: flex;
    gap: 5px;
  }

  .tab-menu {
    background: white;
    transition: width 0.2s ease 0s;
  }

  .p-component {
    font-family: Nunito-Regular;
  }

  .ant-layout-sider-children {
    background-color: white;
  }

  .p-tabmenu-nav {
    display: block;
    border: none !important;
  }

  .p-tabmenuitem {
    margin: 5px 20px !important;
  }

  .p-menuitem-link {
    border-radius: 6px;
    font-family: Nunito-Regular;
    border-color: white !important;
    margin: 0;
    gap: 10px;
  }

  .p-menuitem-icon {
    margin-right: 0 !important;
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    color: white !important;
    background-color: ${theme.primaryColor};
    transition: all 0.5s ease 0s;
  }

  .p-menuitem-link:hover {
    color: ${theme.primaryColor} !important;
  }

  .p-button.p-button-warning {
    margin-left: 5px;
    background-color: ${theme.red};
    border-color: ${theme.red};
  }
.ant-layout-header{
  height: auto !important;
}
  .bar-header {
    display: flex;
    gap: 10px;
    justify-content: end;
    align-items: center;
    padding: 0 10px;
    background: white;
    border-radius: 6px;
    margin: 20px;
    height: 50px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 0.2rem ${theme.lightBlue};
  }

  .p-toolbar-group-left h3 {
    margin: 0;
  }

  .landing-header {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: ${theme.primaryColor};
  }

  .card {
    width: 100%;
    background-color: ${theme.white};
    padding: 20px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
    border-radius: 6px;
    display: grid;
    height: calc(100vh - 110px);
    gap 15px;
  }

  .card-login {
    width: 100%;
    background-color: ${theme.white};
    padding: 40px;
    box-shadow: 0px 0px 30px rgb(0 0 0 / 11%);
    max-width: 450px;
    border-radius: 15px;
  }

  .text-landing {
    margin: 40px 0;
    font-size: 16px;
    text-align: center;
    color: ${theme.primaryColor};
  }

  .login-form {
    display: grid;
    gap: 30px
  }

  .dialog-form {
    display: grid;
    gap: 25px;
  }

  .dialog-footer {
    display: flex;
    gap: 10px;
    justify-content: end;
    width: 100%;
  }

  .dialog-footer button {
    width: 170px !important;
  }

  .p-inputtext {
    width: 100%;
  }

  .p-button-success {
    background-color: ${theme.green} !important;
    font-family: Nunito-Regular;
    border-color: ${theme.green} !important;
  }

  .p-dialog .p-dialog-content {
    padding: 2rem 1.5rem;
  }

  .p-datatable .p-datatable-header {
    padding: 0 1rem;
  }

  .p-datatable-flex-scrollable {
    height: calc(100vh - 260px);
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 5px 1rem;
  }

  .p-dialog .p-dialog-content {
    padding-top: 20px;
  }

  .table-header {
    display: flex;
    gap: 10px;
  }

  .field-checkbox {
    display: flex;
    gap: 10px;
  }

  .status-badge {
    border-radius: 3px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
  }

  .status-badge.status-false {
    background-color: #ffd8b2;
    color: #805b36;
  }

  .status-badge.status-true {
    background-color: #c8e6c9;
    color: #256029;
  }

  .steps-title {
    font-family: Chronosfer !important;
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .confirm-pass-input {
    font-family: Calibri !important;
    padding: 0 20px;
  }

  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background-color: white;
  }
  .steps-content .p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
    color: #3cdcfc;
    font-size: 18px;
  }
  .web-input .p-inputtext:enabled:hover {
    border: none;
  }

  .web-input .p-inputtext:enabled:focus{
    box-shadow: none;
  }
  .web-input .p-inputtext {
    background: transparent;
    border: none;
    padding: 4px;
    color: white;
    caret-color: #3cdcfc;
  }

  .ant-picker-range {
    width: 100%;
  }

  .ant-input-number-group-addon {
    background-color: white;
  }
  .ant-input-number-group .ant-input-number:hover + .ant-input-number-group-addon {
    border-color: #40a9ff;
  }
  .ant-input-number-group > .ant-input-number:first-child, .ant-input-number-group-addon:first-child {
    border-right: none;
  }
  .ant-input-number:focus, .ant-input-number-focused + .ant-input-number-group-addon {
    border-color: #40a9ff;
  }
  .ant-input-number:focus, .ant-input-number-focused {
    box-shadow: none;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }

  @media (max-width: 510px) {
    .container-card{
      background: ${theme.white};
    }

    .card {
      border-radius: 0px;
      box-shadow: none;
    }
  }

  @media (max-width: 1024px) {
    .container-body {
      margin-left: 90px; 
    }

    .tab-menu {
      position: absolute;
      z-index: 10;
    }
  }

  ${fonts}
`
export { GlobalStyles }
