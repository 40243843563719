import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { useLoginResponseManager } from '@hooks'
import { AuthApi, setAuthHeader } from '@api'
import { LOCAL_STORAGE_KEYS } from '@constants'
import { ErrorResponse } from '@types'

const useLogin = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onError = (error: ErrorResponse) => {
    if (error.type === 'LOGIN_ATTEMPTS_EXCEEDED') {
      navigate('/login/change-password', { state: { reason: t('login.account_locked') } })
    } else if (error.type === 'INCORRECT_USER_PASSWORD') {
      notification.error({ message: t('login.password_wrong') })
    } else {
      notification.error({ message: error.type })
    }
  }
  const { onSuccess } = useLoginResponseManager()

  const { mutate, isLoading } = useMutation(AuthApi.login, {
    onSuccess: ({ token, refreshToken, user }) => {
      setAuthHeader(token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
      window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

      onSuccess(user.id)
    },
    onError,
  })

  return { login: mutate, isLoading }
}

export { useLogin }
