import { Button, Checkbox, Col, DatePicker, Form, Input, message, Row } from 'antd'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import { useNavigate} from 'react-router-dom'

import { Title, TitleRow } from '@components'

import { ImagePicker } from '../../components/ImagePicker'
import { Challenge } from '../../types'
import { useChallenges } from '../../hooks'


export const ChallengesCreate = () => {

    const navigate = useNavigate()

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [errors, _] = useState<{ [key: string]: string }>({})
    const [urlImage, setImageUrl] = useState('')

    const { create } = useChallenges()



    const onFinish = useCallback(
        (values: Challenge) => {

            if(!urlImage){
                message.error('Image is required')

                return
            }

            console.log('Received values of form: ', values)

            const { name, description, startDate, endDate, enabled } = values
            
            create({
                    name,
                    description: description??'',
                    startDate,
                    endDate,
                    enabled: enabled??false, 
                    imageUrl: urlImage, 
                    type: 'TEMPORARY', 
                    
                })

            navigate(`/challenges`)
        },
        [urlImage, create, navigate],
    )
   

    return (
        <Row>
            <Col span={24} style={{ marginBottom: '2%' }}>

            <Col span={24}>
                <TitleRow>
                <Col style={{ marginRight: '1%' }}>
                    <Title>{t('challenges.create.title')}</Title>
                </Col>
                </TitleRow>
            </Col>
            <Row>
                <Col span={8}>
                    <ImagePicker
                            label={t('challenges.create.form.input.image')}
                            name={'frontImage'}
                            url={urlImage}
                            onChange={(_, url) =>setImageUrl(url)}
                    />
                </Col>
                <Col span={16}>
                    <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    layout='horizontal'
                    style={{ maxWidth: 600 }}
                    form={form}
                    onFinish={onFinish}
                    
                    //   onFieldsChange={onFieldsChange}
                    //   initialValues={initialValues}
                    >
                    <Form.Item
                        label={t('challenges.create.form.input.name')}
                        validateStatus={errors.name ? 'error' : ''}
                        help={errors.name}
                        name={'name'}
                        rules={[{ required: true, message: t('challenges.create.form.input.name') }]}
                        >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label={t('challenges.create.form.input.description')} 
                        name={'description'}
                        rules={[{required: true, message: t('challenges.create.form.input.description') }]}>
                        <TextArea rows={4} name={'description'} />
                    </Form.Item>

                    <Form.Item label={t('challenges.create.form.input.startDate')} name={'startDate'}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={true}
                            showSecond={false}
                            minuteStep={5}
                            style={{ width: '100%' }} 
                            autoComplete="false"
                            placeholder={t('challenges.create.form.input.startDate')}
                            
                            />
                    </Form.Item>

                    <Form.Item label={t('challenges.create.form.input.endDate')} name={'endDate'}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={true}
                            showSecond={false}
                            minuteStep={5}
                            style={{ width: '100%' }} 
                            autoComplete="false"
                            placeholder={t('challenges.create.form.input.endDate')}
                        />
                    </Form.Item>

                    <Form.Item label={t('challenges.create.form.input.enabled')} name={'enabled'} valuePropName='checked'>
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button 

                            //disabled={!formValid || !formImagesValid || isLoading} 
                            type='primary' 
                            htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item> 
                </Form>
                </Col>
            </Row>


            </Col>
        </Row>
    )
}

