import { useEffect, useState } from "react"
import { Button, Checkbox, Col, DatePicker, Image, Input,  Modal, Row, Spin, Upload, UploadProps } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { UploadOutlined } from "@ant-design/icons"
import moment from "moment"
import { useTranslation } from 'react-i18next'


import { Quizz } from "../../types"
import { StyledRow } from ".."
import { useQuizzes } from "../../hooks"
import { QuizzesApi } from "../../hooks/api"



interface EditQuizzModalProps {
    visible: boolean
    onClose: () => void
    quizById?: Quizz, 
    isLoading: boolean
}

interface FormState {
    name: string | undefined
    description: string | undefined
    imageUrl: string | undefined,
    imageFile?: File,
    startDate: Date ,
    endDate: Date , 
    enabled: boolean
  }

export const EditQuizzModal = ({visible, onClose, quizById, isLoading}: EditQuizzModalProps) => {
    const { t } = useTranslation()
    const { updateQuizz, isLoadingUpdate } = useQuizzes()
    const [fileList, setFileList] = useState<any>([])
    const [allowEdit, setAllowEdit] = useState<boolean>(true)


    const [form, updateForm] = useState<FormState>({
      name: quizById?.name,
      description: quizById?.description,
      imageUrl: quizById?.imageUrl,
      startDate: quizById?.startDate ?? new Date(),
      endDate: quizById?.endDate ?? new Date(), 
      imageFile: undefined, 
      enabled: quizById?.enabled ?? true
    })

    useEffect(() => {

      if(quizById?.startDate) {


        const startDate: Date = new Date(quizById.startDate)
        const now = new Date()

        // console.log(now.toString())
        // console.log(startDate.toString())
        // console.log(startDate >= now)
        setAllowEdit(startDate >= now)
      }
    }
    , [quizById])
  

    // useEffect(() => {
    //   console.log('form', form)
    // },[form])


    const uploadProps: UploadProps = {
      disabled: !allowEdit,
      name: 'file',
      accept: '.png, .jpg, .jpeg',
      listType: "picture-card",
      defaultFileList: [],
      fileList: fileList,
      beforeUpload: ()=>false,
      multiple: false,
      maxCount: 1,
      onChange(info) {

        //console.log('info', info)
        if (info.fileList.length > 0) {
          setFileList([info.fileList[0]])
          updateForm({ ...form, imageFile: info.fileList[0].originFileObj as File })
        } else {
          setFileList([])
          updateForm({ ...form, imageFile: undefined })
        }

      },
    };


    useEffect(() => {
        //console.log({quizById})
        if(quizById) {
            updateForm({
                name: quizById.name,
                description: quizById.description,
                imageUrl: quizById.imageUrl,
                startDate: quizById.startDate ?? new Date(),
                endDate: quizById.endDate ?? new Date(), 
                enabled: quizById.enabled 
            })

            setFileList([])
        }

    }, [quizById])


    const handleSubmit = async () => {

      // console.log('form', form)
      // console.log('quizById', quizById)
      // console.log('form.imageFile', form.imageFile)

      if(form.imageFile) {
        const imageUrl = await QuizzesApi.uploadQuizzImage(form.imageFile)

        console.log('imageUrl', imageUrl)

        updateQuizz({
          quizzId: quizById?.id,
          name: form.name,
          description: form.description,
          imageUrl: imageUrl,
          startDate: form.startDate,
          endDate: form.endDate, 
          enabled: form.enabled
        })
      } else {
        updateQuizz({
          quizzId: quizById?.id,
          name: form.name,
          description: form.description,
          imageUrl: form.imageUrl,
          startDate: form.startDate,
          endDate: form.endDate, 
          enabled: form.enabled
        })
      }
     onClose()

      }


  return (
    <Modal
      width={600}
      open={visible}
      title={t('quizzes.placeholders.edit_quizz')}
      onCancel={onClose}
      maskClosable={false}
      transitionName="ant-fade"
      focusTriggerAfterClose={false}
      closable={false}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('quizzes.buttons.cancel')}
        </Button>,
        <Button
          type="primary"
          key="update"
          onClick={handleSubmit}
          disabled={isLoadingUpdate || !form.name || !form.description || !form.imageUrl || !form.startDate || !form.endDate}
        >
          {t('quizzes.buttons.save')}
        </Button>,
      ]}
    >     
        <Spin spinning={isLoading}>
            <Row style={{ marginBottom:10 }}>
                <Col span={24} >
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.name')}:</h3>
                      <Input
                          disabled={!allowEdit}
                          required
                          autoComplete="false"
                          placeholder={t('quizzes.placeholders.name')}
                          value={form.name}
                          onChange={e => updateForm({ ...form, name: e.currentTarget.value })}
                      />
                    </StyledRow>
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.description')}:</h3>
                      <TextArea
                          disabled={!allowEdit}
                          required
                          autoComplete="false"
                          placeholder={t('quizzes.placeholders.description')}
                          value={form.description}
                          onChange={e => updateForm({ ...form, description: e.currentTarget.value })}
                      />
                    </StyledRow>
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.image')}:</h3>
                    </StyledRow>
                    <StyledRow>
                      <Col style={{marginRight:'10px'}}>
                        <Image src={form.imageUrl} style={{ width: '100px', height: '100px' }} />
                      </Col>
                      <Col flex={1}>
                        <Upload {...uploadProps}>
                          <Button icon={<UploadOutlined rev={''} />} disabled = {!allowEdit}></Button>
                        </Upload>
                      </Col>
                    </StyledRow>
                    {/* <StyledRow>
                      <h3>{t('quizzes.placeholders.imageUrl')}:</h3>
                      <Input
                          disabled={!allowEdit}
                          required
                          autoComplete="false"
                          type="email"
                          placeholder={t('quizzes.placeholders.imageUrl')}
                          value={form.imageUrl}
                          onChange={e => updateForm({ ...form, imageUrl: e.currentTarget.value })}
                      />
                    </StyledRow> */}
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.startDate')}:</h3>
                      <DatePicker
                          disabled={!allowEdit}
                          format="YYYY-MM-DD HH:mm"
                          showTime={true}
                          showSecond={false}
                          minuteStep={5}
                          style={{ width: '100%' }} 
                          autoComplete="false"
                          placeholder={t('quizzes.placeholders.startDate')}
                          defaultValue={moment(form.startDate) ?? moment(Date.now())}
                          value={(form.startDate ? moment(form.startDate) : moment(Date.now()))}
                          onChange={(date) => updateForm({ ...form, startDate: date?.toDate() ?? new Date()})}
                      />
                    </StyledRow>
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.endDate')}:</h3>
                      <DatePicker
                          disabled={!allowEdit}
                          format="YYYY-MM-DD HH:mm"
                          showTime={true}
                          showSecond={false}
                          minuteStep={5}
                          style={{ width: '100%' }} 
                          autoComplete="false"
                          placeholder={t('quizzes.placeholders.endDate')}
                          defaultValue={moment(form.endDate) ?? moment(Date.now())}
                          value={(form.endDate ? moment(form.endDate) : moment(Date.now()))}
                          onChange={(date) => updateForm({ ...form, endDate: date?.toDate()?? new Date()})}
                      />
                    </StyledRow>
                    <StyledRow>
                      <h3>{t('quizzes.placeholders.enabled')}:</h3>
                      <Checkbox
                        style={{ marginLeft: '10px' }}
                        checked={form.enabled}
                        onChange={e => updateForm({ ...form, enabled: e.target.checked })}  
                      />
                    </StyledRow>
                </Col>
                
            </Row>
        </Spin>   
    </Modal>
  )
}

