import React from 'react'
import { Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

interface ChipProps {
  label: string
  onDelete?: () => void
}

const Chip: React.FC<ChipProps> = ({ label, onDelete }) => {
  return (
    <Tag
      closable={!!onDelete}
      onClose={onDelete}
      closeIcon={<CloseOutlined rev={''} />}
      style={{ userSelect: 'none', cursor: 'pointer' }}
    >
      {label}
    </Tag>
  )
}

export { Chip }
