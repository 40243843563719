import { QUERY_KEYS } from '@api'
import { Team, TeamsFilters } from '@types'
import { usePaginatedRequestWithFilters } from '@hooks'

import { TeamsApi } from '../../api'

export const useTeams = (teamFilters: TeamsFilters) => {
  const { isLoading, data, changeFilters, changePage, paginationWithFilters } = usePaginatedRequestWithFilters<
    TeamsFilters,
    Team
  >(teamFilters, QUERY_KEYS.TEAMS, TeamsApi.paginate)

  return { isLoading, data, changeFilters, changePage, paginationWithFilters }
}
