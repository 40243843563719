import React, { ReactNode, useEffect, useState } from 'react'
import { Button, Col, Input, List, Modal, Row, Spin, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'

import { Question } from '../../types'
import { QuestionDetailsQuizz } from '../QuestionDetailsQuizz'
import { useQuizzes, useQuizzById } from '../../hooks'
import { DeleteQuestionModal } from './DeleteQuestionModal'


interface QuestionsQuizzModalProps {
    visible: boolean
    onClose: () => void
    quizzId: number, 
    isLoading: boolean, 
    allowEdit: boolean
}

export const QuestionsQuizzModal = ({visible, onClose, quizzId, isLoading, allowEdit}: QuestionsQuizzModalProps)  => {
    const { t } = useTranslation()


    // const [questions, setQuestions] = useState<Question[]>([])
    const [selectedQuestion, setSelectedQuestion] = useState<Question|null>(null)
    const [newQuestionText, setNewQuestionText] = useState<string>('')
    const [QuestionDetailsModalVisible, setQuestionDetailsModalVisible] = useState<boolean>(false)
    const [lastPosition, setLastPosition] = useState<number>(0)


    const [deleteQuestionModal, setDeleteQuestionModal] = useState<boolean>(false)
    const [deleteQuestionId, setDeleteQuestionId] = useState<number>()
    const [deleteQuestion, setDeleteQuestion] = useState()
    
    const onDeleteClick = Question => {
      setDeleteQuestionId(Question.id)
      setDeleteQuestion(Question)
      setDeleteQuestionModal(true)
    }
    const onCloseDeleteClick = () => {
      setDeleteQuestionModal(false)
    }


    const {quizzById} = useQuizzById(quizzId)
    const {createQuestion} = useQuizzes()


    useEffect(() => {
        if(quizzById) {
            
            if (quizzById.questions && quizzById.questions.length > 0) {
                const maxPositionQuestion = quizzById.questions?.reduce((prev, current) => (prev.position > current.position) ? prev : current)?.position
                setLastPosition(maxPositionQuestion??0)
            }
            else {
                setLastPosition(0)  
            }

        }
    }, [quizzById])


    const handleSelectQuestion = (question: Question) => {

        setSelectedQuestion(question)
        setQuestionDetailsModalVisible(true)
    }

    const handleCreateQuestion = () => {
        //console.log('create question')



        createQuestion({
            quizId: quizzById?.id,
            text: newQuestionText,
            answerType: 'SINGLE',
            mediaType: 'TEXT',
            multimediaUrl: '',
            position: lastPosition + 1, 
            answers: []
        })

        //getQuizzById(quizzId)
        
        setNewQuestionText('')

    }

  return (
    <>
    <Modal
          width={600}
          open={visible}
          title={quizzById?.name}
          onCancel={onClose}
          maskClosable={false}
          transitionName="ant-fade"
          focusTriggerAfterClose={false}
          closable={false}
          footer={[
              <Button key="cancel" onClick={onClose}>
                  {t('quizzes.buttons.cancel')}
              </Button>,

          ]}
    >
        <Spin spinning={isLoading}>
        <List
            header={<h3>{t('quizzes.placeholders.questions')}</h3>}
            footer={
                allowEdit && <Row>
                    <Col span={21}>
                        <Input
                            placeholder={t('quizzes.placeholders.description')}
                            value={newQuestionText}
                            onChange={(e) => {
                                setNewQuestionText(e.currentTarget.value)
                            } } />
                    </Col>
                    <Col span={1} />
                    <Col span={2}>
                        <Tooltip title={t('quizzes.buttons.tooltips.add_question')}>
                            <Button
                                type="primary"
                                color="green"
                                onClick={handleCreateQuestion}
                                icon={<PlusOutlined rev='' />} />
                        </Tooltip>
                    </Col>

                </Row>
            }

                bordered
                dataSource={quizzById?.questions??[]}
                renderItem={question => (
                    <List.Item
                        key={question.position}
                        actions={
                        (()=>{
                            const actions = [<a onClick={()=>{handleSelectQuestion(question)}}>{ allowEdit ?t("questions.buttons.edit"): t("questions.buttons.view") }</a>]
                            if(allowEdit){
                                actions.push(<a onClick={()=>{onDeleteClick(question)}}>{t('questions.buttons.delete')}</a>)
                            }

                            return actions as ReactNode[]
                        })()}
                          
                    >
                        {question.text || "..."}
                    </List.Item>
                )}
            />


        </Spin>
        </Modal>
        {selectedQuestion && 
            <QuestionDetailsQuizz 
                questionId={selectedQuestion.id} 
                onClose={() => { setQuestionDetailsModalVisible(false) } } 
                visible={QuestionDetailsModalVisible}
                quizId={quizzById!.id}
                allowEdit={allowEdit} />}
        <DeleteQuestionModal
          visible={deleteQuestionModal}
          onClose={onCloseDeleteClick}
          questionId={deleteQuestionId}
          question={deleteQuestion}
        />
        </> 
  )
}


