import React, { ReactNode, useCallback, useState } from 'react'
import { Layout } from 'antd'

import { UserHeader } from '../UserHeader'
import { AppMenu } from '../AppMenu'

const { Sider, Content, Header } = Layout

interface AppLayoutProps {
  body: ReactNode
  bodyStyle?: React.CSSProperties
}

const AppLayout = ({ body, bodyStyle }: AppLayoutProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const changeIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider trigger={null} collapsible collapsed={!isOpen} width={300} collapsedWidth={100} style={{ padding: 0 }}>
        <AppMenu isOpen={isOpen} changeIsOpen={() => changeIsOpen()} />
      </Sider>
      <Layout className='site-content'>
        <Header style={{ height: 'auto !important' }}>
          <UserHeader />
        </Header>
        <Content style={{ padding: '24px 36px 0 30px', ...bodyStyle }}>{body}</Content>
      </Layout>
    </Layout>
  )
}

export { AppLayout }
