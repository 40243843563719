import { css } from 'styled-components'

const fonts = css`
  //Nunito
  @font-face {
    font-family: 'Nunito-Regular';
    src: url('/fonts/Nunito/Nunito-Regular.ttf');
  } //400

  //Nunito
  @font-face {
    font-family: 'Nunito-Bold';
    src: url('/fonts/Nunito/Nunito-Bold.ttf');
  } //700

  @font-face {
    font-family: 'Chronosfer';
    src: url('/fonts/chronosfer/Chronosfer-Demo.otf');
  }

  @font-face {
    font-family: 'Calibri';
    src: url('/fonts/calibri/Calibri.ttf');
  }
`

export { fonts }
