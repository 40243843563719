import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  StyledContentContainer,
  StyledTitle,
  StyledSubtitle,
} from '../../UpdatePassword/components'

export const AccountSuccess = () => {
  const { t } = useTranslation()
  
return (
    <StyledContentContainer>
      <Row align="middle" justify="center">
        <Col span={16}>
          <StyledTitle className="steps-title">
            {t('public_web.validate_email.verified_account_title')}
          </StyledTitle>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col span={16}>
          <StyledSubtitle className="confirm-pass-input">
            {t('public_web.validate_email.verified_account_generic')}
          </StyledSubtitle>
        </Col>
      </Row>
    </StyledContentContainer>
  )
}
