import { Button, Col, Row, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'

import { AdminUser } from '@types'

import { useUsers } from './hooks'
import { StyledAlignedTitleRow, StyledTitle } from './components'
import { EditUserModal } from './components/Modals/EditUserModal'
import { ChangePasswordModal } from './components/Modals/ChangePasswordModal'
import { CreateUserModal } from './components/Modals/CreateUserModal'
import { DeleteUserModal } from './components/Modals/DeleteUserModal'

const Users = () => {
  const { t } = useTranslation()
  const [editUserModalOpen, setEditUserModalOpen] = useState<boolean>(false)
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const { users, userById, getUserById, isLoading, isCreating, isLoadingUpdate, isDeleting } = useUsers()
  const [userId, setUserId] = useState<number>()
  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false)
  const [deleteUserId, setDeleteUserId] = useState<number>()
  const [deleteUser, setDeleteUser] = useState()
  const [updatePasswordModalOpen, setUpdatePasswordModalOpen] = useState<boolean>(false)

  // useEffect(() => {
  //   console.log('users', users)
  // }, [users])

  const onEditUser = (id: number) => {
    getUserById(id)
    setEditUserModalOpen(true)
  }
  const onUpdatePassword = (id: number) => {
    setUserId(id)
    setUpdatePasswordModalOpen(true)
  }
  const onCloseModal = () => {
    setEditUserModalOpen(false)
  }
  const onClosePasswordModal = () => {
    setUpdatePasswordModalOpen(false)
  }
  const onCreateClick = () => {
    setCreateModalOpen(true)
  }
  const onDeleteClick = user => {
    setDeleteUserId(user.id)
    setDeleteUser(user)
    setDeleteUserModal(true)
  }
  const onCloseDeleteClick = () => {
    setDeleteUserModal(false)
  }
  const onCloseCreateModal = () => {
    setCreateModalOpen(false)
  }
  const columns = [
    {
      title: t('users.table_headers.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('users.table_headers.full_name'),
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      title: t('users.table_headers.username'),
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: t('common.action'),
      key: 'action',
      render: (user: AdminUser) => (
        <>
          <Row>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('users.buttons.tooltips.edit')}>
                <Button
                  type='primary'
                  onClick={() => {
                    onEditUser(user.id)
                  }}
                  icon={<EditOutlined rev='' />}
                />
              </Tooltip>
            </Col>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('users.buttons.tooltips.reset_password')}>
                <Button
                  onClick={() => {
                    onUpdatePassword(user.id)
                  }}
                  icon={<LockOutlined rev='' />}
                />
              </Tooltip>
            </Col>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('users.buttons.tooltips.delete')}>
                <Button
                  type='primary'
                  danger
                  onClick={() => {
                    onDeleteClick(user)
                  }}
                  icon={<DeleteOutlined rev='' />}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
          <Col span={24}>
            <StyledAlignedTitleRow>
              <Col style={{ marginRight: '1%' }}>
                <StyledTitle>{t('menu.users')}</StyledTitle>
              </Col>
              <Col style={{ marginBottom: '1%' }}>
                <Tooltip title={t('users.buttons.tooltips.new_user')}>
                  <Button type='primary' onClick={onCreateClick}>
                    <PlusOutlined rev='' />
                    {t('users.buttons.new_user')}
                  </Button>
                </Tooltip>
              </Col>
            </StyledAlignedTitleRow>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading || isLoadingUpdate || isDeleting || isCreating}
              columns={columns}
              dataSource={users?.data}
              rowKey='id'
              pagination={{
                position: ['bottomCenter'],
                total: users?.total,
                current: (users?.page ?? 0) + 1,
              }}
            />
          </Col>
        </Col>
        <EditUserModal visible={editUserModalOpen} userById={userById} onClose={onCloseModal} />
        <CreateUserModal visible={createModalOpen} onClose={onCloseCreateModal} />
        <ChangePasswordModal visible={updatePasswordModalOpen} userId={userId} onClose={onClosePasswordModal} />
        <DeleteUserModal
          visible={deleteUserModal}
          onClose={onCloseDeleteClick}
          userId={deleteUserId}
          user={deleteUser}
        />
      </Row>
    </>
  )
}

export { Users }
