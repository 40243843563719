import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QUERY_KEYS } from '@api'

interface OnDeleteSuccessProps {
  categoryId: string, 
  round: number
}

const useOnDeleteSuccess = ({categoryId, round}: OnDeleteSuccessProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.PREDICTIONS)
    queryClient.invalidateQueries([QUERY_KEYS.PREDICTIONS_MATCHES, categoryId, round])
    
    notification.success({
      message: t('predictions.notifications.delete_success'),
    })
  }


  return { onDeleteSuccess }
}

export { useOnDeleteSuccess }
