import { api } from '@api'

import { ReviewItemResponse } from '../types'

const getReviewItems = async () => {
  const response = await api.get<ReviewItemResponse>('/items/reviewpending')

  return response?.data
}

const validateReviewItem = async (id: number, collectibleCount: number) => {
  const response = await api.post<ReviewItemResponse>(`/items/validate/${id}`, { collectibleCount })

  return response?.data
}

const rejectReviewItem = async (id: number) => {
  const response = await api.post<ReviewItemResponse>(`/items/sendtouserreview/${id}`)

  return response?.data
}

export const ReviewItemsApi = {
  getReviewItems,
  validateReviewItem,
  rejectReviewItem,
}
