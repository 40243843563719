import { Card, Row, Table } from 'antd'
import styled from 'styled-components'

const StyledTitle = styled.h1`
  font-family: Nunito-Bold;
  font-size: 35px;
`
const StyledAlignedTitleRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const StyledTable = styled(Table)`
  width: 100%;
`
const StyledCenteredContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledCard = styled(Card)`
  width: 200;
`
const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 0 20px 0;
`
const StyledDeleteMessage = styled.div`
  width: 100%;
  text-align: center;
`

export {
  StyledTitle,
  StyledTable,
  StyledCenteredContainer,
  StyledCard,
  StyledAlignedTitleRow,
  StyledRow,
  StyledDeleteMessage,
}
