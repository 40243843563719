import React, { useEffect, useState } from 'react'
import { Button, InputNumber, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { useReviewItems } from '../../hooks'
import { ReviewItem } from '../../types'
import ItemMetadata from '../ItemMetadata'

interface ValidateYesNoProps {
  visible: boolean
  onClose: () => void
  item?: ReviewItem
}

const ValidateYesNo = ({ visible, onClose, item }: ValidateYesNoProps) => {
  const { t } = useTranslation()
  const { validateReviewItem, isLoadingValidate, rejectReviewItem, isLoadingReject } = useReviewItems()

  const [validateButtonPressed, setValidateButtonPressed] = useState<boolean>(false)
  const [collectibleCount, setCollectibleCount] = useState<number>(1)

  useEffect(() => {
    setValidateButtonPressed(false)
    setCollectibleCount(1)
  }, [item])

  const validateReviewItemHandler = (id: number) => {
    //console.log('validateReviewItemHandler', id)
    validateReviewItem({ id, collectibleCount })
    onClose()
  }

  const rejectReviewItemHandler = (id: number) => {
    rejectReviewItem(id)
    onClose()
  }

  const handleCollectibleCountChage = (value: number | string | null) => {
    if (!value) {
      return
    }
    try {
      setCollectibleCount(Number(value))

      return
    } catch (e) {
      return
    }
  }

  return (
    <Modal
      width={800}
      open={visible}
      title={t('review-items.titles.review_items')}
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      footer={[
        <Button
          key='cancel'
          onClick={() => {
            setValidateButtonPressed(false)
            onClose()
          }}
        >
          {t('review-items.buttons.cancel')}
        </Button>,
        <Button
          danger
          key='reject'
          hidden={validateButtonPressed}
          onClick={() => {
            rejectReviewItemHandler(item!.id)
          }}
          disabled={isLoadingReject}
        >
          {t('review-items.buttons.reject')}
        </Button>,
        <Button
          type='primary'
          key='validateStep1'
          hidden={validateButtonPressed}
          onClick={() => {
            setValidateButtonPressed(true)
          }}
          disabled={isLoadingValidate}
        >
          {t('review-items.buttons.validate')}
        </Button>,
        <Button
          type='primary'
          key='validateStep2'
          hidden={!validateButtonPressed}
          onClick={() => {
            validateReviewItemHandler(item!.id)
            setValidateButtonPressed(false)
          }}
          disabled={isLoadingValidate}
        >
          {t('review-items.buttons.validate')}
        </Button>,
      ]}
    >
      {validateButtonPressed ? (
        <div>
          <InputNumber
            addonBefore={t('review-items.placeholders.collectible_count')}
            defaultValue={collectibleCount}
            min={1}
            max={10}
            onChange={handleCollectibleCountChage}
          />
        </div>
      ) : (
        <ItemMetadata metadata={item?.metadata} isLoading={isLoadingValidate || isLoadingReject} />
      )}
    </Modal>
  )
}

export default ValidateYesNo
