import { useCallback,  } from 'react'
import { Button, Form,  Modal, Select} from 'antd'
import { useTranslation } from 'react-i18next'


import { Challenge,  ChallengeRequirementItemDto } from '../../types'
import { useCombos } from '../../hooks/useCombos'
import {  useChallenges } from '../../hooks'




// import { StyledDeleteMessage, StyledRow } from '../../components'

interface MetadataChallengeModalProps {
    visible: boolean
    challenge?: Challenge
    onClose: () => void
}

export const MetadataChallengeModal = (props: MetadataChallengeModalProps) => {
    const { visible, challenge, onClose } = props
    const { 
        yearsOptions, 
        brandsOptions, 
        signersOptions, 
        countriesOptions, 
        dorsalsOptions, 
        equipmentTypeOptions, 
        seasonsOptions, 
        teamsOptions, 
        itemTypesOptions, 
        namesOptions
    } = useCombos()
  
    const {createChallengeRequirementItem} = useChallenges()
    
    const [form] = Form.useForm()
    
    const { t } = useTranslation()
    
    
 

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };
      
    const onSearch = (value: string) => {
        console.log('search:', value);
    };


    const onFieldsChange = useCallback(() => {
        // const fields = form.getFieldsValue()
        // Object.keys(fields).forEach(field => {
        //   console.log('field', field)
        // })
      }, [])

    const onFinish = useCallback(() => {

        console.log('onFinish')
        const fields = form.getFieldsValue()

        const challengeRequirementItem: ChallengeRequirementItemDto = {
            challengeId: challenge!.id,
            quantity: 1,
            metadata: {
                name: fields.name,
                type: fields.itemType,
                year: fields.year,
                brand: fields.brand,
                signer: fields.signer,
                season: fields.season,
                dorsal: fields.dorsal,
                equipmentType: fields.equipmentType,

                // team: {
                //     id: fields.team as number
                // },
                history: '',
                isSigned: false,

            }
        }

        //console.log({challengeRequirementItem})

        createChallengeRequirementItem(challengeRequirementItem)
                               
        onClose();

    }, [form, challenge, createChallengeRequirementItem, onClose])

    // const initialValues = {
    //     year: undefined ,
    //     brand: challenge?.brand,
    //     signer: challenge?.signer,
    //     season: challenge?.season,
    //     itemType: challenge?.itemType,
    //     dorsal: challenge?.dorsal,
    //     equipmentType: challenge?.equipmentType,
    //     team: challenge?.team,
    //     country: challenge?.country,
    // }

    return (
        <Modal
            width={800}
            open={visible}
            title={t('challenges.modals.metadata.title')}
            onCancel={onClose}
            transitionName='ant-fade'
            focusTriggerAfterClose={false}
            maskClosable={false}
            closable={false}
            footer={[
                <Button key='cancel' onClick={onClose}>
                    {t('challenges.modals.metadata.cancel')}
                </Button>,
                <Button
                    type='primary'
                    key='delete'
                    onClick={() => {
                        form.submit()
                    }}
                >
                {t('challenges.modals.metadata.save')}
                </Button>,
            ]}
        >


            <Form
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 14 }}
                layout='horizontal'
                
                // style={{ maxWidth: 600 }}
                form={form}
                onFinish={onFinish}
                onFieldsChange={onFieldsChange}

                //initialValues={initialValues}
            >
                <Form.Item
                    name='name'
                    label='Name'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a name"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={namesOptions} />
                </Form.Item>

                <Form.Item
                    name='year'
                    label='Year'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select an year"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={yearsOptions} />
                </Form.Item>

                <Form.Item
                    name='brand'
                    label='Brand'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a brand"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={brandsOptions} />
                </Form.Item>

                <Form.Item
                    name='signer'
                    label='Signer'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a signer"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={signersOptions} />
                </Form.Item>

                <Form.Item
                    name='season'
                    label='Season'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a season"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={seasonsOptions} />
                </Form.Item>

                <Form.Item
                    name='itemType'
                    label='Type'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a type"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={itemTypesOptions} />

                </Form.Item>

                <Form.Item
                    name='dorsal'
                    label='Dorsal'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a dorsal"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={dorsalsOptions} />
                </Form.Item>

                <Form.Item
                    name='equipmentType'
                    label='Equipment Type'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a equipment type"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={equipmentTypeOptions} />
                </Form.Item>

                <Form.Item
                    name='team'
                    label='Team'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a team"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={teamsOptions} />
                </Form.Item>

                <Form.Item
                    name='country'
                    label='Country'>
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="label"
                        onChange={onChange}
                        onSearch={onSearch}
                        options={countriesOptions} />
                </Form.Item>



            </Form>


        {/* <Spin spinning={isDeleting}>
            <StyledRow>
            <StyledDeleteMessage>{confirmDeleteText}</StyledDeleteMessage>
            </StyledRow>
        </Spin> */}
        </Modal>
     )    
}
