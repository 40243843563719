import * as yup from 'yup'

import { WidgetShowType, WidgetType } from '../../types'

const useWidgetFormValidationSchema = () => {
  // Define el esquema base y algunos subesquemas comunes
  const OptionalString = yup.string().nullable().optional()
  const ConditionalString = ref =>
    yup.string().when(ref, (values, schema) => {
      const value = values.length > 0 ? values[0] : null

      return value ? schema.required() : schema.nullable().optional()
    })

  const BaseWidgetSchema = yup.object({
    enabled: yup.boolean().optional(),
    order: yup.number().optional(),
  })

  const TextWidgetSchema = BaseWidgetSchema.shape({
    type: yup.mixed().oneOf([WidgetType.TEXT]).required(),
    title: yup.string().required(),
    description: yup.string().required(),
    actionName: OptionalString,
    actionTarget: ConditionalString('actionName'),
    showType: yup.mixed().oneOf(Object.keys(WidgetShowType)).optional(),
  })

  const ImageWidgetSchema = BaseWidgetSchema.shape({
    type: yup.mixed().oneOf([WidgetType.IMAGE]).required(),
    imageUrl: yup.string().required(),
    actionTarget: OptionalString,
    showType: yup.mixed().oneOf(Object.keys(WidgetShowType)).optional(),
  })

  const VideoWidgetSchema = BaseWidgetSchema.shape({
    type: yup.mixed().oneOf([WidgetType.VIDEO]).required(),
    videoUrl: yup.string().required(),
    imageUrl: OptionalString,
    showType: yup.mixed().oneOf(Object.keys(WidgetShowType)).optional(),
  })

  const NewsWidgetSchema = BaseWidgetSchema.shape({
    type: yup.mixed().oneOf([WidgetType.NEWS_HORIZONTAL, WidgetType.NEWS_VERTICAL]).required(),
    title: yup.string().required(),
    imageUrl: yup.string().required(),
    description: yup.string().required(),
    actionName: OptionalString,
    actionTarget: ConditionalString('actionName'),
    showType: yup.mixed().oneOf(Object.keys(WidgetShowType)).optional(),
  })

  const GlobalRankingWidgetSchema = BaseWidgetSchema.shape({
    type: yup.mixed().oneOf([WidgetType.GLOBAL_RANKING]).required(),
    title: yup.string().required(),
    actionName: OptionalString,
    actionTarget: ConditionalString('actionName'),
    showType: yup.mixed().oneOf([WidgetShowType.ONLY_ONLINE]).optional(),
  })

  const widgetFormValidationSchemaProvider = (type: WidgetType): any => {
    switch (type) {
      case WidgetType.GLOBAL_RANKING:
        return GlobalRankingWidgetSchema
      case WidgetType.IMAGE:
        return ImageWidgetSchema
      case WidgetType.VIDEO:
        return VideoWidgetSchema
      case WidgetType.TEXT:
        return TextWidgetSchema
      case WidgetType.NEWS_VERTICAL:
      case WidgetType.NEWS_HORIZONTAL:
        return NewsWidgetSchema
      default:
        return BaseWidgetSchema
    }
  }

  return { widgetFormValidationSchemaProvider }
}

export { useWidgetFormValidationSchema }
