import { useQuery } from "react-query"

import { QUERY_KEYS } from "@api"

import { PredictionsApi } from "../api"


export const useCompetitions = () => {

    
    const { getCompetitions } = PredictionsApi

    const { data: competitions, isLoading: isLoadingCompetitions } = useQuery({
        queryKey: [QUERY_KEYS.COMPETITIONS],
        queryFn: async () => getCompetitions(),
        staleTime: 10000,
        refetchOnWindowFocus: false,

        // keepPreviousData: true,
        // staleTime: 5000,
      })


      return {
        competitions,
        isLoadingCompetitions,

    }
}