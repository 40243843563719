import { Skeleton } from 'primereact/skeleton'
import { Avatar } from 'primereact/avatar'
import { useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import styled from 'styled-components'

import { useUser } from '@contexts'
import { useLogout } from '@hooks'

const StyledBtnLogout = styled(Button)`
  cursor: pointer;
  color: ${props => props.theme.gray} !important;
  :focus {
    box-shadow: none !important;
  }
`

const UserHeader = () => {
  const { state: user } = useUser()
  const navigate = useNavigate()
  const { logout } = useLogout()

  const handleLogout = () => {
    logout()
    navigate('/login', { replace: true })
  }

  return (
    <nav className='bar-header'>
      {typeof user === 'undefined' ? (
        <>
          <Skeleton width='10rem' height='1rem' />
          <Skeleton shape='circle' size='2rem' />
        </>
      ) : (
        <>
          <span>
            {user?.firstName} {user?.lastName}
          </span>
          <Avatar shape='circle'>
            {user?.firstName?.charAt(0).toUpperCase()}
            {user?.lastName?.charAt(0).toUpperCase()}
          </Avatar>
          <StyledBtnLogout
            icon='pi pi-sign-out'
            className='p-button-rounded p-button-secondary p-button-text'
            aria-label='Logout'
            onClick={() => handleLogout()}
          />
        </>
      )}
    </nav>
  )
}

export { UserHeader }
