import React, {  useEffect, useState } from 'react'
import { Button, Input,  Modal, Spin, Form, List, InputNumber, Switch, Image, Col, Upload, UploadProps, Row, UploadFile, notification } from 'antd'
import { UploadOutlined,  } from '@ant-design/icons'
import { useTranslation } from "react-i18next"

import { useQuestionById, useQuizzes } from '../hooks'
import { Answer } from '../types'
import { DeleteAnswerModal } from './modals/DeleteAnswerModal'
import { QuizzesApi } from '../hooks/api'
import { AnswerEditable } from './AnswerEditable'




export interface QuestionDetailsQuizzProps {
    quizId: number,
    questionId: number
    onClose: () => void
    visible: boolean
    allowEdit: boolean
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

//   const tailLayout = {
//     wrapperCol: { offset: 8, span: 16 },
//   };

export const QuestionDetailsQuizz = ({questionId, visible, onClose, quizId, allowEdit} : QuestionDetailsQuizzProps) => {
    const { t } = useTranslation()
    
    const [newAnswerText, setNewAnswerText] = useState<string>('')
    const [newAnswerIsCorrect, setNewAnswerIsCorrect] = useState<boolean>(false)
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [deleteAnswerModalVisible, setDeleteAnswerModalVisible] = useState<boolean>(false)
    const [answerToDelete, setAnswerToDelete] = useState<Answer | undefined>(undefined)
    const [disabled, _] = useState<boolean>(!allowEdit)

    const { createAnswer, updateQuestion, updateAnswer} = useQuizzes()
    const { questionById, isLoadingQuestionById } = useQuestionById(questionId)

    const [form] = Form.useForm();

    const uploadProps: UploadProps = {
        disabled: !allowEdit,
        name: 'file',
        accept: '.png, .jpg, .jpeg',
        listType: "picture-card",
        defaultFileList: [],
        fileList: fileList,
        beforeUpload: ()=>false,
        multiple: false,
        maxCount: 1,
        onChange(info) {
  
          //console.log('info', info)
          if (info.fileList.length > 0) {
            setFileList([info.fileList[0]])

            //form.setFieldsValue({ ...questionById, imageFile: info.fileList[0].originFileObj as File })
          } else {
            setFileList([])

            //form.setFieldsValue({ ...questionById, imageFile: undefined })
          }
  
        },
      };

    useEffect(() => {
        if(questionById) {
            //console.log('questionById', questionById)

            //setQuestion(questionById)

            const correctCount = questionById?.answers?.filter((value)=>{return value.isCorrect === true}).length??0

            //console.log('correctCount', correctCount)

            form.setFieldsValue({
                ...questionById,
                answerType: correctCount > 1 ? 'MULTIPLE' : 'SINGLE',
            })

            setFileList([])
        }
    }, [questionById,form])



    const handleCreateAnswer = () => {
        const newAnswer = {
            questionId: questionById?.id,
            text: newAnswerText,
            isCorrect: newAnswerIsCorrect, 
            position: questionById?.answers?.length??0 + 1
        }

        createAnswer(newAnswer)
        setNewAnswerText('')
        setNewAnswerIsCorrect(false)
    }

    const handleUpdateAnswer = (answer: Answer) => {

        //check if one question is correct at less
        //const correctCount = questionById?.answers?.filter((value)=>{return value.isCorrect === true}).length??0
        const correctAnswers = questionById?.answers?.filter((value)=>{return value.isCorrect === true}) ||[]

        if(correctAnswers?.length === 1 && answer.id === correctAnswers[0].id && answer.isCorrect === false){
            notification.error({
                message: t('questions.notifications.one_correct_answer'),
            })

            return

        }


        if(questionById){
            //console.log('answer', answer)
            updateAnswer({
                id: answer.id!,
                isCorrect: answer.isCorrect,
                position: answer.position,
                questionId: questionById.id,
                text: answer.text
            })
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        form.validateFields().then(async (values) => {
            //console.log('values', values)
            
            let multimediaUrl = values.multimediaUrl ?? questionById?.multimediaUrl 

            if(fileList.length > 0) {
                const file = fileList[0].originFileObj as File
                console.log('file', file)
                multimediaUrl = await QuizzesApi.uploadQuestionImage(file)
               
            }

            const updatedQuestion = {
                ...questionById,
                ...values, 
                multimediaUrl,
                quizId: quizId
    
            }
            updateQuestion(updatedQuestion)
            onClose()


        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
        

    }

    const handleDeleteAnswer = (answer: Answer) => {
        console.log('answerId', {answer})
        setAnswerToDelete(answer)
        setDeleteAnswerModalVisible(true)
    }


    if(!questionById) return (<div>{t('quizzes.placeholders.loading')}</div>)

    return (
        <Spin spinning={isLoadingQuestionById}>
            <Modal
                width={800}
                open={visible}
                title={questionById.text}
                onCancel={onClose}
                maskClosable={false}
                transitionName="ant-fade"
                focusTriggerAfterClose={false}
                closable={false}
                footer={[
                <Button key="cancel" onClick={onClose}>
                    {t('quizzes.buttons.cancel')}
                </Button>,

                allowEdit && <Button key="submit" type="primary" onClick={handleSubmit}>
                    {t('quizzes.buttons.save')}
                </Button>,

                ]}
        >
            <Form {...layout} form={form}  onFinish={handleSubmit}>
                <Form.Item name="text" label="Text" rules={[{ required: true }]}>
                    <Input disabled={disabled} />
                </Form.Item>

                <Form.Item name="image" label="Image">
                    <Row>
                        {questionById.multimediaUrl && <Col style={{marginRight:'10px'}}>
                            <Image src={questionById.multimediaUrl} style={{ width: '100px', height: '100px' }} />
                        </Col>}
                        <Col flex={1}>
                            <Upload {...uploadProps}>
                                <Button icon={<UploadOutlined rev={''} />} disabled = {!allowEdit}></Button>
                            </Upload>
                        </Col>
                    </Row>

                </Form.Item>


                {/* <Form.Item name="multimediaUrl" label="Multimedia Url" >
                    <Input disabled={disabled} />
                </Form.Item> */}
                <Form.Item name="position" label="Position" rules={[{ required: true }]}>
                    <InputNumber disabled={disabled} />
                </Form.Item>
                <Form.Item name="answerType" label="Answer Type" rules={[{ required: true }]}>
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item name="mediaType" label="Media Type" hidden rules={[{ required: true }]}>
                    <Input disabled={disabled} />
                </Form.Item>
            </Form>

            <List
                header={t('questions.titles.answers')}
                footer={
                    allowEdit && <Form>
                        <Form.Item label={t('questions.labels.answer')}>
                            <Input placeholder={t('questions.placeholders.answer')} value={newAnswerText} onChange={
                                (e) => {
                                    setNewAnswerText(e.target.value)
                                }
                            } />
                        </Form.Item>
                        <Form.Item label={t('questions.labels.correct')} >
                            <Switch checked={newAnswerIsCorrect} onChange={
                                (checked) => {
                                    setNewAnswerIsCorrect(checked)
                                }
                            } />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" onClick={handleCreateAnswer}>{t('questions.buttons.add')}</Button>
                        </Form.Item>
                    </Form>

                }
                bordered
                dataSource={questionById.answers}
                renderItem={answer => (
                    <AnswerEditable key={answer.id} answer={answer} allowEdit={allowEdit} handleDeleteAnswer={handleDeleteAnswer} updateAnswer={handleUpdateAnswer} />

                    // <List.Item
                    //     key={answer.id}
                    //     actions={[
                    //         allowEdit && <a key="answer-delete" onClick={() => {handleDeleteAnswer(answer)}}>
                    //             {t('questions.buttons.delete')} {allowEdit}
                    //         </a>,]}
                    // >
                    //     {answer.isCorrect 
                    //         ? <CheckOutlined style={{  color: '#52c41a', paddingRight: 10 }} rev="" />
                    //         : <CloseCircleOutlined style={{ color: '#f5222d', paddingRight: 10  }} rev="" />
                    //     }

                    //     {answer.text}
                    // </List.Item>
                )}
            />
            </Modal>
            <DeleteAnswerModal answer={answerToDelete!} onClose={() => {setDeleteAnswerModalVisible(false)}} visible={deleteAnswerModalVisible} questionId={questionId} />
        </Spin>

    )
}