import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import { CopyOutlined, PlusOutlined } from '@ant-design/icons'

import { Title, TitleRow } from '@components'

import { useMedia } from '../../hooks'
import { CreateMediaModal } from '../../modals'
import { MediaPreview } from '../../components'

const MediaMain = () => {
  const { t } = useTranslation()
  const [page, updatePage] = useState(0)
  const { data, isLoading, refetch } = useMedia(page)
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)

  const copy = (url: string) => {
    navigator.clipboard.writeText(url).then()
  }
  const columns = [
    {
      title: t('media.table_headers.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('media.table_headers.preview'),
      key: 'preview',
      render: media => <MediaPreview media={media} />,
    },
    {
      title: t('media.table_headers.url'),
      key: 'url',
      render: media => (
        <Tooltip title={'Click to copy url'}>
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault()
              copy(media.url)
            }}
            style={{ display: 'block', maxWidth: 400 }}
          >
            <CopyOutlined rev='' /> {media.url}
          </a>
        </Tooltip>
      ),
    },
    {
      title: t('media.table_headers.mimetype'),
      dataIndex: 'mimeType',
      key: 'mimetype',
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
          <Col span={24}>
            <TitleRow>
              <Col style={{ marginRight: '1%' }}>
                <Title>{t('menu.media')}</Title>
              </Col>
              <Col style={{ marginBottom: '1%' }}>
                <Tooltip title={t('media.buttons.tooltips.new')}>
                  <Button type='primary' onClick={() => setCreateModalOpen(true)}>
                    <PlusOutlined rev='' />
                    {t('media.buttons.new')}
                  </Button>
                </Tooltip>
              </Col>
            </TitleRow>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={data?.data}
              rowKey='id'
              pagination={{
                position: ['bottomCenter'],
                total: data?.total,
                current: (data?.page ?? 0) + 1,
                pageSize: data?.pageSize ?? 10,
              }}
              onChange={pagination => {
                if (pagination) {
                  updatePage(pagination.current! - 1)
                }
              }}
            />
          </Col>
        </Col>
        <CreateMediaModal
          visible={createModalOpen}
          onClose={async () => {
            setCreateModalOpen(false)
            await refetch()
          }}
        />
      </Row>
    </>
  )
}

export { MediaMain }
