import { WidgetContent } from '../styled'
import { VideoWidgetType } from '../../types'

const VideoWidget = (props: VideoWidgetType) => {
  const { imageUrl, videoUrl } = props

  return (
    <WidgetContent style={{ padding: 0, overflow: 'hidden' }}>
      <video poster={imageUrl} width={'100%'} height={'100%'} controls style={{ backgroundColor: '#000000' }}>
        <source src={videoUrl} />
      </video>
    </WidgetContent>
  )
}
export { VideoWidget }
