import { api } from '@api'
import { PaginatedResponse } from '@types'

import { Media, MediaType } from '../types'

const getMedia = async (page: string | number, type?: MediaType) => {
  const params = new URLSearchParams()
  params.set('page', `${page}`)
  params.set('pageSize', '50')
  if (type) {
    params.set('type', `${type}`)
  }
  const response = await api.get<PaginatedResponse<Media>>(`/media?${params.toString()}`)

  return response?.data
}

const createMedia = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await api.post<Media>('/media/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response?.data
}
export const MediaApi = { getMedia, createMedia }
