import React, { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'

import { useUser } from '@contexts'

const PrivateRoute = ({ children }: PropsWithChildren<any>) => {
  const { state: user } = useUser()

  return user ? <>{children}</> : <Navigate to='/login' />
}

export { PrivateRoute }
