import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { message } from "antd"

import { QUERY_KEYS } from "@api"

import { PredictionsApi } from "../api"
import {  PredictionsResponse } from "../../types"

export const usePredictions = () => {

    const [currentPage, setCurrentPage] = useState<number>(1)

    const queryClient = useQueryClient()


    const moveToPage = (page: number) => {
      setCurrentPage(page)
    }

    
    const {mutate: createPrediction, isLoading: idLoadingCreatePrediction} = useMutation(PredictionsApi.createPrediction, {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSuccess: (data, variabes) => {
          message.success('Prediction created')
          
          //console.log('onSuccess')
 
          queryClient.invalidateQueries([QUERY_KEYS.PREDICTIONS_MATCHES])
          queryClient.invalidateQueries([QUERY_KEYS.PREDICTIONS,currentPage])
          
        },
        onError: () => {
          message.error({content: "error creating prediction"}) 
        },

    })



    const { data: predictions, isLoading: isLoadingPredictions } = useQuery<PredictionsResponse>({
        queryKey: [QUERY_KEYS.PREDICTIONS,currentPage],
        queryFn: async () => PredictionsApi.getPredictions(currentPage),
        keepPreviousData: true,
        staleTime: 5000,
      })
    
    return {

        predictions,
        isLoadingPredictions,
        createPrediction,
        idLoadingCreatePrediction,
        moveToPage
    }



}