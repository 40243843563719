import { Button } from 'antd'

import { WidgetContent } from '../styled'
import { TextWidgetType } from '../../types'

const TextWidget = (props: TextWidgetType) => {
  const { title, description, actionName, actionTarget } = props

  const onClick = actionTarget ? () => open(actionTarget, '_blank') : undefined

  return (
    <WidgetContent onClick={onClick}>
      <h1 style={{ paddingBottom: 3, color: 'white', textTransform: 'uppercase' }}>
        <b>{title}</b>
      </h1>
      {!!description && <div>{description}</div>}
      {!!actionName && !!onClick && (
        <Button style={{ marginTop: 8, fontWeight: 700, fontSize: 18, width: '100%' }} onClick={onClick}>
          {actionName}
        </Button>
      )}
    </WidgetContent>
  )
}
export { TextWidget }
