import { useState } from "react"
import { Button, Col, Modal, Row, Table, Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { DeleteOutlined } from "@ant-design/icons"

import { Challenge, ChallengeRequirementItemDto } from "../../types"
import { useChallenge } from "../../hooks"
import { MetadataChallengeModal } from "../MetadataChallengeModal"
import { DeleteRequiredItemModal } from "../DeleteRequiredItemModal"


interface ChallengeRequirementItemsModalProps {
    visible: boolean
    challenge?: Challenge
    onClose: () => void
}



export const ChallengeRequirementItemsModal = (props: ChallengeRequirementItemsModalProps) => {
    

    const { t } = useTranslation()
    const { visible, challenge, onClose } = props
    const {challenge: challengeById } = useChallenge(challenge?.id || 0)

    const [metadataModalVisible, setMetadataModalVisible] = useState(false)
    const [itemTodelete, setItemToDelete] = useState<ChallengeRequirementItemDto | undefined>()


    const onCloseMetadataModal = () => {
        setMetadataModalVisible(false)
    }

    // useEffect(() => {
    //     console.log('requirements', challengeById?.requirements)

    //     // const metadatas = challengeById?.requirements?.map((requirement) => {
    //     //   return {
    //     //     id: requirement.id,
    //     //     ...requirement.metadata
    //     //   }
    //     // })
    //     // console.log('metadatas', metadatas)

          

    // }
    // , [challengeById])

    const columns = [
        // {
        //     title: 'id',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: '100'
        // },
        {
            title: t('challenges.modals.metadata.table_headers.name'),
            key: 'name',
            render: (record: any) => (
                <span>{record.metadata?.name||''}</span>
            ),
            width: '300'
        },
        {
            title: t('challenges.modals.metadata.table_headers.type'),
            key: 'type',
            render: (record: any) => (
                <span>{record.metadata?.type||''}</span>
            ),
            width: '200'
        },
        {
            title: t('challenges.modals.metadata.table_headers.year'),
            key: 'year',
            render: (record: any) => (
                <span>{record.metadata?.year||''}</span>
            )
        },
        {
            title: t('challenges.modals.metadata.table_headers.season'),
            key: 'season',
            render: (record: any) => (
                <span>{record.metadata?.season||''}</span>
            )
        },
        {
            title: t('challenges.modals.metadata.table_headers.brand'),
            key: 'brand',
            render: (record: any) => (
                <span>{record.metadata?.brand||''}</span>
            )
        },
        {
            title: t('challenges.modals.metadata.table_headers.equipmentType'),
            key: 'equipmentType',
            render: (record: any) => (
                <span>{record.metadata?.equipmentType||''}</span>
            )
        },
        {
            title: t('challenges.modals.metadata.table_headers.isSigned'),
            key: 'isSigned',
            render: (record: any) => (
                <span>{record.metadata?.isSigned||''}</span>
            )
        },
        {
            title: t('challenges.modals.metadata.table_headers.signer'),
            key: 'signer',
            render: (record: any) => (
                <span>{record.metadata?.signer||''}</span>
            )
        },
        {
            title:  t('challenges.modals.metadata.table_headers.dorsal'),
            key: 'dorsal',
            render: (record: any) => (
                <span>{record.metadata?.dorsal||''}</span>
            )
        },
        {
            title:  t('challenges.modals.metadata.table_headers.history'),
            key: 'history',
            render: (record: any) => (
                <span>{record.metadata?.history||''}</span>
            )
        },
        {
            title:  t('challenges.modals.metadata.table_headers.team'),
            key: 'team',
            render: (record: any) => (
                <span>{record.metadata?.team?.id||''}</span>
            )
        },
        {
            title: t('common.action'),
            key: 'actions',
            render: (record: any) => (
                <>
                <Row>
                    <Col style={{ marginRight: '5px' }}>
                        <Tooltip title={t('quizzes.buttons.tooltips.delete')}>
                            <Button
                            type='primary'
                            danger
                            onClick={() => {
                              setItemToDelete({...record})
                            }}
                            icon={<DeleteOutlined rev='' />}
                            />
                        </Tooltip>
                    </Col>
                </Row>
                </>
            )
        },


        
        
    ]
       


    return (
        <><Modal
            width={800}
            open={visible}
            title={t('challenges.modals.metadata.title')}
            onCancel={onClose}
            transitionName='ant-fade'
            focusTriggerAfterClose={false}
            maskClosable={false}
            closable={false}
            footer={[
                <Button key='cancel' onClick={onClose}>
                    {t('challenges.modals.metadata.cancel')}
                </Button>,
                <Button
                    type='primary'
                    key='delete'
                    onClick={() => {
                        setMetadataModalVisible(true)
                    } }
                >
                    {t('challenges.modals.metadata.add')}
                </Button>,
            ]}
        >
            <Table
                dataSource={challengeById?.requirements?.filter((requirement) => requirement.metadata)}
                columns={columns}
                rowKey="id"
                scroll={{ x: 1300 }} />
            <MetadataChallengeModal visible={metadataModalVisible} challenge={challenge} onClose={onCloseMetadataModal} />
        </Modal>
        
        <DeleteRequiredItemModal
                visible={!!itemTodelete}
                onClose={() => setItemToDelete(undefined)}
                item={itemTodelete}
                challengeId={challenge?.id || 0} />
    </>
    )
    
}
