import { Button, Col, Row, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'

import { useContracts } from './hooks'
import { Contract } from './types'
import { StyledAlignedTitleRow, StyledTitle } from './components'

const Contracts = () => {
  const { t } = useTranslation()
  const { contracts, isLoading } = useContracts()
  const columns = [
    {
      title: t('contracts.table_headers.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('contracts.table_headers.name'),
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: t('contracts.table_headers.address'),
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: t('contracts.table_headers.type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: t('contracts.table_headers.symbol'),
      dataIndex: 'symbol',
      key: 'symbol',
    },
    {
      title: t('common.action'),
      key: 'action',
      render: (contract: Contract) => (
        <>
          <Row>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('contracts.buttons.tooltips.edit')}>
                <Button
                  type='primary'
                  onClick={() => {
                    console.log(contract)
                  }}
                  icon={<EditOutlined rev='' />}
                />
              </Tooltip>
            </Col>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('contracts.buttons.tooltips.delete')}>
                <Button type='primary' danger onClick={() => {}} icon={<DeleteOutlined rev=''/>} />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
          <StyledAlignedTitleRow>
            <Col style={{ marginRight: '1%' }}>
              <StyledTitle>{t('menu.contracts')}</StyledTitle>
            </Col>
            <Col style={{ marginBottom: '1%' }}>
              <Tooltip title={t('contracts.buttons.tooltips.new')}>
                <Button type='primary' onClick={() => {}}>
                  <PlusOutlined rev=''/>
                  {t('contracts.buttons.new')}
                </Button>
              </Tooltip>
            </Col>
          </StyledAlignedTitleRow>
        </Col>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={contracts?.data}
            rowKey='id'
            pagination={{
              position: ['bottomCenter'],
              total: contracts?.total,
              current: contracts?.page,
            }}
          />
        </Col>
      </Col>
    </Row>
  )
}

export { Contracts }
