import * as React from 'react'
import { useState } from 'react'
import { Form, Input } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'

import { Team, TeamType } from '@types'

import { TeamPickerModal } from '../../modals'

interface Props {
  validateStatus?: ValidateStatus
  help?: React.ReactNode
  name: string
  label: string
  type?: TeamType
  team?: Team
  onChange: (team: Team | null) => void
}
const TeamPickerInput = (props: Props) => {
  const { label, type, team, onChange, name, validateStatus, help } = props
  const [open, openModal] = useState(false)

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        validateStatus={validateStatus}
        help={help}
        key={'input'}
        valuePropName={'name'}
      >
        <Input value={team?.name} disabled={!type} onClick={() => openModal(true)} readOnly={true} />
      </Form.Item>

      {type && (
        <TeamPickerModal
          key={`modal-${type}`}
          onClose={() => openModal(false)}
          visible={open}
          maxTeams={1}
          type={type!}
          onChange={t => onChange(t ? t.shift() ?? null : null)}
        />
      )}
    </>
  )
}

export { TeamPickerInput }
