import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { BaseDeleteModal } from '@components'

import { ChallengeRequirementItemDto } from '../../types'
import { useOnDeleteSuccess } from '../../hooks'
import { ChallengesApi } from '../../hooks/api'

interface DeleteRequiredItemModalProps {
  visible: boolean
  onClose: () => void
  item?: ChallengeRequirementItemDto
  challengeId: number
}

const DeleteRequiredItemModal = ({ visible, onClose, item, challengeId }: DeleteRequiredItemModalProps) => {
  const { t } = useTranslation()
  const { onDeleteRequirementSuccess } = useOnDeleteSuccess()

  useEffect(() => {
    console.log('challengeId', challengeId)
  }
    , [challengeId])

  const { deleteChallengeRequirementItem } = ChallengesApi

  return item?.id ? (
    <BaseDeleteModal<ChallengeRequirementItemDto>
      visible={visible}
      onClose={onClose}
      itemId={item.id}
      title={t('challenges.modals.delete_requirement.title')}
      confirmDeleteText={`${t('challenges.modals.delete_requirement.delete_confirmation')} ${item.id} ${t(
        'challenges.modals.delete_requirement.delete_confirmation_end',
      )}`}
      request={id => deleteChallengeRequirementItem(id as number)}
      onSuccess={ ()=> onDeleteRequirementSuccess(challengeId!) }
    />
  ) : null
}

export { DeleteRequiredItemModal }