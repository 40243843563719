import { Button, Checkbox, Col, Form, Input, message, Radio, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'

import { Title, TitleRow } from '@components'
import { ItemEquipmentType, ItemMediaFiles, TeamType } from '@types'
import { useBrands } from '@hooks'

import { TeamPickerInput } from '../../../Teams'
import { DigitizeForm } from '../../types'
import { useDigitizeFormValidationSchema } from './validation'
import { TShirtImagePicker } from '../../components'
import { CollectiblesApi } from '../../api'

const { TextArea } = Input

const initialValues: DigitizeForm = {
  quantity: 3,
  dorsal: '',
  history: '',
  name: '',
  season: '',
  year: '',
  isSigned: false,
  signer: '',
}
const CollectiblesCreate = () => {
  const { t } = useTranslation()
  const { digitizeFormValidationSchema } = useDigitizeFormValidationSchema()
  const { brands } = useBrands()
  const [itemMediaFiles, setItemMediaFiles] = useState<ItemMediaFiles>({
    frontImage: '',
    backImage: '',
    rightArmImage: '',
    leftArmImage: '',
  })

  const navigate = useNavigate()
  const { mutate: create, isLoading } = useMutation(CollectiblesApi.create, {
    onSuccess: () => {
      message.success('Collectibles created')
      navigate(`/collectibles`)
    },
    onError: () => {
      message.error('Error submitting form')
    },
  })
  const [form] = Form.useForm()

  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const validateField = useCallback(
    async (name, value) => {
      try {
        await digitizeFormValidationSchema.validateAt(name, { [name]: value, ...form.getFieldsValue() })
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
      } catch (error: any) {
        setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }))
      }
    },
    [form, digitizeFormValidationSchema],
  )

  const onFieldsChange = useCallback(() => {
    const fields = form.getFieldsValue()
    Object.keys(fields).forEach(field => {
      validateField(field, fields[field]).then()
    })
  }, [form, validateField])

  useEffect(() => {
    onFieldsChange()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeItemMediaFile = (name: string, url: string) => {
    setItemMediaFiles(prev => {
      return { ...prev, [name]: url }
    })
  }

  const signed = form.getFieldValue('isSigned')
  const fieldErrors = form.getFieldsError()
  const formValid = useMemo(() => {
    return !fieldErrors.some(item => item.errors.length > 0)
  }, [fieldErrors])

  const formImagesValid = useMemo(() => {
    const { frontImage, backImage, leftArmImage, rightArmImage, signatureImage } = itemMediaFiles
    const basicImagesCompleted = ![frontImage, backImage, leftArmImage, rightArmImage].includes('')

    return signed ? basicImagesCompleted && !!signatureImage : basicImagesCompleted
  }, [itemMediaFiles, signed])

  useEffect(() => {
    if (!signed) {
      form.setFieldValue('signer', '')
      setItemMediaFiles(prev => {
        const { signatureImage: _, ...others } = prev

        return { ...others }
      })
    }
  }, [signed, form])

  const onFinish = useCallback(
    (values: DigitizeForm) => {
      const { quantity, year, dorsal, signer = '', equipment, brand, team, type, ...others } = values
      create({
        quantity: Number(quantity),
        metadata: {
          ...others,
          signer,
          equipmentType: equipment!,
          brand: brand!,
          year: Number(year),
          dorsal: Number(dorsal),
          team: team!,
          type: type!,
          mediaFiles: { ...itemMediaFiles },
        },
      })
    },
    [itemMediaFiles, create],
  )

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
          <TitleRow>
            <Col style={{ marginRight: '1%' }}>
              <Title>{t('collectibles.create.title')}</Title>
            </Col>
          </TitleRow>
        </Col>
        <Row>
          <Col span={8}>
            <TShirtImagePicker
              label={t('collectibles.create.images.front')}
              name={'frontImage'}
              url={itemMediaFiles.frontImage}
              onChange={(name, url) => onChangeItemMediaFile(name, url)}
            />
            <TShirtImagePicker
              label={t('collectibles.create.images.back')}
              name={'backImage'}
              url={itemMediaFiles.backImage}
              onChange={(name, url) => onChangeItemMediaFile(name, url)}
            />

            <TShirtImagePicker
              label={t('collectibles.create.images.right-side')}
              name={'rightArmImage'}
              url={itemMediaFiles.rightArmImage}
              onChange={(name, url) => onChangeItemMediaFile(name, url)}
            />
            <TShirtImagePicker
              label={t('collectibles.create.images.left-side')}
              name={'leftArmImage'}
              url={itemMediaFiles.leftArmImage}
              onChange={(name, url) => onChangeItemMediaFile(name, url)}
            />
            {form.getFieldValue('isSigned') && (
              <TShirtImagePicker
                label={t('collectibles.create.images.signature')}
                name={'signatureImage'}
                url={itemMediaFiles.signatureImage}
                onChange={(name, url) => onChangeItemMediaFile(name, url)}
              />
            )}
          </Col>
          <Col span={16}>
            <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 14 }}
              layout='horizontal'
              style={{ maxWidth: 600 }}
              form={form}
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
              initialValues={initialValues}
            >
              <Form.Item
                name={'quantity'}
                label={t('collectibles.create.form.input.quantity')}
                validateStatus={errors.quantity ? 'error' : ''}
                help={errors.quantity}
              >
                <Input type={'number'} />
              </Form.Item>
              <Form.Item
                label={t('collectibles.create.form.input.team-type.label')}
                name={'type'}
                validateStatus={errors.type ? 'error' : ''}
                help={errors.type}
              >
                <Radio.Group>
                  <Radio value={TeamType.NATIONAL_TEAM}>
                    {t('collectibles.create.form.input.team-type.national-team')}
                  </Radio>
                  <Radio value={TeamType.TEAM}> {t('collectibles.create.form.input.team-type.club')} </Radio>
                </Radio.Group>
              </Form.Item>

              <TeamPickerInput
                name={'team'}
                validateStatus={errors.team ? 'error' : ''}
                help={errors.team}
                type={form.getFieldValue('type')}
                team={form.getFieldValue('team')}
                label={t('collectibles.create.form.input.team')}
                onChange={t => {
                  form.setFieldValue('team', t)
                  onFieldsChange()
                }}
              />

              <Form.Item
                name={'season'}
                label={t('collectibles.create.form.input.season')}
                validateStatus={errors.season ? 'error' : ''}
                help={errors.season}
              >
                <Input placeholder={'YYYY/YYYY'} />
              </Form.Item>
              <Form.Item
                name={'year'}
                label={t('collectibles.create.form.input.year')}
                validateStatus={errors.year ? 'error' : ''}
                help={errors.year}
              >
                <Input placeholder={'YYYY'} />
              </Form.Item>
              <Form.Item
                label={t('collectibles.create.form.input.brand')}
                name={'brand'}
                validateStatus={errors.brand ? 'error' : ''}
                help={errors.brand}
              >
                <Select>
                  {brands.map(b => (
                    <Select.Option value={b.id}>{b.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={'dorsal'}
                label={t('collectibles.create.form.input.dorsal')}
                validateStatus={errors.dorsal ? 'error' : ''}
                help={errors.dorsal}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('collectibles.create.form.input.name')}
                validateStatus={errors.name ? 'error' : ''}
                help={errors.name}
                name={'name'}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('collectibles.create.form.input.equipment')}
                name={'equipment'}
                validateStatus={errors.equipment ? 'error' : ''}
                help={errors.equipment}
              >
                <Select>
                  <Select.Option value={ItemEquipmentType.FIRST}>First</Select.Option>
                  <Select.Option value={ItemEquipmentType.SECOND}>Second</Select.Option>
                  <Select.Option value={ItemEquipmentType.THIRD}>Third</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label={t('collectibles.create.form.input.signed')} name='isSigned' valuePropName='checked'>
                <Checkbox></Checkbox>
              </Form.Item>

              {signed && (
                <Form.Item
                  name={'signer'}
                  label={t('collectibles.create.form.input.signer')}
                  validateStatus={errors.signer ? 'error' : ''}
                  help={errors.signer}
                >
                  <Input />
                </Form.Item>
              )}

              <Form.Item label={t('collectibles.create.form.input.history')} name={'history'}>
                <TextArea rows={4} name={'history'} />
              </Form.Item>
              <Form.Item>
                <Button disabled={!formValid || !formImagesValid || isLoading} type='primary' htmlType='submit'>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export { CollectiblesCreate }
