// eslint-disable-next-line no-shadow
export enum ErrorEnum {
  UNKNOWN = 'UNKNOWN',
  PASSWORD_FORMAT_WRONG = 'PASSWORD_FORMAT_WRONG',
  EMAIL_FORMAT_WRONG = 'EMAIL_FORMAT_WRONG',
  COUNTRY_MANDATORY = 'COUNTRY_MANDATORY',
  PHONE_NUMBER_MANDATORY = 'PHONE_NUMBER_MANDATORY',
  USER_ALREADY_EXISTING = 'USER_ALREADY_EXISTING',
  USER_ALREADY_VERIFIED = 'USER_ALREADY_VERIFIED',
  USER_NOT_ADMIN = 'USER_NOT_ADMIN',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  INCORRECT_USER_PASSWORD = 'INCORRECT_USER_PASSWORD',
  INCORRECT_TEAM_NAME = 'INCORRECT_TEAM_NAME',
  LOGIN_ATTEMPTS_EXCEEDED = 'LOGIN_ATTEMPTS_EXCEEDED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  INVALID_FILE_UPLOAD = 'INVALID_FILE_UPLOAD',
  BLOCKED_USER = 'BLOCKED_USER',
}

export interface ApiError {
  type: ErrorEnum
  message: string
}
