import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { ContractsApi } from '../api'

const useContracts = () => {
  const { data, isLoading } = useQuery([QUERY_KEYS.CONTRACTS], () => ContractsApi.getContracts())

  return {
    contracts: data,
    isLoading,
  }
}

export { useContracts }
