// eslint-disable react/no-array-index-key
import { Breadcrumb } from 'antd'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1%;
  display: flex;

  span {
    color: #7d86a9;
    display: inline-block;

    .last {
      font-weight: 700;
    }
    .hasNavigation {
      cursor: pointer;
    }
  }
`

interface BreadcrumbsProps {
  navigation: string[]
  path?: string
}

const Breadcrumbs = ({ navigation, path }: BreadcrumbsProps) => {
  const navigate = useNavigate()

  return (
    <StyledBreadcrumb>
      {navigation.map((nav, index) => {
        const isLast = navigation.length - 1 === index

        return (
          <Breadcrumb.Item key={index} onClick={() => !isLast && path && navigate(path)}>
            <p className={isLast ? 'last' : 'hasNavigation'}>{nav}</p>
          </Breadcrumb.Item>
        )
      })}
    </StyledBreadcrumb>
  )
}

export { Breadcrumbs }
