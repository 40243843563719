// import { useEffect } from "react"
import { useEffect, useState } from "react"
import { Button, Col, Modal, Row, Select, Table, Tooltip } from "antd"
import { useTranslation } from "react-i18next"

import { StyledAlignedTitleRow, StyledTitle } from "../../components"
import { CATEGORY_ID } from "../../constants"
import { useMatches } from "../../hooks/useMatches"
import { usePredictions } from "../../hooks/usePredictions"
import { useCompetitions } from "../../hooks/useCompetitions"





interface NextMatchesModalProps {
    visible: boolean
    onClose: () => void
}

export const NextMatchesModal = (props: NextMatchesModalProps) => {
    
    const { visible, onClose } = props
    const [selectedCompetition, setSelectedCompetition] = useState<string>(CATEGORY_ID)
    const { createPrediction } = usePredictions()
    const { competitions, isLoadingCompetitions } = useCompetitions()
    const [rounds, setRounds] = useState<number[]>([])
    const [selectedRound, setSelectedRound] = useState<number|undefined>(undefined)
    
    const { matches, isLoadingMatches } = useMatches(selectedCompetition, selectedRound??0)

    // const [matchesWithPredictions, setMatchesWithPredictions] = useState<MatchDto[]>(matches??[])


    const { t } = useTranslation()

    const columns = [
        {
          title: t('predictions.modals.next_matches_modal.table_headers.id'),
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.match'),          
          key: 'match',
          render: (text, record) => (
            <div>{record.local} vs {record.visitor}</div>
          ),
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.round'),
          dataIndex: 'round',
          key: 'round',
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.schedule'),
          key: 'schedule',
          render: (text, record) => (
            <div>{new Date(record.schedule).toLocaleString('es-ES', { hour: '2-digit', minute: '2-digit', day: '2-digit', month: '2-digit', year: 'numeric' })}</div>
          ),
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.status'),
          key: 'status',
          render: (text, record) => (
            <span>
              {record.hasPrediction ? 'Con prediccion' : 'Sin prediccion'}
            </span>  
            )
        },
        {
          title: t('predictions.modals.next_matches_modal.table_headers.actions'),
          key: 'actions',
          render: (text, record) => (
            <div>
              <Tooltip title={t('predictions.buttons.tooltips.create_prediction')}>
                <Button 
                    type='primary' 
                    onClick={() => createPrediction(record.id)}
                    disabled={record.hasPrediction || new Date(record.schedule) < new Date()}
                    >
                  {t('predictions.buttons.create_prediction')}
                </Button>
              </Tooltip>
            </div>
          ),
        },
    ]

    // useEffect(() => {
    //   console.log(competitions)

    // }, [competitions])


    const onChangerCompetition = (value: string) => {
      setSelectedRound(undefined)
      setSelectedCompetition(value)
    }

    const onChangeRound = (value: number) => {
      setSelectedRound(value)
    }

    useEffect(() => {
      const competition = competitions?.category.find(competition => competition.id === selectedCompetition)
      const currentRound = Number(competition?.current_round ?? "1")
      const totalRounds = Number(competition?.total_rounds ?? "1")

      if (currentRound && totalRounds) {
        const roundsArray = Array.from({ length: totalRounds - currentRound + 1 }, (_, i) => i + currentRound)
        setRounds(roundsArray)
        setSelectedRound(currentRound)
      }
      else {
        setRounds([])
        setSelectedRound(undefined)
      }
    }, [selectedCompetition, competitions])

    return (

        <Modal
          width={1000}
          open={visible}
          title={t('predictions.modals.next_matches_modal.title')}
          onCancel={onClose}
          transitionName='ant-fade'
          focusTriggerAfterClose={false}
          maskClosable={false}
          closable={false}

          footer={[
              <Button key='cancel' onClick={onClose}>
                  {t('predictions.buttons.cancel')}
              </Button>,

              // <Button
              //     type='primary'
              //     key='delete'
              //     onClick={() => {
              //         //form.submit()
              //     }}
              // >
              // {t('challenges.modals.metadata.save')}
              // </Button>,
        ]}
    >
      <Row>
        <Col span={12} style={{ marginBottom: '2%' }}>
          <label>{t('predictions.modals.next_matches_modal.placeholders.select_competition')}</label>
          <Select 
            onChange={onChangerCompetition} 
            size="large" 
            style={{width: '90%'}}
            placeholder={t('predictions.modals.next_matches_modal.placeholders.select_competition')}
            value={selectedCompetition}
            >
            {

              isLoadingCompetitions 
                ? <Select.Option value='loading'>Loading...</Select.Option> 
                : competitions?.category.map(competition => (
                    <Select.Option key={competition.id} value={competition.id}>{competition.name}</Select.Option>
                  ))
            }
          </Select>

        </Col>

        <Col span={12} style={{ marginBottom: '2%' }}>
          <label>{t('predictions.modals.next_matches_modal.placeholders.select_round')}</label>
          <Select 

            onChange={onChangeRound}
            size="large" 
            style={{width: '90%'}}
            placeholder={t('predictions.modals.next_matches_modal.placeholders.select_round')}
            value={selectedRound}
            >
            {
              isLoadingCompetitions 
                ? <Select.Option value='loading'>Loading...</Select.Option> 
                : rounds?.map(round => (
                    <Select.Option key={round} value={round}>{round}</Select.Option>
                  ))
            }
          </Select>

        </Col>
      </Row>
      {isLoadingMatches && !matches 
      ? <div>Loading...</div>
      : <>

          <Row>
              <Col span={24} style={{ marginBottom: '2%' }}>
              <Col span={24}>
                  <StyledAlignedTitleRow>
                  <Col style={{ marginRight: '1%' }}>
                      <StyledTitle>{t('predictions.modals.next_matches_modal.title')}</StyledTitle>
                  </Col>
                  </StyledAlignedTitleRow>
              </Col>
              <Col span={24}>
                  <Table

                  // loading={isLoading}
                  columns={columns}

                  dataSource={matches}
                  rowKey='id'
                  pagination={{
                      
                      position: ['bottomCenter'],

                      // total: (predictions?.total || 0),
                      // current: (predictions?.page || 0)+1,
                      // onChange: (page) => {
                      // moveToPage(page)
                      // }
                  }}
                  />
              </Col>
              </Col>
          </Row>
        </>
    
    }

    </Modal>





  )
}