import { PaginatedRequest, PaginatedResponse } from '@types'
import { api } from '@api'

import { Widget, WidgetForm, WidgetsFilters } from '../types'

const paginate = async (params: PaginatedRequest<WidgetsFilters>) => {
  const { page, filters } = params
  const response = await api.get<PaginatedResponse<Widget>>('/widgets', { params: { page, ...filters } })

  return response.data
}

const create = async (data: WidgetForm) => {
  const response = await api.post<Widget>('/widgets', data)

  return response.data
}

const update = async (data: WidgetForm) => {
  console.log('update', data)
  const { id, ...form } = data
  if (!id) throw new Error('widget id required')
  const response = await api.put<Widget>(`/widgets/${id}`, form)

  return response.data
}

const getById = async (id: string | number) => {
  const response = await api.get<Widget>(`/widgets/${id}`)

  return response.data
}

const deleteById = async (id: string | number) => {
  await api.delete<void>(`/widgets/${id}`)
}
export const WidgetsApi = { paginate, create, update, getById, deleteById }
