import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

//import { t } from 'i18next'

import { StyledAlignedTitleRow, StyledTitle } from '../ReviewItem/components'
import { useReviewItems } from './hooks'
import { ReviewItem } from './types'
import ValidateYesNo from './components/Modals/ValidateYesNo'

const ReviewItems = () => {
  const [validateYesNoVisible, setValidateYesNoVisible] = React.useState<boolean>(false)
  const [itemToValidate, setItemToValidate] = React.useState<ReviewItem>()

  const { t } = useTranslation()

  const { isLoading, reviewItems } = useReviewItems()

  //console.log('reviewItems', reviewItems)
  //console.log('isLoading', isLoading)
  function onValidateItem(item: ReviewItem) {
    setItemToValidate(item)
    setValidateYesNoVisible(true)

    //console.log('id', id)

    //throw new Error('Function not implemented.')
  }
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Metadata',
      key: 'metadata',
      render: (item: ReviewItem) => (
        <Row>
          <Col>{`${item.metadata?.name} - ${item.metadata?.type} - ${item.metadata?.year}`}</Col>
        </Row>
      ),
    },
    {
      title: t('common.action'),
      key: 'action',
      render: (item: ReviewItem) => (
        <>
          <Row>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('review-items.buttons.tooltips.validate')}>
                <Button
                  type='primary'
                  onClick={() => {
                    onValidateItem(item)
                  }}
                  icon={<CheckCircleOutlined rev={''} />}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  // useEffect(() => {
  //   console.log('reviewItems', reviewItems)
  // }, [reviewItems])

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
          <Col span={24}>
            <StyledAlignedTitleRow>
              <Col style={{ marginRight: '1%' }}>
                <StyledTitle>{t('menu.reviewItems')}</StyledTitle>
              </Col>
            </StyledAlignedTitleRow>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={reviewItems?.data}
              rowKey='id'
              pagination={{
                position: ['bottomCenter'],
                total: reviewItems?.total || 0,
                current: (reviewItems?.page || 0) + 1,
              }}
            />
          </Col>
        </Col>
        <ValidateYesNo
          item={itemToValidate}
          visible={validateYesNoVisible}
          onClose={() => setValidateYesNoVisible(false)}
        />
      </Row>
    </>
  )
}

export default ReviewItems
