import { api } from '@api'

import { Question, Quizz, QuizzResponse } from '../types'


interface CreateQuizzParams {
  name: string | undefined,
  description: string | undefined,
  imageUrl: string | undefined,
  startDate: Date,
  endDate: Date, 
  enabled: boolean
}

interface UpdateQuizzParams {
  quizzId: number | undefined,
  name: string | undefined,
  description: string | undefined,
  imageUrl: string | undefined,
  startDate: Date,
  endDate: Date, 
  enabled: boolean
}

interface CreateAnswerParams {
  questionId?: number | undefined,
  text: string | undefined,
  isCorrect: boolean, 
  position: number
}

interface UpdateAnswerParams {
  questionId: number ,
  id: number,
  text: string | undefined,
  isCorrect: boolean, 
  position: number
}

interface CreateQuestionParams {
  quizId: number | undefined,
  text: string | undefined,
  answerType: string | undefined,
  mediaType: string | undefined,
  multimediaUrl: string | undefined,
  position: number, 
  answers: CreateAnswerParams[]

  // quizId": 1,
	// "text": "¿River es el mejor cuadro del mundo?",
	// "answerType": "SINGLE",
	// "mediaType": "TEXT",
	// "multimediaUrl": "https://frontend.dev.shirtum.com/static/media/question1.76fe26617e644cc04ea4.jpg",
	// "position": 2, 
}

interface UpdateQuestionParams {
  id: number | undefined,
  quizId: number | undefined,
  text: string | undefined,
  answerType: string | undefined,
  mediaType: string | undefined,
  multimediaUrl: string | undefined,
  position: number, 
  answers: CreateAnswerParams[]
}



const getQuizzes = async (page?: number) => {
  const response = await api.get<QuizzResponse>(`/quizzes${page ? `?page=${page}` : ''}`)

  return response?.data
}

const getQuizzById = async (id: number) => {
  const response = await api.get<Quizz>(`/quizzes/${id}`)

  return response?.data
}

const createQuizz = async (params: CreateQuizzParams) => {
  const response = await api.post('/quizzes', { ...params, role: 'ADMIN' })

  return response?.data
}

const updateQuizz = async (params: UpdateQuizzParams) => {
  const {quizzId} = params
  console.log({params})
  const response = await api.put(`/quizzes/${quizzId}`, {...params })

  return response?.data
}


const createAnswer = async (params: CreateAnswerParams) => {  
  const response = await api.post('/answers', { ...params, role: 'ADMIN'})

  return response?.data
}

const updateAnswer = async (params: UpdateAnswerParams) => {  
  const response = await api.put(`/answers/${params.id}`, { ...params, role: 'ADMIN'})

  return response?.data
}

const createQuestion = async (params: CreateQuestionParams) => {  
  const response = await api.post('/questions', { ...params, role: 'ADMIN'})

  return response?.data
}

const updateQuestion = async (params: UpdateQuestionParams) => {  
  const response = await api.put(`/questions/${params.id}`, { ...params, role: 'ADMIN'})

  return response?.data
}

const getQuestionById = async (id: number) => {
  const response = await api.get<Question>(`/questions/${id}`)

  return response?.data
}

const deleteQuizz = async (quizzId: number | undefined) => {
  const response = await api.delete(`/quizzes/${quizzId}`)

  return response?.data
}

const deleteQuestion = async (questionId: number | undefined) => {
  const response = await api.delete(`/questions/${questionId}`)

  return response?.data
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars

interface DeleteAnswerParams {
  answerId: number,
  questionId: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deleteAnswer = async ({answerId, questionId}: DeleteAnswerParams) => {
  const response = await api.delete(`/answers/${answerId}`)

  return response?.data
}

const uploadQuestionImage = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await api.post('/questions/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response?.data
}


const uploadQuizzImage = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await api.post('/quizzes/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response?.data
}



export const QuizzesApi = {
    getQuizzes,
    getQuizzById, 
    createQuizz, 
    updateQuizz, 
    createAnswer,
    updateAnswer,
    createQuestion, 
    updateQuestion,
    getQuestionById, 
    deleteQuizz, 
    deleteQuestion, 
    deleteAnswer,
    uploadQuestionImage, 
    uploadQuizzImage
}
