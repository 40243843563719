import { UpdatePasswordParams } from '@types'

import { api, setAuthHeader } from '../../../api/axios'

const updatePassword = async (params: UpdatePasswordParams) => {
  const { token, password } = params
  setAuthHeader(token)
  const response = await api.put('auth/recover-password/password', {
    password,
  })
  
return response?.data
}
const verifyUser = async (token: string) => {
  setAuthHeader(token)
  const response = await api.put('auth/verification')
  
return response?.data
}

export const AuthApi = { updatePassword, verifyUser }
