import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import { BackofficeUsersApi, QUERY_KEYS } from '@api'
import { useUser } from '@contexts'

const onError = () => {
  console.log('No pudimos encontrar el usuario')
}

interface Props {
  onSuccess?: () => void
}

const useLoginResponseManager = (props?: Props) => {
  const { setState } = useUser()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { mutate, isLoading } = useMutation([QUERY_KEYS.BACKOFFICE_USER], BackofficeUsersApi.getBackofficeUser, {
    onError,
    onSuccess: ({ id, createdAt, username, firstName, lastName }) => {
      setState({ id, createdAt, username, firstName, lastName })
      if (props?.onSuccess) props.onSuccess()
      if (pathname === '/login') {
        navigate('/')
      }
    },
  })

  return { isLoading, onSuccess: mutate }
}

export { useLoginResponseManager }
