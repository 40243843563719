import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { MediaApi } from '../../api'
import { MediaType } from '../../types'

const useMedia = (page: number, type?: MediaType) => {
  const { data, isLoading, refetch } = useQuery([QUERY_KEYS.MEDIA, page, type], () => MediaApi.getMedia(page, type), {
    refetchOnWindowFocus: false,
  })

  return { data, isLoading, refetch }
}

export { useMedia }
