import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { message } from "antd"

import { QUERY_KEYS } from "@api"

import { ChallengesApi } from "./api"


export { useOnDeleteSuccess } from './useOnDeleteSuccess'


export const useChallenge = (id: number) => {
  const { data: challenge, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.CHALLENGE, id],
    queryFn: () => ChallengesApi.getChallenge(id),
  })

  return {
    challenge,
    isLoading,
  }
}


export const useChallenges = () => {
    const [currentPage, setCurrentPage] = useState<number>(0)

    // const { t } = useTranslation()
    const queryClient = useQueryClient()


    const moveToPage = (page: number) => {
      setCurrentPage(page)
    }

    const { mutate: create, isLoading: isLoadingCreate } = useMutation(ChallengesApi.create, {
      onSuccess: () => {
        message.success('challenge created')
        queryClient.invalidateQueries([QUERY_KEYS.CHALLENGES,currentPage])
        
      },
      onError: ({err}) => {
        message.error(err)
      },
    })

    const {mutate: update, isLoading: isLoadingUpdate} = useMutation(ChallengesApi.update, {
      onSuccess: () => {
        message.success('challenge updated')
        queryClient.invalidateQueries([QUERY_KEYS.CHALLENGES,currentPage])
      },
      onError: ({err}) => {
        message.error(err)
      },
    })

    const {mutate: createChallengeRequirementItem, isLoading: isLoadingCreateChallengeRequirementItem} = useMutation(ChallengesApi.createChallengeRequirementItem, {
      onSuccess: (data, variabes) => {
        message.success('required item created')

        // console.log('data', data) 
        // console.log('variabes', variabes)
        // console.log(variabes.challengeId)

        queryClient.invalidateQueries([QUERY_KEYS.CHALLENGE, variabes.challengeId])
        
      },
      onError: ({err}) => {
        message.error(err)
      },
    })



    const { data: challenges, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.CHALLENGES, currentPage],
        queryFn: () => ChallengesApi.getChallenges(currentPage),
        keepPreviousData: true,
        staleTime: 5000,
      })

      return {
        challenges,
        isLoading,
        moveToPage, 
        isLoadingCreate,
        create, 
        isLoadingUpdate,
        update,
        isLoadingCreateChallengeRequirementItem,
        createChallengeRequirementItem

      }
    
}

