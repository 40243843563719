import {  useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'

import { QuizzesApi } from './api'




export const useQuizzById = (id: number) => {
  
  const { data: quizzById, isLoading: isLoadingQuizzById } = useQuery([QUERY_KEYS.QUIZZ_BY_ID, id], () => QuizzesApi.getQuizzById(id))

  return {
    quizzById,
    isLoadingQuizzById,
  }

}

export const useQuestionById = (id: number) => {
    
    const { data: questionById, isLoading: isLoadingQuestionById } = useQuery([QUERY_KEYS.QUESTION_BY_ID, id], () => QuizzesApi.getQuestionById(id))
  
    return {
      questionById,
      isLoadingQuestionById,
    }
  
  }


const useQuizzes = () => {


  const [currentPage, setCurrentPage] = useState<number>(0)

  const moveToPage = (page: number) => {
    setCurrentPage(page)
  }


  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const onError = (error: AxiosError) => {
    notification.error({
      message: error?.message,
    })
  }

  const onUserUpdateSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.QUIZZES)
    notification.success({
      message: t('quizzes.notifications.edit_success'),
    })
  }

  const onCreateSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.QUIZZES)
    notification.success({
      message: t('quizzes.notifications.create_success'),
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCreateQuestion = ({data, variabes}) => {

    queryClient.invalidateQueries(QUERY_KEYS.QUIZZ_BY_ID, variabes.quizId)
    notification.success({
      message: `${t('questions.notifications.create_success')}`,
    })
  }


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateQuestion = ({data, variabes}) => {

    // console.log('onUpdateQuestion data', {data})
    // console.log('onUpdateQuestion variabes', variabes)

    queryClient.invalidateQueries(QUERY_KEYS.QUIZZ_BY_ID, variabes.quiz.id)
    queryClient.invalidateQueries(QUERY_KEYS.QUESTION_BY_ID, variabes.id)
    notification.success({
      message: `${t('questions.notifications.update_success')}`,
    })
  }
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCreateAnswer = ({data, variabes}) => {
    
    queryClient.invalidateQueries(QUERY_KEYS.QUESTION_BY_ID, data.question.id)
    notification.success({
      message: `${t('questions.notifications.create_answer_success')}`,
    })

  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onUpdateAnswer = ({data, variabes}) => {
    
    queryClient.invalidateQueries(QUERY_KEYS.QUESTION_BY_ID, variabes.questionId)
    notification.success({
      message: `${t('questions.notifications.update_answer_success')}`,
    })

  }


  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.QUIZZES)
    notification.success({
      message: t('quizzes.notifications.delete_success'),
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteQuestionSuccess = ({data, variabes}) => {
    // console.log('data', data)
    // console.log('variabes', variabes)
    queryClient.invalidateQueries(QUERY_KEYS.QUIZZ_BY_ID, variabes.quizId)
    notification.success({
      message: t('questions.notifications.delete_success'),
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDeleteAnswerSuccess = ({data, variabes}) => {
    // console.log('onDeleteAnswerSuccess data', {data})
    // console.log('onDeleteAnswerSuccess variabes', {variabes})
    // console.log('onDeleteAnswerSuccess questionId', variabes.questionId)
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.QUESTION_BY_ID, variabes.questionId],
    })
    notification.success({
      message:`${t('questions.notifications.delete_answer_success')}`,
    })
  }


  const { data: quizzes, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.QUIZZES, currentPage],
    queryFn: () => QuizzesApi.getQuizzes(currentPage),
    keepPreviousData: true,
    staleTime: 5000,
  })
  
  const { mutate: updateQuizz, isLoading: isLoadingUpdate } = useMutation(
    [QUERY_KEYS.QUIZZ_UPDATE],
    QuizzesApi.updateQuizz,
    {

      onSuccess: onUserUpdateSuccess,
      onError,
      
    },
  )

  const { mutate: deleteQuizz, isLoading: isDeleting } = useMutation(
    [QUERY_KEYS.DELETE_QUIZZ],
    QuizzesApi.deleteQuizz,
    {
      onSuccess: onDeleteSuccess,
      onError,
    },
  )

  const { mutate: deleteQuestion, isLoading: isDeletingQuestion } = useMutation(
    [QUERY_KEYS.DELETE_QUESTION],
    QuizzesApi.deleteQuestion,
    {
      onSuccess: (data, variabes) => {
        onDeleteQuestionSuccess({data, variabes})
      },
      onError,
    },
  )

  const {mutate: deleteAnswer, isLoading: isDeletingAnswer} = useMutation(
    [QUERY_KEYS.DELETE_ANSWER],
    QuizzesApi.deleteAnswer,
    {
      onSuccess: (data, variabes) => {
        onDeleteAnswerSuccess({data, variabes})
      },
      onError,
    }
  )


  const { mutate: getQuizzById, data: quizzById, isLoading: isLoadingQuizzById } = useMutation(
    [QUERY_KEYS.QUIZZ_BY_ID],
    QuizzesApi.getQuizzById,
    {
      onError,
    },
  )

  const { mutate: createQuizz, isLoading: isCreating } = useMutation(
    [QUERY_KEYS.CREATE_QUIZZ],
    QuizzesApi.createQuizz,
    {
      onSuccess: onCreateSuccess,
      onError,
    },
  )


  const { mutate: createAnswer, isLoading: isCreatingAnswer } = useMutation(
    [QUERY_KEYS.CREATE_ANSWER],
    QuizzesApi.createAnswer,
    {
      onSuccess: (data, variabes)=>{ onCreateAnswer({data, variabes})},
      onError,

      // onMutate: (variables) => {
      //   console.log('onMutate: ', {variables})
      // }
    },
  )
  const { mutate: updateAnswer, isLoading: isUpdatingAnswer } = useMutation(
    [QUERY_KEYS.UPDATE_ANSWER],
    QuizzesApi.updateAnswer,
    {
      onSuccess: (data, variabes)=>{ onUpdateAnswer({data, variabes})},
      onError,
      
      // onMutate: (variables) => {
      //   console.log('onMutate: ', {variables})
      // }
    },
  )


  
  const { mutate: getQuestionById, data: questionById, isLoading: idLoadingQuestionById } = useMutation(
    [QUERY_KEYS.QUESTION_BY_ID,],
    QuizzesApi.getQuestionById,
    {
      onError,

      // onSuccess: (data) => {
      //   console.log('data', data)
      // },
      
      
    },
  )
  
  const { mutate: createQuestion, isLoading: isCreatingQuestion } = useMutation(
    [QUERY_KEYS.QUESTION_BY_ID],
    QuizzesApi.createQuestion,
    {
      onError,
      onSuccess: (data, variabes)=>{
        onCreateQuestion({data, variabes})
      },
    },
  )

  const { mutate: updateQuestion, isLoading: isUpdatingQuestion } = useMutation(
    [QUERY_KEYS.UPDATE_QUESTION],
    QuizzesApi.updateQuestion,
    {
      onSuccess: (data, variabes)=>{
        onUpdateQuestion({data, variabes})
      },
      onError,
    },
  )


  return {
    quizzes,
    isLoading,
    getQuizzById,
    quizzById,
    isLoadingQuizzById, 
    createQuizz, 
    isCreating, 
    updateQuizz,
    isLoadingUpdate, 
    createAnswer,
    isCreatingAnswer, 
    updateAnswer,
    isUpdatingAnswer,
    createQuestion, 
    isCreatingQuestion, 

    getQuestionById,
    questionById,
    idLoadingQuestionById, 
    deleteQuizz,
    isDeleting, 
    deleteQuestion,
    isDeletingQuestion,
    deleteAnswer,
    isDeletingAnswer,
    updateQuestion,
    isUpdatingQuestion,
    moveToPage


  }
}

export { useQuizzes }
