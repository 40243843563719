import * as React from 'react'
import { Typography, Space } from 'antd'

import { Team } from '@types'

interface TeamItemProps extends Team {
  onClick?: () => void
}
const { Text } = Typography
const TeamItem = (props: TeamItemProps) => {
  return (
    <Space
      onClick={props.onClick}
      direction={'vertical'}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#cecece',
        padding: 4,
        margin: 4,
        width: '100%',
        textAlign: 'center',
      }}
    >
      <img src={props.logo} width={50} height={50} alt={props.name} />
      <Text strong style={{ fontSize: 12, wordBreak: 'break-word' }}>
        {props.name}
      </Text>
    </Space>
  )
}

export { TeamItem }
