import { PaginatedRequest, PaginatedResponse, Team, TeamsFilters } from '@types'
import { api } from '@api'

const paginate = async (params: PaginatedRequest<TeamsFilters>) => {
  const { page, filters } = params
  const response = await api.get<PaginatedResponse<Team>>('/teams', { params: { page, ...filters } })

  return response.data
}

export const TeamsApi = { paginate }
