import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Title, TitleRow } from '@components'

import { WidgetForm, WidgetShowType, WidgetType } from '../../types'
import { useCreateWidget } from '../../hooks/useCreateWidget'
import { WidgetFormView } from '../../components/WidgetFormView'

const initialValues: WidgetForm = {
  order: 0,
  enabled: false,
  showType: WidgetShowType.ALWAYS,
  type: WidgetType.NEWS_VERTICAL,
}
const WidgetsCreate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { createWidget, isLoading } = useCreateWidget({
    onSuccess: () => {
      navigate('/widgets')
    },
  })

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
          <TitleRow>
            <Col style={{ marginRight: '1%' }}>
              <Title>{t('widgets.create.title')}</Title>
            </Col>
          </TitleRow>
        </Col>
        <Row style={{ justifyContent: 'center' }}>
          <WidgetFormView initialValues={initialValues} onSubmit={createWidget} isLoading={isLoading} />
        </Row>
      </Col>
    </Row>
  )
}

export { WidgetsCreate }
