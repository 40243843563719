import styled from 'styled-components'
import { Row } from 'antd'

const Title = styled.h1`
  font-family: Nunito-Bold;
  font-size: 35px;
`
const TitleRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export { Title, TitleRow }
