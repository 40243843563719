import { Button, Col, Row, Table, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { DeleteOutlined, UserAddOutlined, PlusOutlined } from '@ant-design/icons'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Title, TitleRow } from '@components'

import { useCollectiblesWithoutOwner } from '../../hooks'
import { Collectible } from '../../types'
import { DeleteCollectibleModal } from '../../modals'
import { ChooseClientModal } from '../../../Clients'
import { Client } from '../../../Clients/types'
import { useAssignOwner } from '../../hooks/useAssignOwner'

const CollectiblesMain = () => {
  const { t } = useTranslation()
  const [page, updatePage] = useState(0)
  const { data, isLoading } = useCollectiblesWithoutOwner(page)
  const navigate = useNavigate()
  const [collectibleToDelete, updateCollectibleToDelete] = useState<Collectible | undefined>()
  const [collectibleToAssignClient, updateCollectibleToAssignClient] = useState<Collectible | undefined>()
  const { assignOwnerRequest } = useAssignOwner()
  const assignSelectedClient = useCallback(
    (clients: Client[]) => {
      if (collectibleToAssignClient) {
        const client = clients.shift()
        if (client) {
          assignOwnerRequest({ id: collectibleToAssignClient.id, owner: client.id })
        }
      }
    },
    [collectibleToAssignClient, assignOwnerRequest],
  )
  const columns = [
    {
      title: t('collectibles.table_headers.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('collectibles.table_headers.image'),
      key: 'image',
      render: collectible => {
        return (
          <img
            src={collectible.metadata.mediaFiles.frontImage}
            height={40}
            width={40}
            alt={collectible.metadata.name}
          />
        )
      },
    },
    {
      title: t('collectibles.table_headers.name'),
      key: 'name',
      render: collectible => {
        return collectible.metadata.name
      },
    },
    {
      title: t('collectibles.table_headers.year'),
      key: 'year',
      render: collectible => {
        return collectible.metadata.year
      },
    },
    {
      title: t('collectibles.table_headers.season'),
      key: 'season',
      render: collectible => {
        return collectible.metadata.season
      },
    },
    {
      title: t('common.action'),
      key: 'action',
      render: (item: Collectible) => (
        <>
          <Row>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('collectibles.buttons.tooltips.assign')}>
                <Button
                  type='primary'
                  onClick={() => {
                    updateCollectibleToAssignClient(item)
                  }}
                  icon={<UserAddOutlined rev='' />}
                />
              </Tooltip>
            </Col>

            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('collectibles.buttons.tooltips.delete')}>
                <Button
                  type='primary'
                  danger
                  onClick={() => {
                    updateCollectibleToDelete(item)
                  }}
                  icon={<DeleteOutlined rev='' />}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
          <Col span={24}>
            <TitleRow>
              <Col style={{ marginRight: '1%' }}>
                <Title>{t('menu.collectibles')}</Title>
              </Col>
              <Col style={{ marginBottom: '1%' }}>
                <Tooltip title={t('collectibles.buttons.tooltips.new')}>
                  <Button type='primary' onClick={() => navigate('/collectibles/new')}>
                    <PlusOutlined rev='' />
                    {t('collectibles.buttons.new')}
                  </Button>
                </Tooltip>
              </Col>
            </TitleRow>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={data?.data}
              rowKey='id'
              pagination={{
                position: ['bottomCenter'],
                total: data?.total,
                current: (data?.page ?? 0) + 1,
                pageSize: data?.pageSize ?? 10,
              }}
              onChange={pagination => {
                if (pagination) {
                  updatePage(pagination.current! - 1)
                }
              }}
            />
          </Col>
        </Col>
        <DeleteCollectibleModal
          visible={!!collectibleToDelete}
          onClose={() => updateCollectibleToDelete(undefined)}
          collectible={collectibleToDelete}
        />
        <ChooseClientModal
          visible={!!collectibleToAssignClient}
          onClose={() => updateCollectibleToAssignClient(undefined)}
          onClientsSelected={clientsSelected => assignSelectedClient(clientsSelected)}
        />
      </Row>
    </>
  )
}

export { CollectiblesMain }
