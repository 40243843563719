import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'
import { PaginatedRequest } from '@types'

import { WidgetsApi } from '../../api'
import { WidgetsFilters } from '../../types'

const useWidgets = (params: PaginatedRequest<WidgetsFilters>) => {
  const { page = 0 } = params
  const { data, isLoading } = useQuery([QUERY_KEYS.WIDGETS, page], () => WidgetsApi.paginate(params), {
    refetchOnWindowFocus: false,
  })

  return { data, isLoading }
}

export { useWidgets }
