import { Button, Checkbox, Form, Input, Select } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { WidgetForm, WidgetShowType, WidgetType } from '../../types'
import { MediaPickerInput } from '../../../Media/components'
import ImageOrVideoExample from '../../../../assets/images/widgets/image-video.png'
import TextExample from '../../../../assets/images/widgets/text.png'
import HorizontalNewsExample from '../../../../assets/images/widgets/horizontal-news.png'
import VerticalNewsExample from '../../../../assets/images/widgets/vertical-news.png'
import RankingExample from '../../../../assets/images/widgets/global-ranking.png'
import { useWidgetFormValidationSchema } from './validation'
import { Media } from '../../../Media/types'

interface WidgetFormProps {
  initialValues: Partial<WidgetForm>
  onSubmit: (form: WidgetForm) => void
  isLoading: boolean
}
const WidgetFormView = (props: WidgetFormProps) => {
  const { onSubmit, initialValues, isLoading } = props

  const [form] = Form.useForm()
  const type = form.getFieldValue('type')
  const { t } = useTranslation()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const { widgetFormValidationSchemaProvider } = useWidgetFormValidationSchema()

  const validateField = useCallback(
    async (name, value) => {
      try {
        await widgetFormValidationSchemaProvider(form.getFieldValue('type')).validateAt(name, {
          [name]: value,
          ...form.getFieldsValue(),
        })
        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }))
      } catch (error: any) {
        setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }))
      }
    },
    [form, widgetFormValidationSchemaProvider],
  )

  const onFieldsChange = useCallback(() => {
    setErrors({})
    const fields = form.getFieldsValue()
    Object.keys(fields).forEach(field => {
      validateField(field, fields[field]).then()
    })
  }, [form, validateField])

  const onValuesChange = (values: Partial<WidgetForm>) => {
    const fields = form.getFieldsValue()
    const { imageUrl: fImageUrl, videoUrl: fVideoUrl, actionName, actionTarget, description, title, ...others } = fields
    const image = form.getFieldValue('image')
    const video = form.getFieldValue('video')
    const imageUrl = fImageUrl || image?.url
    const videoUrl = fVideoUrl || video?.url
    if (values.type) {
      switch (values.type) {
        case WidgetType.NEWS_HORIZONTAL:
        case WidgetType.NEWS_VERTICAL:
          form.setFieldsValue({ ...others, imageUrl, actionName, actionTarget, description, title })
          break
        case WidgetType.TEXT:
          form.setFieldsValue({ ...others, actionName, actionTarget, description, title })
          break
        case WidgetType.VIDEO:
          form.setFieldsValue({ ...others, videoUrl })
          break
        case WidgetType.IMAGE:
          form.setFieldsValue({ ...others, imageUrl, actionTarget })
          break
      }
      onFieldsChange()
    }
  }
  const formValid = useMemo(() => {
    return !Object.values(errors).some(error => !!error)
  }, [errors])

  useEffect(() => {
    onFieldsChange()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  useEffect(() => {
    // @ts-ignore
    const { imageUrl, videoUrl } = initialValues
    if (imageUrl) {
      initialValues.image = { url: imageUrl } as Media
    }
    if (videoUrl) {
      initialValues.video = { url: videoUrl } as Media
    }
    form.setFieldsValue(initialValues)
    onFieldsChange()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const showTitle = useMemo(() => {
    return [WidgetType.TEXT, WidgetType.NEWS_VERTICAL, WidgetType.NEWS_HORIZONTAL, WidgetType.GLOBAL_RANKING].includes(
      type,
    )
  }, [type])

  const showDescription = useMemo(() => {
    return [WidgetType.TEXT, WidgetType.NEWS_VERTICAL, WidgetType.NEWS_HORIZONTAL].includes(type)
  }, [type])

  const showImage = useMemo(() => {
    return [WidgetType.IMAGE, WidgetType.NEWS_VERTICAL, WidgetType.NEWS_HORIZONTAL, WidgetType.VIDEO].includes(type)
  }, [type])

  const showVideo = useMemo(() => {
    return [WidgetType.VIDEO].includes(type)
  }, [type])

  const showActionTarget = useMemo(() => {
    return [
      WidgetType.IMAGE,
      WidgetType.NEWS_HORIZONTAL,
      WidgetType.NEWS_VERTICAL,
      WidgetType.TEXT,
      WidgetType.GLOBAL_RANKING,
    ].includes(type)
  }, [type])

  const showActionName = useMemo(() => {
    return [WidgetType.NEWS_HORIZONTAL, WidgetType.NEWS_VERTICAL, WidgetType.TEXT, WidgetType.GLOBAL_RANKING].includes(
      type,
    )
  }, [type])

  const example = useMemo(() => {
    switch (type) {
      case WidgetType.IMAGE:
      case WidgetType.VIDEO:
        return ImageOrVideoExample
      case WidgetType.TEXT:
        return TextExample
      case WidgetType.NEWS_HORIZONTAL:
        return HorizontalNewsExample
      case WidgetType.NEWS_VERTICAL:
        return VerticalNewsExample
      case WidgetType.GLOBAL_RANKING:
        return RankingExample
    }
  }, [type])

  const onFinish = useCallback(
    (values: WidgetForm) => {
      const { image: _i, video: _v, ...form } = values
      onSubmit(form)
    },
    [onSubmit],
  )

  return (
    <Form
      labelCol={{ span: 14 }}
      wrapperCol={{ span: 14 }}
      layout='vertical'
      style={{ maxWidth: 600 }}
      form={form}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        label={t('widgets.form.input.type')}
        name={'type'}
        validateStatus={errors.type ? 'error' : ''}
        help={errors.type}
      >
        <Select
          onChange={value => {
            if (value === WidgetType.GLOBAL_RANKING) {
              form.setFieldValue('showType', WidgetShowType.ONLY_ONLINE)
              onFieldsChange()
            }
          }}
        >
          {Object.values(WidgetType).map(type => (
            <Select.Option key={`type-${type}`} value={type}>
              {t(`widgets.type.${type.toLowerCase()}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <img src={example} style={{ borderRadius: 8, margin: 16, maxWidth: 500 }} alt='example' />

      <Form.Item
        label={t('widgets.form.input.show-type')}
        name={'showType'}
        validateStatus={errors.showType ? 'error' : ''}
        help={errors.showType}
      >
        <Select disabled={type === WidgetType.GLOBAL_RANKING}>
          {Object.values(WidgetShowType).map(showType => (
            <Select.Option key={`show-type-${showType}`} value={showType}>
              {t(`widgets.show-type.${showType.toLowerCase()}`)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name={'order'}
        label={t('widgets.form.input.order')}
        validateStatus={errors.order ? 'error' : ''}
        help={errors.order}
      >
        <Input type={'number'} min={0} />
      </Form.Item>
      <Form.Item label={t('widgets.form.input.enabled')} name='enabled' valuePropName='checked'>
        <Checkbox></Checkbox>
      </Form.Item>

      {showImage && (
        <MediaPickerInput
          type={'image'}
          name={'imageUrl'}
          validateStatus={errors.imageUrl ? 'error' : ''}
          help={errors.imageUrl}
          media={form.getFieldValue('image')}
          label={t('widgets.form.input.image')}
          onChange={m => {
            form.setFieldValue('image', m)
            form.setFieldValue('imageUrl', m?.url)
            onFieldsChange()
          }}
        />
      )}

      {showVideo && (
        <MediaPickerInput
          type={'video'}
          name={'videoUrl'}
          validateStatus={errors.videoUrl ? 'error' : ''}
          help={errors.video}
          media={form.getFieldValue('video')}
          label={t('widgets.form.input.video')}
          onChange={m => {
            form.setFieldValue('video', m)
            form.setFieldValue('videoUrl', m?.url)
            onFieldsChange()
          }}
        />
      )}

      {showTitle && (
        <Form.Item
          label={t('widgets.form.input.title')}
          validateStatus={errors.title ? 'error' : ''}
          help={errors.title}
          name={'title'}
        >
          <Input />
        </Form.Item>
      )}
      {showDescription && (
        <Form.Item
          label={t('widgets.form.input.description')}
          name={'description'}
          validateStatus={errors.description ? 'error' : ''}
          help={errors.description}
        >
          <TextArea rows={4} name={'description'} />
        </Form.Item>
      )}

      {showActionName && (
        <Form.Item
          label={t('widgets.form.input.action-name')}
          validateStatus={errors.actionName ? 'error' : ''}
          help={errors.actionName}
          name={'actionName'}
        >
          <Input />
        </Form.Item>
      )}

      {showActionTarget && (
        <Form.Item
          label={t('widgets.form.input.action-target')}
          validateStatus={errors.actionTarget ? 'error' : ''}
          help={errors.actionTarget}
          name={'actionTarget'}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item>
        <Button type='primary' htmlType='submit' loading={isLoading} disabled={!formValid || isLoading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export { WidgetFormView }
