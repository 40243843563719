import { useTranslation } from 'react-i18next'

import { BaseDeleteModal } from '@components'

import { Collectible } from '../../types'
import { CollectiblesApi } from '../../api'
import { useOnDeleteSuccess } from '../../hooks'

interface DeleteCollectibleModalProps {
  visible: boolean
  onClose: () => void
  collectible?: Collectible
}

const DeleteCollectibleModal = ({ visible, onClose, collectible }: DeleteCollectibleModalProps) => {
  const { t } = useTranslation()
  const { onDeleteSuccess } = useOnDeleteSuccess()

  return collectible?.id ? (
    <BaseDeleteModal<Collectible>
      visible={visible}
      onClose={onClose}
      itemId={collectible.id}
      title={t('collectibles.modals.delete.title')}
      confirmDeleteText={`${t('collectibles.modals.delete.delete_confirmation')} ${collectible.id} ${t(
        'collectibles.modals.delete.delete_confirmation_end',
      )}`}
      request={id => CollectiblesApi.deleteCollectible(id)}
      onSuccess={onDeleteSuccess}
    />
  ) : null
}

export { DeleteCollectibleModal }
