import { useTranslation } from 'react-i18next'
import { Button, Modal, Spin } from 'antd'

import { useQuizzes } from '../../hooks'
import { StyledDeleteMessage, StyledRow } from '..'
import { Quizz } from '../../types'

interface DeleteQuizzModalProps {
  visible: boolean
  onClose: () => void
  quizzId?: number
  quizz?: Quizz
}

const DeleteQuizzModal = ({ visible, onClose, quizzId, quizz }: DeleteQuizzModalProps) => {
  const { t } = useTranslation()

  const { deleteQuizz, isDeleting } = useQuizzes()

  return (
    <Modal
      open={visible}
      title={t('quizzes.placeholders.delete_quizz')}
      onCancel={onClose}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {t('common.edit.button.cancel')}
        </Button>,
        <Button
          danger
          type='primary'
          key='delete'
          onClick={() => {
            deleteQuizz(quizzId)
            if (!isDeleting) {
              onClose()
            }
          }}
        >
          {t('users.buttons.delete')}
        </Button>,
      ]}
    >
      <Spin spinning={isDeleting}>
        <StyledRow>
          <StyledDeleteMessage>{`${t('quizzes.placeholders.delete_confirmation')}${quizz?.name}${t(
            'users.placeholders.delete_confirmation_end',
          )}`}</StyledDeleteMessage>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { DeleteQuizzModal }
