import styled from 'styled-components'
import { Card } from 'antd'

export const WidgetContent = styled(Card)({
  borderRadius: 24,
  border: `2px solid #cecece`,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  backgroundColor: '#000000',
  padding: 8,
  justifyContent: 'space-between',
  flexBasis: '100%',
  color: 'white',
})
