import { useMutation } from 'react-query'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '@api'

import { AuthApi } from './api'

const useAuth = () => {
  const { t } = useTranslation()
  const onError = (error: AxiosError) => {
    if (error?.message.toLowerCase().includes('already verified')) {
      notification.error({
        message: t('public_web.validate_email.notifications.already_verified'),
      })
    } else {
      notification.error({
        message: error?.message,
      })
    }
  }
  const { mutate: updatePassword, isLoading } = useMutation(
    [QUERY_KEYS.UPDATE_USER_PASSWORD],
    AuthApi.updatePassword,
  )
  const { mutate: verifyUser, isLoading: isVerifying } = useMutation(
    [QUERY_KEYS.VERIFY_USER],
    AuthApi.verifyUser,
    {
      onError,
    },
  )

  return { updatePassword, verifyUser, isLoading, isVerifying }
}

export { useAuth }
