import { Button, Checkbox, Col, DatePicker, Form, Input, message, Row, Image } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextArea from 'antd/lib/input/TextArea'
import { useNavigate, useParams} from 'react-router-dom'
import dayjs from 'dayjs';

import { Title, TitleRow } from '@components'

import { ImagePicker } from '../../components/ImagePicker'
import { Challenge } from '../../types'
import { useChallenge, useChallenges } from '../../hooks'


export const ChallengesUpdate = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const {challenge,} = useChallenge(Number(id))

    console.log('id', id)
    

    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [errors, _] = useState<{ [key: string]: string }>({})
    const [urlImage, setImageUrl] = useState('')
    

    const { update } = useChallenges()


    useEffect(() => {
        if(challenge){
            form.setFieldsValue({
                name: challenge.name,
                description: challenge.description,
                startDate: dayjs(challenge.startDate),
                endDate: dayjs(challenge.endDate),
                enabled: challenge.enabled, 
                urlImage: challenge.imageUrl
            })

            setImageUrl(challenge.imageUrl)
            console.log('Challenge', challenge)
        }
    }, [challenge, form])



    const onFinish = useCallback(
        (values: Challenge) => {

            if(!urlImage){
                message.error('Image is required')

                return
            }

            console.log('Received values of form: ', values)

            const { name, description, startDate, endDate, enabled } = values
            
            update({
                    id: Number(id),
                    name,
                    description: description??'',
                    startDate,
                    endDate,
                    enabled: enabled??false, 
                    imageUrl: urlImage, 
                    type: 'TEMPORARY', 
                    
                })

            navigate(`/challenges`)
        },
        [urlImage, update, navigate, id ],
    )
   

    return (
        <Row>
            <Col span={24} style={{ marginBottom: '2%' }}>

            <Col span={24}>
                <TitleRow>
                <Col style={{ marginRight: '1%' }}>
                    <Title>{t('challenges.update.title')}</Title>
                </Col>
                </TitleRow>
            </Col>
            <Row>
                <Col span={8}>
                    <ImagePicker
                            label={t('challenges.update.form.input.image')}
                            name={'frontImage'}
                            url={urlImage}
                            onChange={(_, url) =>setImageUrl(url)}
                    />

                    <Image
                        width={200}
                        
                    />
                </Col>
                <Col span={16}>
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 14 }}
                        layout='horizontal'
                        style={{ maxWidth: 600 }}
                        form={form}
                        onFinish={onFinish}
                        
                        //   onFieldsChange={onFieldsChange}
                        //   initialValues={initialValues}
                    >
                    <Form.Item
                        label={t('challenges.update.form.input.name')}
                        validateStatus={errors.name ? 'error' : ''}
                        help={errors.name}
                        name={'name'}
                        rules={[{ required: true, message: t('challenges.update.form.input.name') }]}
                        >
                        <Input />
                    </Form.Item>


                    <Form.Item 
                        label={t('challenges.update.form.input.description')} 
                        name={'description'}
                        rules={[{required: true, message: t('challenges.update.form.input.description') }]}>
                        <TextArea rows={4} name={'description'} />
                    </Form.Item>

                    <Form.Item label={t('challenges.update.form.input.startDate')} name={'startDate'}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={true}
                            showSecond={false}
                            minuteStep={5}
                            style={{ width: '100%' }} 
                            autoComplete="false"
                            placeholder={t('challenges.update.form.input.startDate')}
                            
                            />
                    </Form.Item>

                    <Form.Item label={t('challenges.update.form.input.endDate')} name={'endDate'}>
                        <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={true}
                            showSecond={false}
                            minuteStep={5}
                            style={{ width: '100%' }} 
                            autoComplete="false"
                            placeholder={t('challenges.update.form.input.endDate')}
                        />
                    </Form.Item>

                    <Form.Item label={t('challenges.update.form.input.enabled')} name={'enabled'} valuePropName='checked'>
                        <Checkbox></Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button 

                            //disabled={!formValid || !formImagesValid || isLoading} 
                            type='primary' 
                            htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item> 
                </Form>
                </Col>
            </Row>


            </Col>
        </Row>
    )
}

