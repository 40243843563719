import { UsersResponse } from '@types'

import { api } from '../../../api/axios'

interface UpdateParams {
  userId: number | undefined
  firstName: string | undefined
  lastName: string | undefined
}
interface UpdatePasswordParams {
  userId?: number
  password: string | undefined
}
interface CreateUserParams {
  username: string | undefined
  password: string | undefined
  firstName: string | undefined
  lastName: string | undefined
}

const getUsers = async () => {
  //setAuthHeader('aaaaa')
  const response = await api.get<UsersResponse>('/users')

  return response?.data
}
const getUserById = async (userId?: number) => {
  const response = await api.get(`/users/${userId}`)

  return response?.data
}

const updateUser = async (params: UpdateParams) => {
  const { userId, firstName, lastName } = params
  const response = await api.put(`/users/${userId}`, { firstName, lastName })

  return response?.data
}
const createUser = async (params: CreateUserParams) => {
  const response = await api.post('/users', { ...params, role: 'ADMIN' })

  return response?.data
}
const updateUserPassword = async (params: UpdatePasswordParams) => {
  const { password, userId } = params
  const response = await api.patch(`/users/${userId}`, { password })

  return response?.data
}
const deleteUser = async (userId: number | undefined) => {
  const response = await api.delete(`/users/${userId}`)

  return response?.data
}

export const UsersApi = {
  getUsers,
  updateUser,
  getUserById,
  updateUserPassword,
  createUser,
  deleteUser,
}
