import { useEffect, useState } from 'react'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, message, Upload, UploadFile, UploadProps } from 'antd'
import { RcFile, UploadChangeParam } from 'antd/es/upload'

import { useChallengeImageUploader } from '../../../../hooks/useChallengeUploader'

interface ImagePickerProps {
    label: string
    name: string
    url?: string
    onChange: (name: string, url: string) => void
}


export const ImagePicker = (props: ImagePickerProps) => {

  const { label, name, url = '', onChange } = props

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>(url)

  const { upload } = useChallengeImageUploader(
    url => {
      console.log('url', url)
      setLoading(false)
      setImageUrl(url)
      onChange(name, url)
    },
    () => {
      setLoading(false)
    },
  )

  //Check if the file is a valid format and size
  const beforeUpload = (file: RcFile) => {

    const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
    if (!isValidFormat) {
      message.error('You can only upload JPG/PNG/WEBP file!')
    }
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      message.error('Image must be smaller than 1MB!')
    }
  
    return isValidFormat && isLt1M
  }

  const onUpload = options => {
    const { file } = options
   
    upload(file)

    console.log(file)


  }

  useEffect(() => {
    setImageUrl(url)
  }
  , [url])
    

    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined rev={''} /> : <PlusOutlined rev={''} />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )


    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
          setLoading(true)
    
          return
        }
      }

    return (
        <Form.Item label={label} labelCol={{ span: 12 }}>
            <Upload
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                customRequest={onUpload}
            >
                {imageUrl ? <img src={url} alt={name} style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        </Form.Item>
    )
}

