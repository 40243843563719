import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

import { Title, TitleRow } from '@components'

import { useUpdateWidget } from '../../hooks/useUpdateWidget'
import { WidgetFormView } from '../../components/WidgetFormView'
import { useWidget } from '../../hooks/useWidget'
import { WidgetForm } from '../../types'

const WidgetsUpdate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const { widget, isLoading: isFinding } = useWidget(id!)
  const { updateWidget, isLoading } = useUpdateWidget({
    onSuccess: () => {
      navigate('/widgets')
    },
  })

  const onSubmit = useCallback(
    (form: WidgetForm) => {
      return updateWidget({ id: Number(id!), ...form })
    },
    [id, updateWidget],
  )

  return (
    <Row>
      <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
          <TitleRow>
            <Col style={{ marginRight: '1%' }}>
              <Title>{t('widgets.create.title')}</Title>
            </Col>
          </TitleRow>
        </Col>
        <Row style={{ justifyContent: 'center' }}>
          <WidgetFormView initialValues={widget ?? {}} onSubmit={onSubmit} isLoading={isLoading || isFinding} />
        </Row>
      </Col>
    </Row>
  )
}

export { WidgetsUpdate }
