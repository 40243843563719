import { useTranslation } from 'react-i18next'
import { Button, Modal, Row, Spin } from 'antd'
import styled from 'styled-components'

import { useBaseDelete } from './hooks/useBaseDelete'

interface BaseDeleteModalProps<T> {
  visible: boolean
  onClose: () => void
  itemId: number
  item?: T
  title: string
  confirmDeleteText: string
  cancelButtonText?: string
  deleteButtonText?: string
  request: (id: string | number) => Promise<void>
  onSuccess?: () => void
  onError?: (error: any) => void
}

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 0 20px 0;
`
const StyledDeleteMessage = styled.div`
  width: 100%;
  text-align: center;
`
const BaseDeleteModal = <T,>({
  request,
  onSuccess,
  onError,
  visible,
  onClose,
  itemId,
  title,
  cancelButtonText,
  deleteButtonText,
  confirmDeleteText,
}: BaseDeleteModalProps<T>) => {
  const { t } = useTranslation()

  const { deleteRequest, isDeleting } = useBaseDelete({ request, onSuccess, onError })

  return (
    <Modal
      open={visible}
      title={title}
      onCancel={onClose}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      maskClosable={false}
      closable={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {cancelButtonText ?? t('common.modals.button.cancel')}
        </Button>,
        <Button
          danger
          type='primary'
          key='delete'
          onClick={() => {
            deleteRequest(itemId)
            if (!isDeleting) {
              onClose()
            }
          }}
        >
          {deleteButtonText ?? t('common.modals.button.delete')}
        </Button>,
      ]}
    >
      <Spin spinning={isDeleting}>
        <StyledRow>
          <StyledDeleteMessage>{confirmDeleteText}</StyledDeleteMessage>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { BaseDeleteModal }
