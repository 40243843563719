import { Button } from 'antd'

import { WidgetContent } from '../styled'
import { NewsVerticalWidgetType } from '../../types'

const NewsVerticalWidget = (props: NewsVerticalWidgetType) => {
  const { title, description, actionName, imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => open(actionTarget, '_blank') : undefined

  return (
    <WidgetContent onClick={onClick}>
      <div style={{ width: '100%', position: 'relative' }}>
        <img src={imageUrl} style={{ border: `1px solid #cecece`, borderRadius: 12, width: '100%' }} alt={'t-shirts'} />
        <div style={{ position: 'absolute', padding: 8, bottom: 0 }}>
          <h1 style={{ paddingBottom: 3, color: 'white', textTransform: 'uppercase' }}>
            <b>{title}</b>
          </h1>
        </div>
      </div>
      {!!description && <div>{description}</div>}
      {!!actionName && !!onClick && (
        <Button style={{ marginTop: 8, fontWeight: 700, fontSize: 18, width: '100%' }} onClick={onClick}>
          {actionName}
        </Button>
      )}
    </WidgetContent>
  )
}
export { NewsVerticalWidget }
