import { useEffect, useState } from 'react'
import { TabMenu } from 'primereact/tabmenu'
import { Button } from 'primereact/button'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { logo } from '@images'

const HeaderImgMenu = styled.img`
  width: 50px;
`

const StyledBtnMenu = styled(Button)`
  color: #27356c !important;
  :focus {
    box-shadow: none;
  }
`

interface AppMenuProps {
  isOpen: boolean
  changeIsOpen: () => void
}
const AppMenu = ({ changeIsOpen, isOpen }: AppMenuProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [activeIndex, setActiveIndex] = useState(0)
  const { t } = useTranslation()

  const items = [
    { id: '0', label: isOpen ? t('menu.users') : '', icon: 'pi pi-user', route: '/users' },
    { id: '1', label: isOpen ? t('menu.quizzes') : '', icon: 'pi pi-align-center', route: '/quizzes' },
    { id: '2', label: isOpen ? t('menu.challenges') : '', icon: 'pi pi-star', route: '/challenges' },
    { id: '3', label: isOpen ? t('menu.predictions') : '', icon: 'pi pi-check', route: '/predictions' },
    { id: '4', label: isOpen ? t('menu.reviewItems') : '', icon: 'pi pi-eye', route: '/review-items' },
    { id: '5', label: isOpen ? t('menu.collectibles') : '', icon: 'pi pi-briefcase', route: '/collectibles' },
    { id: '6', label: isOpen ? t('menu.media') : '', icon: 'pi pi-images', route: '/media' },
    { id: '7', label: isOpen ? t('menu.widgets') : '', icon: 'pi pi-th-large', route: '/widgets' },

    // { id: '1', label: isOpen ? t('menu.contracts') : '', icon: 'pi pi-book', route: '/contracts' },
    // { id: '2', label: isOpen ? t('menu.collections') : '', icon: 'pi pi-briefcase', route: '/collections' },
  ]

  const menuClick = e => {
    setActiveIndex(Number(e.value.id))
    navigate(e.value.route)
  }

  useEffect(() => {
    const activeItem = items.filter(item => item.route === pathname)
    if (activeItem !== undefined) {
      setActiveIndex(Number(activeItem[0]?.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <nav className='tab-menu'>
      <header className='container-header-menu'>
        <HeaderImgMenu src={logo} style={isOpen ? { display: 'block' } : { display: 'none' }} />
        <StyledBtnMenu
          icon={isOpen ? 'pi pi-align-right' : 'pi pi-bars'}
          className='p-button-rounded p-button-text'
          onClick={() => changeIsOpen()}
        />
      </header>
      <TabMenu model={items} activeIndex={activeIndex} onTabChange={e => menuClick(e)} />
    </nav>
  )
}

export { AppMenu }
