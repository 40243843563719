import { useMutation } from 'react-query'

import { MediaApi } from '../../api'
import { Media } from '../../types'

const useCreateMedia = (onSuccess: (media: Media) => void) => {
  const { mutate, isLoading } = useMutation(MediaApi.createMedia, { onSuccess })

  return { createMedia: mutate, isLoading }
}

export { useCreateMedia }
