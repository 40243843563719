import { useTranslation } from 'react-i18next'

import { BaseDeleteModal } from '@components'

import { Challenge } from '../../types'
import { useOnDeleteSuccess } from '../../hooks'
import { ChallengesApi } from '../../hooks/api'

interface DeleteCollectibleModalProps {
  visible: boolean
  onClose: () => void
  challenge?: Challenge
}

const DeleteChallengeModal = ({ visible, onClose, challenge }: DeleteCollectibleModalProps) => {
  const { t } = useTranslation()
  const { onDeleteSuccess } = useOnDeleteSuccess()

  const { deleteChallenge } = ChallengesApi

  return challenge?.id ? (
    <BaseDeleteModal<Challenge>
      visible={visible}
      onClose={onClose}
      itemId={challenge.id}
      title={t('challenges.modals.delete.title')}
      confirmDeleteText={`${t('challenges.modals.delete.delete_confirmation')} ${challenge.id} ${t(
        'challenges.modals.delete.delete_confirmation_end',
      )}`}
      request={id => deleteChallenge(id as number)}
      onSuccess={onDeleteSuccess}
    />
  ) : null
}

export { DeleteChallengeModal }
