import styled from 'styled-components'
import { Button, Modal, Row, Spin, Upload, UploadProps } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'

import { useCreateMedia } from '../../hooks'
import { Media } from '../../types'

interface CreateMediaModalProps {
  visible: boolean
  onClose: (media?: Media) => void
}
interface FormState {
  file?: File
}

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 0 20px 0;
`
const CreateMediaModal = ({ visible, onClose }: CreateMediaModalProps) => {
  const { t } = useTranslation()
  const { createMedia, isLoading } = useCreateMedia((media: Media) => {
    onClose(media)
  })
  const [fileList, setFileList] = useState<any>([])

  const [form, updateForm] = useState<FormState>({})

  useEffect(() => {
    updateForm({})

    setFileList([])
  }, [visible])

  const uploadProps: UploadProps = {
    name: 'file',
    listType: 'picture-card',
    defaultFileList: [],
    fileList: fileList,
    beforeUpload: () => false,
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.fileList.length > 0) {
        setFileList([info.fileList[0]])
        updateForm({ ...form, file: info.fileList[0].originFileObj as File })
      } else {
        setFileList([])
        updateForm({ ...form, file: undefined })
      }
    },
  }

  const handleSubmit = () => {
    if (form.file) {
      createMedia(form.file)
    }
  }

  return (
    <Modal
      open={visible}
      title={t('media.placeholders.new')}
      onCancel={() => onClose()}
      maskClosable={false}
      closable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      footer={[
        <Button key='cancel' onClick={() => onClose()}>
          {t('media.buttons.cancel')}
        </Button>,
        <Button type='primary' key='update' onClick={handleSubmit} disabled={isLoading}>
          {t('media.buttons.save')}
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <StyledRow>
          <h3>{t('media.placeholders.file')}:</h3>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined rev={''} />}></Button>
          </Upload>
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { CreateMediaModal }
