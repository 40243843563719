
import { ChangeEvent, useEffect, useState } from 'react'
import { Input, List, Switch } from 'antd'
import { useTranslation } from 'react-i18next'

import { Answer } from '../types'



interface AnswerEditableProps {
    answer: Answer, 
    allowEdit: boolean,
    handleDeleteAnswer: (answer: Answer) => void, 
    updateAnswer: (answer: Answer) => void
}


export const AnswerEditable = ({answer, allowEdit, handleDeleteAnswer, updateAnswer}:  AnswerEditableProps) => {

    const { t } = useTranslation()
    const [editing, setEditing] = useState<boolean>(false)
    const [answerText, setAnswerText] = useState<string>(answer.text)
    const [answerIsCorrect, setAnswerIsCorrect] = useState<boolean>(answer.isCorrect)



    const handleAnswerTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAnswerText(e.target.value)
        setEditing(true)
    }

    const handleAnswerIsCorrectChange = (checked: boolean) => {
        setAnswerIsCorrect(checked)
        setEditing(true)
    }

    const handleEditAnswer = (answer: Answer) => {

        const newAnswer = {
            ...answer,
            text: answerText,
            isCorrect: answerIsCorrect
        }

        updateAnswer(newAnswer)
        setEditing(false)
    }


    useEffect(() => {
        setAnswerText(answer.text)
        setAnswerIsCorrect(answer.isCorrect)
    }, [answer])


    return (
    <List.Item
        key={answer.id}
        actions={[
            allowEdit && <a key="answer-delete" onClick={() => {handleDeleteAnswer(answer)}}>
                {t('questions.buttons.delete')} {allowEdit}
            </a>,]}
        >
        {/* {answer.isCorrect 
            ? <CheckOutlined style={{  color: '#52c41a', paddingRight: 10 }} rev="" />
            : <CloseCircleOutlined style={{ color: '#f5222d', paddingRight: 10  }} rev="" />
        } */}
        <Switch checked={answerIsCorrect} disabled={!allowEdit} onChange={handleAnswerIsCorrectChange} style={styles.answerSwitch} />
        <Input type='text' value={answerText} disabled={!allowEdit} onChange={handleAnswerTextChange} style={styles.answerText} />
        {
            editing && allowEdit && <a type="primary" onClick={() => {handleEditAnswer({...answer, text: answerText, isCorrect: answerIsCorrect})}}>
                {t('questions.buttons.save')}
            </a>
        }
    </List.Item>
    )
}

const styles = {

    answerText: {
        flex: 1,
        padding: 5,
        border: 'none',
        background: 'rgba(0, 0, 0, 0)',
        marginLeft: 10,
        marginRight: 10,
    },
    answerSwitch: {
        padding: 5,
    },

}


