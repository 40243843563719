import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

import { QUERY_KEYS } from '../../../api/queryKeys'
import { UsersApi } from './api'

const useUsers = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const onUserUpdateSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.USERS)
    notification.success({
      message: t('users.notifications.edit_success'),
    })
  }
  const onUpdatePasswordSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.USERS)
    notification.success({
      message: t('users.notifications.password_success'),
    })
  }
  const onDeleteSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.USERS)
    notification.success({
      message: t('users.notifications.delete_success'),
    })
  }
  const onCreateSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.USERS)
    notification.success({
      message: t('users.notifications.create_success'),
    })
  }
  const onError = (error: AxiosError) => {
    notification.error({
      message: error?.message,
    })
  }
  const onPasswordError = (error: AxiosError) => {
    if (error.message.includes('Error validation')) {
      notification.error({
        message: t('users.notifications.password_error'),
      })
    } else {
      notification.error({
        message: error?.message,
      })
    }
  }
  const { data, isLoading } = useQuery([QUERY_KEYS.USERS], () => UsersApi.getUsers())
  const { mutate: getUserById, data: userById } = useMutation(
    [QUERY_KEYS.USER_BY_ID],
    UsersApi.getUserById,
    {
      onError,
    },
  )
  const { mutate: updateUser, isLoading: isLoadingUpdate } = useMutation(
    [QUERY_KEYS.USER_UPDATE],
    UsersApi.updateUser,
    {
      onSuccess: onUserUpdateSuccess,
      onError,
    },
  )
  const { mutate: updateUserPassword, isLoading: isLoadingUpdatePassword } = useMutation(
    [QUERY_KEYS.PASSWORD_UPDATE],
    UsersApi.updateUserPassword,
    {
      onSuccess: onUpdatePasswordSuccess,
      onError: onPasswordError,
    },
  )
  const { mutate: deleteUser, isLoading: isDeleting } = useMutation(
    [QUERY_KEYS.USER_DELETE],
    UsersApi.deleteUser,
    {
      onSuccess: onDeleteSuccess,
      onError,
    },
  )
  const { mutate: createUser, isLoading: isCreating } = useMutation(
    [QUERY_KEYS.CREATE_USER],
    UsersApi.createUser,
    {
      onSuccess: onCreateSuccess,
      onError,
    },
  )

  return {
    users: data,
    isLoading,
    updateUser,
    isLoadingUpdate,
    userById,
    getUserById,
    updateUserPassword,
    isLoadingUpdatePassword,
    createUser,
    isCreating,
    deleteUser,
    isDeleting,
  }
}

export { useUsers }
