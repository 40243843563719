import { BackofficeUser } from '@types'

import { api } from './axios'

const getBackofficeUser = async (id?: number) => {
  const response = await api.get<BackofficeUser>(`/users/${id}`)
  
  return response.data
}

export const BackofficeUsersApi = { getBackofficeUser }
