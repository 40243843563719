import { useMutation } from 'react-query'

import { WidgetsApi } from '../../api'
import { Widget } from '../../types'

export const useUpdateWidget = (props: { onSuccess?: (widget: Widget) => void; onError?: () => void }) => {
  const { onSuccess, onError } = props

  const { mutate: updateWidget, isLoading } = useMutation(WidgetsApi.update, {
    onSuccess,
    onError,
  })

  return { updateWidget, isLoading }
}
