import { Steps } from 'antd'
import { useState } from 'react'

import { StyledContainer, StyledStepsContainer } from './components'
import logo from '../../../assets/images/logo-splash.png'
import { PasswordStep } from './components/PasswordStep'
import { SuccessStep } from './components/SuccessStep'
import { FailureStep } from './components/FailureStep'

const { Step } = Steps

export const ResetPassword = () => {
  const [current, setCurrent] = useState(0)
  const [updateState, setUpdateState] = useState()
  const next = () => {
    setCurrent(current + 1)
  }

  const steps = [
    {
      id: 1,
      content: <PasswordStep current={current} next={next} setUpdateState={setUpdateState} />,
    },
    {
      id: 2,
      content: updateState ? <SuccessStep /> : <FailureStep />,
    },
  ]

  return (
    <StyledContainer
      style={{
        backgroundColor: `#000000`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      <img src={logo} alt='logo' width={300} />
      <StyledStepsContainer>
        <Steps current={current} progressDot direction='horizontal' responsive={false}>
          {steps.map(item => (
            <Step key={item.id} />
          ))}
        </Steps>
      </StyledStepsContainer>
      <div className='steps-content'>{steps[current].content}</div>
    </StyledContainer>
  )
}
