import { useState } from "react";
import { DeleteOutlined, EditOutlined, OrderedListOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { useQuizzes } from "./hooks";
import { Quizz } from "./types";
import { StyledAlignedTitleRow, StyledTitle } from "./components";
import { EditQuizzModal } from "./components/modals/EditQuizzModal";
import { CreateQuizzModal } from "./components/modals/CreateQuizzModal";
import { QuestionsQuizzModal } from "./components/modals/QuestionsQuizzModal";
import { DeleteQuizzModal } from "./components/modals/DeleteQuizzModal";


const Quizzes = () => {
  const { t } = useTranslation()

  const [editQuizzModalOpen, setEditQuizzModalOpen] = useState<boolean>(false)
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [questionsQuizzModalOpen, setQuestionsQuizzModalOpen] = useState<boolean>(false)
  const {isLoading, quizzes, getQuizzById, moveToPage, quizzById, isLoadingQuizzById} = useQuizzes();

  const [deleteQuizzModal, setDeleteQuizzModal] = useState<boolean>(false)
  const [deleteQuizzId, setDeleteQuizzId] = useState<number>()
  const [deleteQuizz, setDeleteQuizz] = useState()
    
  // useEffect(() => {
  //   console.log('quizzes', quizzes)
  // }
  // , [quizzes
  // ])

  const onDeleteClick = quizz => {
    setDeleteQuizzId(quizz.id)
    setDeleteQuizz(quizz)
    setDeleteQuizzModal(true)
  }
  const onCloseDeleteClick = () => {
    setDeleteQuizzModal(false)
  }


  const onCreateClick = () => {
    setCreateModalOpen(true)
  }

  const onEditQuizz = (id: number) => {
      getQuizzById(id)
      setEditQuizzModalOpen(true)
  }

  const onEditQuestionsQuizz = (id: number) => {
    getQuizzById(id)
    setQuestionsQuizzModalOpen(true)
  }

  const onCloseEditModal = () => {
      setEditQuizzModalOpen(false)
  }

  const onCloseQuestionsQuizzModal = () => {
    setQuestionsQuizzModalOpen(false)
  }

  const onCloseCreateModal = () => {
    setCreateModalOpen(false)
  }


  const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },

      {
        title: t('common.action'),
        key: 'action',
        render: (item: Quizz) => (
          <>
              <Row>
                  <Col style={{ marginRight: '5px' }}>
                  <Tooltip title={t('quizzes.buttons.tooltips.edit')}>
                      <Button
                      type='primary'
                      onClick={() => {
                        onEditQuizz(item.id)
                      }}
                      icon={<EditOutlined rev='' />}
                      />
                  </Tooltip>
                  </Col>

                  <Col style={{ marginRight: '5px' }}>
                  <Tooltip title={t('quizzes.buttons.tooltips.questions')}>
                      <Button
                        type='primary'
                        onClick={() => {
                          onEditQuestionsQuizz(item.id)
                        }}
                        icon={<OrderedListOutlined rev='' />}
                      />
                  </Tooltip>
                  </Col>

                  <Col style={{ marginRight: '5px' }}>
                  <Tooltip title={t('quizzes.buttons.tooltips.delete')}>
                      <Button
                      disabled={new Date(item.startDate) <= new Date()}
                      type='primary'
                      danger
                      onClick={() => {
                          onDeleteClick(item)
                      }}
                      icon={<DeleteOutlined rev='' />}
                      />
                  </Tooltip>
                  </Col>
              </Row>
              </>
        ),
      },
    ]


  return  <>
    <Row>
      <Col span={24} style={{ marginBottom: '2%' }}>
        <Col span={24}>
          <StyledAlignedTitleRow>
            <Col style={{ marginRight: '1%' }}>
              <StyledTitle>{t('menu.quizzes')}</StyledTitle>
            </Col>
            <Col style={{ marginBottom: '1%' }}>
                <Tooltip title={t('quizzes.buttons.tooltips.new_quiz')}>
                  <Button type='primary' onClick={onCreateClick}>
                    <PlusOutlined rev='' />
                    {t('quizzes.buttons.new_quiz')}
                  </Button>
                </Tooltip>
              </Col>
          </StyledAlignedTitleRow>
        </Col>
        <Col span={24}>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={quizzes?.data}
            rowKey='id'
            pagination={{
              
              position: ['bottomCenter'],
              total: (quizzes?.total || 0),
              current: (quizzes?.page || 0)+1,
              onChange: (page) => {
                moveToPage(page-1)
              }
            }}
          />
        </Col>
      </Col>
      <EditQuizzModal visible={editQuizzModalOpen} onClose={onCloseEditModal} quizById={quizzById} isLoading={isLoadingQuizzById} />
      {quizzById && <QuestionsQuizzModal 
                      allowEdit={new Date(quizzById.startDate) >= (new Date())}
                      visible={questionsQuizzModalOpen} 
                      onClose={onCloseQuestionsQuizzModal} 
                      quizzId={quizzById?.id} 
                      isLoading={isLoadingQuizzById} />}

      <CreateQuizzModal visible={createModalOpen} onClose={onCloseCreateModal} />
      <DeleteQuizzModal
          visible={deleteQuizzModal}
          onClose={onCloseDeleteClick}
          quizzId={deleteQuizzId}
          quizz={deleteQuizz}
        />
    </Row>

  </>
  }

export default Quizzes;

