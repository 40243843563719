import { Upload, message, Form } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

import { useTempImageUploader } from '@hooks'

const beforeUpload = (file: RcFile) => {
  const isValidFormat = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
  if (!isValidFormat) {
    message.error('You can only upload JPG/PNG/WEBP file!')
  }
  const isLt1M = file.size / 1024 / 1024 < 1
  if (!isLt1M) {
    message.error('Image must be smaller than 1MB!')
  }

  return isValidFormat && isLt1M
}

interface TShirtImagePickerProps {
  label: string
  name: string
  url?: string
  onChange: (name: string, url: string) => void
}
const TShirtImagePicker = (props: TShirtImagePickerProps) => {
  const { label, name, url = '', onChange } = props
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>(url)

  const { upload } = useTempImageUploader(
    url => {
      setLoading(false)
      setImageUrl(url)
      onChange(name, url)
    },
    () => {
      setLoading(false)
    },
  )
  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)

      return
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined rev={''} /> : <PlusOutlined rev={''} />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onUpload = options => {
    const { file } = options
    upload(file)
  }

  return (
    <Form.Item label={label} labelCol={{ span: 12 }}>
      <Upload
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={onUpload}
      >
        {imageUrl ? <img src={imageUrl} alt={name} style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    </Form.Item>
  )
}

export { TShirtImagePicker }
