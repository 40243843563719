import { useTranslation } from 'react-i18next'

import { BaseDeleteModal } from '@components'

import { PredictionDto } from '../../types'
import { PredictionsApi } from '../../hooks/api'
import { useOnDeleteSuccess } from '../../hooks/useOnDeleteSuccess'

interface DeletePredictionModalProps {
    visible: boolean
    onClose: () => void
    prediction: PredictionDto | undefined
}

const DeletePredictionModal = ({ visible, onClose, prediction }: DeletePredictionModalProps) => {
  const { t } = useTranslation()
  const { onDeleteSuccess } = useOnDeleteSuccess({ 
    categoryId: prediction?.leagueId.toString() ?? '', 
    round: prediction?.round ?? 0 })

  const { deletePrediction } = PredictionsApi


  return prediction?.id ? (
    <BaseDeleteModal<PredictionDto>
      visible={visible}
      onClose={onClose}
      itemId={prediction.id}
      title={t('predictions.modals.delete.title')}
      confirmDeleteText={`${t('predictions.modals.delete.delete_confirmation')} ${prediction.id} ${t(
        'predictions.modals.delete.delete_confirmation_end',
      )}`}
      request={
        async (id) => {
          await deletePrediction(id as number)
        }
      }
      onSuccess={onDeleteSuccess}
    />
  ) : null
}

export { DeletePredictionModal }
