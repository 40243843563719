import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Button, Col, Row, Table, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import { Title, TitleRow } from '@components'
import { PaginatedRequest } from '@types'

import { useWidgets } from '../../hooks/useWidgets'
import { Widget, WidgetsFilters } from '../../types'
import { WidgetPreview } from '../../components'
import { DeleteWidgetModal } from '../../modals/DeleteWidgetModal'

const WidgetsMain = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params, updateParams] = useState<PaginatedRequest<WidgetsFilters>>({ page: 0, filters: {} })
  const { data, isLoading } = useWidgets(params)
  const [itemToDelete, updateItemToDelete] = useState<Widget | undefined>(undefined)
  const columns = [
    {
      title: t('widgets.table_headers.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('widgets.table_headers.status'),
      key: 'status',
      render: widget => {
        return (
          <Col style={{ maxWidth: 600 }} span={16}>
            <Row gutter={4}>
              <b style={{ marginRight: 8 }}>Order:</b>
              <span>{widget.order}</span>
            </Row>
            <Row gutter={4}>
              <b style={{ marginRight: 8 }}>Enabled:</b>
              {widget.enabled ? (
                <CheckCircleOutlined rev='' style={{ color: 'rgb(38 198 23)' }} />
              ) : (
                <CloseCircleOutlined rev={''} style={{ color: 'red' }} />
              )}
            </Row>
            <Row>
              <b style={{ marginRight: 8 }}>Type:</b> {widget.type}
            </Row>
            <Row>
              <b style={{ marginRight: 8 }}>Show:</b> {widget.showType}
            </Row>
          </Col>
        )
      },
    },
    {
      title: t('widgets.table_headers.preview'),
      key: 'preview',
      render: widget => {
        return <WidgetPreview {...widget} />
      },
    },
    {
      title: t('widgets.table_headers.actions'),
      key: 'action',
      render: (widget: Widget) => (
        <>
          <Row>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip title={t('widgets.buttons.tooltips.edit')}>
                <Button
                  type='primary'
                  onClick={() => navigate(`/widgets/edit/${widget.id}`)}
                  icon={<EditOutlined rev='' />}
                />
              </Tooltip>
            </Col>
            <Col style={{ marginRight: '5px' }}>
              <Tooltip
                title={
                  widget.enabled ? t('widgets.buttons.tooltips.cant-delete') : t('widgets.buttons.tooltips.delete')
                }
              >
                <Button
                  disabled={widget.enabled}
                  type='primary'
                  danger
                  onClick={() => updateItemToDelete(widget)}
                  icon={<DeleteOutlined rev='' />}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ]

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '2%' }}>
          <Col span={24}>
            <TitleRow>
              <Col style={{ marginRight: '1%' }}>
                <Title>{t('menu.widgets')}</Title>
              </Col>
              <Col style={{ marginBottom: '1%' }}>
                <Tooltip title={t('media.buttons.tooltips.new')}>
                  <Button type='primary' onClick={() => navigate('/widgets/new')}>
                    <PlusOutlined rev='' />
                    {t('media.buttons.new')}
                  </Button>
                </Tooltip>
              </Col>
            </TitleRow>
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={data?.data}
              rowKey='id'
              pagination={{
                position: ['bottomCenter'],
                total: data?.total,
                current: (data?.page ?? 0) + 1,
                pageSize: data?.pageSize ?? 10,
              }}
              onChange={pagination => {
                if (pagination) {
                  updateParams({ ...params, page: pagination.current! - 1 })
                }
              }}
            />
          </Col>
        </Col>
        <DeleteWidgetModal visible={!!itemToDelete} onClose={() => updateItemToDelete(undefined)} item={itemToDelete} />
      </Row>
    </>
  )
}

export { WidgetsMain }
