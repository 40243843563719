import { api } from "@api"

import {  Challenge, ChallengeDto, ChallengeRequirementItemDto, ChallengeResponse } from "../types"

const create = async (payload: ChallengeDto) => {
    const response = await api.post<{ challenge: ChallengeDto }>('/challenges', {...payload, partnerId:1})
  
    return response.data
}

const update = async (payload: Challenge) => {
    const response = await api.put<{ challenge: Challenge }>(`/challenges/${payload.id}`, {...payload, partnerId:1})
  
    return response.data
}


const createChallengeRequirementItem = async (payload: ChallengeRequirementItemDto) => {
    const response = await api.post<{ challenge: ChallengeDto }>('/challenge-requirement-items', {...payload, partnerId:1})
  
    return response.data
}

const deleteChallenge = async (id: number) => {
    const response = await api.delete(`/challenges/${id}`)
  
    return response.data
}

const deleteChallengeRequirementItem = async (id: number) => {
    const response = await api.delete(`/challenge-requirement-items/${id}`)
  
    return response.data
}


const getChallenges = async (page: number) => {
    //console.log(page)
    const response = await api.get<ChallengeResponse>(`/challenges/?page=${page}`)
  
    return response?.data
}

const getChallenge = async (id: number) => {
    const response = await api.get<ChallengeDto>(`/challenges/${id}`)
  
    return response.data
}

//names
const getAllCollectibleNames = async () => {
    const response = await api.get<{name: string}[]>('collectibles/names')

    return response.data
}

//brands
const getAllCollectibleBrands = async () => {
    const response = await api.get<{ brand: number, name: string }[]>('collectibles/brands')

    return response.data
}

//teams
const getAllCollectibleTeams = async () => {
    const response = await api.get<{data: { id: number, name: string }[]}>('teams')

    return response.data
}

//countries
const getAllCollectibleCountries = async () => {
    const response = await api.get<{data: { id: number, name: string }[]}>('countries')

    return response.data
}

//years
const getAllCollectibleYears = async () => {
    const response = await api.get<{year: number}[]>('collectibles/years')

    return response.data
}

//signers
const getAllCollectibleSigner = async () => {
    const response = await api.get<{signer: string}[]>('collectibles/signers')

    return response.data
}

//seasons
const getAllCollectibleSeasons = async () => {
    const response = await api.get<{season: string}[]>('collectibles/seasons')

    return response.data
}

//itemTypes
const getAllCollectibleItemTypes = async () => {
    const response = await api.get<string[]>('collectibles/itemTypes')

    return response.data
}

//dorsals
const getAllCollectibleDorsals = async () => {
    const response = await api.get<{dorsal: string}[]>('collectibles/dorsals')

    return response.data
}

//equipmentTypes    
const getAllCollectibleEquipmentTypes = async () => {
    const response = await api.get<string[]>('collectibles/equipmentTypes')

    return response.data
}

export const ChallengesApi = {
    getChallenges, 
    getChallenge,
    create, 
    createChallengeRequirementItem,
    deleteChallenge, 
    getAllCollectibleBrands, 
    getAllCollectibleYears, 
    getAllCollectibleSigner, 
    getAllCollectibleSeasons, 
    getAllCollectibleItemTypes, 
    getAllCollectibleDorsals, 
    getAllCollectibleEquipmentTypes, 
    getAllCollectibleTeams,
    getAllCollectibleCountries,
    getAllCollectibleNames,
    deleteChallengeRequirementItem, 
    update
}

