import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { WidgetsApi } from '../../api'

export const useWidget = (id: string | number) => {
  const { data: widget, isLoading } = useQuery([QUERY_KEYS.WIDGET, id], () => WidgetsApi.getById(id))

  return { widget, isLoading }
}
