import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'

import { QUERY_KEYS } from '@api'

import { CollectiblesApi } from '../../api'

const useAssignOwner = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries(QUERY_KEYS.COLLECTIBLES_WITHOUT_OWNER)
    notification.success({
      message: t('collectibles.notifications.assign_success'),
    })
  }
  const { mutate: assignOwnerRequest, isLoading } = useMutation(CollectiblesApi.assignOwner, {
    onSuccess,
  })

  return { assignOwnerRequest, isLoading }
}
export { useAssignOwner }
