import { useQuery } from 'react-query'

import { QUERY_KEYS } from '@api'

import { CollectiblesApi } from '../../api'

const useCollectiblesWithoutOwner = (page: number) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.COLLECTIBLES_WITHOUT_OWNER, page],
    () => CollectiblesApi.getCollectiblesWithoutOwner(page),
    {
      refetchOnWindowFocus: false,
    },
  )

  return { data, isLoading }
}

export { useCollectiblesWithoutOwner }
