import { useState } from "react"
import { Button, Col, Row, Table, Tooltip } from "antd"
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"


import { StyledAlignedTitleRow, StyledTitle } from "../../components"
import { usePredictions } from "../../hooks/usePredictions"
import { NextMatchesModal } from "../../modals/NextMatchesModal"
import { DeletePredictionModal } from "../../modals/DeletePredictionModal"
import { PredictionDto } from "../../types"





export const PredictionsMain = () => {

    const [nextMatchesModalVisible, setNextMatchesModalVisible] = useState(false)
    const {predictions, isLoadingPredictions, moveToPage} = usePredictions()
    const[predictionToDelete, setPredictionToDelete] = useState<PredictionDto|undefined>(undefined)


    // useEffect(() => {
    //     //console.log(predictions)
    // }, [predictions])

    const { t } = useTranslation()
    
    const columns = [
        {
          title: t('predictions.table_headers.id'),
          dataIndex: 'id',
          key: 'id',

        },

        {
          title: t('predictions.table_headers.round'),
          dataIndex: 'round',
          key: 'round',
        },
        {
          title: t('predictions.table_headers.match'),          
          key: 'match',
          render: (text, record) => (
            <div>{record.localTeamName} vs {record.visitorTeamName}</div>
          ),
        },
        {
          title: t('predictions.table_headers.schedule'),
          key: 'startsAt',
          render: (text, record) => (
            <div>{new Date(record.startsAt).toLocaleString('es-ES', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' })}</div>
          ),
        },
        {
          title: t('predictions.table_headers.status'),
          key: 'status',
          render: (text, record) => (
            <div>
              {record.status.charAt(0).toUpperCase() + record.status.slice(1)}
            </div>
          ),
        },

        {
          title: t('common.action'),
          key: 'action',
          render: (text, item) => (
            <Row>
              <Col style={{ marginRight: '5px' }}>
                <Tooltip title={t('challenges.buttons.tooltips.delete')}>
                    <Button
                      type='primary'
                      danger
                      onClick={() => {
                        setPredictionToDelete(item)
                      }}
                      icon={<DeleteOutlined rev='' />}
                      disabled={new Date(item.startsAt) < new Date()}
                    />
                </Tooltip>
            </Col>
          </Row>
          ),
        },
    ]

    {if (isLoadingPredictions && !predictions) return <div>Loading...</div>}

    return (
      <>
      <Row>
          <Col span={24} style={{ marginBottom: '2%' }}>
            <Col span={24}>
              <StyledAlignedTitleRow>
                <Col style={{ marginRight: '1%' }}>
                  <StyledTitle>{t('menu.predictions')}</StyledTitle>
                </Col>
                <Col style={{ marginBottom: '1%' }}>
                  <Tooltip title={t('predictions.buttons.tooltips.new')}>
                    <Button type='primary' onClick={() => setNextMatchesModalVisible(true)}>
                      <PlusOutlined rev='' />
                      {t('predictions.buttons.new')}
                    </Button>
                  </Tooltip>
                </Col>
              </StyledAlignedTitleRow>
            </Col>
            <Col span={24}>
              <Table

                loading={isLoadingPredictions}
                columns={columns}

                dataSource={predictions?.data}
                rowKey='id'
                pagination={{
                  position: ['bottomCenter'],
                  total: (predictions?.total || 0),
                  current: (predictions?.page || 1),
                  onChange: (page) => {
                    console.log(predictions)
                    console.log(page)
                    moveToPage(page)
                   }

                }} />
            </Col>
          </Col>
        </Row>
        <NextMatchesModal 
          onClose={()=>{ setNextMatchesModalVisible(false) }} 
          visible={nextMatchesModalVisible} />
        <DeletePredictionModal
          visible={!!predictionToDelete}
          onClose={() => setPredictionToDelete(undefined)}
          prediction={predictionToDelete}
    />
      </>
  )
}

