import { WidgetContent } from '../styled'
import { ImageWidgetType } from '../../types'

const ImageWidget = (props: ImageWidgetType) => {
  const { imageUrl, actionTarget } = props

  const onClick = actionTarget ? () => open(actionTarget, '_blank') : undefined

  return (
    <WidgetContent onClick={onClick} style={{ padding: 0, overflow: 'hidden' }} bodyStyle={{ padding: 0 }}>
      <img src={imageUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt={'image widget'} />
    </WidgetContent>
  )
}
export { ImageWidget }
