import { Button, Table, TableProps } from 'antd'

import { WidgetContent } from '../styled'
import { GlobalRankingWidgetType } from '../../types'

interface DataType {
  key: string
  name: string
  position: number
  points: number
}

const columns: TableProps<DataType>['columns'] = [
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'Points',
    dataIndex: 'points',
    key: 'points',
  },
]

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    position: 1,
    points: 32,
  },
  {
    key: '2',
    name: 'Jim Green',
    position: 2,
    points: 21,
  },
  {
    key: '3',
    name: 'Joe Black',
    position: 3,
    points: 10,
  },
]
const GlobalRankingWidget = (props: GlobalRankingWidgetType) => {
  const { actionTarget, actionName, title } = props

  const onClick = actionTarget ? () => open(actionTarget, '_blank') : undefined

  return (
    <WidgetContent onClick={onClick}>
      <div style={{ width: '100%', position: 'relative' }}>
        <img
          src={'/images/ranking.jpg'}
          style={{ border: `1px solid #cecece`, borderRadius: 12, width: '100%' }}
          alt={'t-shirts'}
        />
        <div style={{ position: 'absolute', padding: 8, bottom: 0 }}>
          <h1 style={{ paddingBottom: 3, color: 'white', textTransform: 'uppercase' }}>
            <b>{title}</b>
          </h1>
        </div>
      </div>
      <Table<DataType>
        columns={columns}
        dataSource={data}
        pagination={false}
        style={{ borderRadius: 8, overflow: 'hidden', marginTop: 8, marginBottom: 8, background: 'white', padding: 4 }}
      />
      {!!actionName && !!onClick && (
        <Button style={{ marginTop: 8, fontWeight: 700, fontSize: 18, width: '100%' }} onClick={onClick}>
          {actionName}
        </Button>
      )}
    </WidgetContent>
  )
}
export { GlobalRankingWidget }
