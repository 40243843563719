import { notification, Spin } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  StyledContentContainer,
  StyledTitle,
  StyledSubtitle,
  StyledFormLabel,
  StyledInput,
  StyledConfirmButton,
} from '.'
import { useAuth } from '../../hooks'

export const PasswordStep = ({ current, next, setUpdateState }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const token = new URLSearchParams(search).get('token')
  const [passwordValue, setPasswordValue] = useState({
    firstPassword: '',
    confirmPassword: '',
  })
  const onUpdateSuccess = () => {
    setUpdateState(true)
    next()
  }
  const onUpdateError = () => {
    setUpdateState(false)
    notification.error({
      message: t('users.notifications.password_error'),
    })
    next()
  }

  const { updatePassword, isLoading: isUpdating } = useAuth()
  const handleConfirm = () => {
    const { confirmPassword: password } = passwordValue
    if (token) {
      updatePassword(
        { token, password },
        {
          onSuccess: onUpdateSuccess,
          onError: onUpdateError,
        },
      )
    }
  }
  
return (
    <StyledContentContainer>
      <StyledTitle className="steps-title">
        {t('public_web.placeholders.change_password')}
      </StyledTitle>
      <StyledSubtitle className="confirm-pass-input">
        {t('public_web.placeholders.add_new_password')}
      </StyledSubtitle>
      <StyledFormLabel htmlFor="contraseña">
        {t('public_web.placeholders.new_password')}
      </StyledFormLabel>
      <StyledInput
        className="web-input"
        feedback={false}
        toggleMask
        value={passwordValue.firstPassword}
        id="contraseña"
        onChange={e => setPasswordValue({ ...passwordValue, firstPassword: e.currentTarget.value })}
      />
      <StyledFormLabel style={{ marginTop: '10%' }} htmlFor="confirmar">
        {t('public_web.placeholders.confirm_password')}
      </StyledFormLabel>
      <StyledInput
        className="web-input"
        toggleMask
        feedback={false}
        value={passwordValue.confirmPassword}
        id="confirmar"
        onChange={e =>
          setPasswordValue({ ...passwordValue, confirmPassword: e.currentTarget.value })
        }
      />
      {current !== 2 && (
        <Spin
          spinning={isUpdating}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: '4%',
          }}
        >
          <StyledConfirmButton
            className={
              !passwordValue.firstPassword ||
              !passwordValue.confirmPassword ||
              passwordValue.firstPassword !== passwordValue.confirmPassword
                ? 'disabled'
                : ''
            }
            type="primary"
            onClick={handleConfirm}
            disabled={
              passwordValue.firstPassword !== passwordValue.confirmPassword ||
              !passwordValue.firstPassword ||
              !passwordValue.confirmPassword ||
              passwordValue.confirmPassword.length < 8 ||
              passwordValue.firstPassword.length < 8
            }
          >
            {t('public_web.placeholders.confirm')}
          </StyledConfirmButton>
        </Spin>
      )}
    </StyledContentContainer>
  )
}
