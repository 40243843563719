import { Button, Input, Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StyledRow } from '..'
import { useUsers } from '../../hooks'

interface CreateUserModalProps {
  visible: boolean
  onClose: () => void
}
interface FormState {
  username: string | undefined
  password: string | undefined
  firstName: string | undefined
  lastName: string | undefined
}

const CreateUserModal = ({ visible, onClose }: CreateUserModalProps) => {
  const { t } = useTranslation()
  const { createUser, isCreating } = useUsers()
  const [form, updateForm] = useState<FormState>({
    username: '',
    password: '',
    firstName: '',
    lastName: '',
  })
  useEffect(() => {
    updateForm({
      username: '',
      password: '',
      firstName: '',
      lastName: '',
    })
  }, [visible, onClose])

  return (
    <Modal
      open={visible}
      title={t('users.placeholders.new_user')}
      onCancel={onClose}
      maskClosable={false}
      closable={false}
      transitionName='ant-fade'
      focusTriggerAfterClose={false}
      footer={[
        <Button key='cancel' onClick={onClose}>
          {t('common.edit.button.cancel')}
        </Button>,
        <Button
          type='primary'
          key='update'
          onClick={() => {
            createUser(form)
            if (!isCreating) onClose()
          }}
          disabled={
            isCreating || form.firstName === '' || form.lastName === '' || form.username === '' || form.password === ''
          }
        >
          {t('common.edit.button.save')}
        </Button>,
      ]}
    >
      <Spin spinning={isCreating}>
        <StyledRow>
          <h3>{t('users.placeholders.first_name')}:</h3>
          <Input
            required
            autoComplete='false'
            placeholder={t('users.placeholders.first_name')}
            value={form.firstName}
            onChange={e => updateForm({ ...form, firstName: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.last_name')}:</h3>
          <Input
            required
            autoComplete='false'
            placeholder={t('users.placeholders.last_name')}
            value={form.lastName}
            onChange={e => updateForm({ ...form, lastName: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.username')}:</h3>
          <Input
            required
            autoComplete='false'
            type='email'
            placeholder={t('users.placeholders.username')}
            value={form.username}
            onChange={e => updateForm({ ...form, username: e.currentTarget.value })}
          />
        </StyledRow>
        <StyledRow>
          <h3>{t('users.placeholders.password')}:</h3>
          <Input.Password
            required
            autoComplete='false'
            placeholder={t('users.placeholders.password')}
            value={form.password}
            onChange={e => updateForm({ ...form, password: e.currentTarget.value })}
          />
        </StyledRow>
      </Spin>
    </Modal>
  )
}

export { CreateUserModal }
