import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import { QUERY_KEYS } from "@api"

import { PredictionsApi } from "../api"


export const useMatches = (categoryId: string, round: number) => {

    const [selectedCompetition, setSelectedCompetition] = useState<string>(categoryId)
    const [selectedRound, setSelectedRound] = useState<number>(round)



    useEffect(() => {
        console.log(`se cambió la competicion ${categoryId}`)
        setSelectedCompetition(categoryId)

    }, [categoryId])

    useEffect(() => {
        console.log(`se cambió el round ${round}`)
        setSelectedRound(round)
    }, [round])

    
    const getNextMatches = async () => {

        if (!selectedCompetition) return []
        if (!selectedRound || selectedRound === 0) return []   

        // console.log(`buscando partidos de la competición ${selectedCompetition}`)

        // const currentRound = await PredictionsApi.getCurrentRound(selectedCompetition)
        // const matchesCurrentRound = await PredictionsApi.getNextMatches(selectedCompetition, currentRound)
        // const matchesNextRound = await PredictionsApi.getNextMatches(selectedCompetition, (parseInt(currentRound) + 1).toString())

        // return [...matchesCurrentRound.match, ...matchesNextRound.match]

        console.log(`buscando partidos de la competición ${selectedCompetition} y la ronda ${selectedRound}`)

        const matches = await PredictionsApi.getNextMatches(selectedCompetition, selectedRound.toString())
        
        return matches.match ?? []
    }

    const { data: matches, isLoading: isLoadingMatches } = useQuery({
        queryKey: [QUERY_KEYS.PREDICTIONS_MATCHES, selectedCompetition, selectedRound],
        queryFn: async () => getNextMatches(),
        staleTime: 1000,

      })


      return {
        matches,
        isLoadingMatches,

    }
}