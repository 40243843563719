import * as React from 'react'
import { useState } from 'react'
import { Form, Input } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'

import { MediaPickerModal } from '../../modals'
import { Media, MediaType } from '../../types'

interface Props {
  validateStatus?: ValidateStatus
  help?: React.ReactNode
  name: string
  label: string
  media?: Media
  onChange: (media: Media | null) => void
  type?: MediaType
}
const MediaPickerInput = (props: Props) => {
  const { label, onChange, name, type, media, validateStatus, help } = props
  const [open, openModal] = useState(false)

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        validateStatus={validateStatus}
        help={help}
        key={'input'}
        valuePropName={'url'}
      >
        <Input value={media?.url} onClick={() => openModal(true)} readOnly={true} />
      </Form.Item>

      <MediaPickerModal
        type={type}
        onClose={() => openModal(false)}
        visible={open}
        maxMedia={1}
        onChange={m => onChange(m ? m.shift() ?? null : null)}
      />
    </>
  )
}

export { MediaPickerInput }
