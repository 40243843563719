import { api } from '@api'
import { PaginatedResponse } from '@types'

import { Collectible, DigitizeDto } from '../types'

const getCollectiblesWithoutOwner = async (page: string | number) => {
  const params = new URLSearchParams()
  params.set('page', `${page}`)
  params.set('pageSize', '50')
  const response = await api.get<PaginatedResponse<Collectible>>(`/collectibles/no-owner?${params.toString()}`)

  return response?.data
}

const create = async (payload: DigitizeDto) => {
  const response = await api.post<{ collectibles: Collectible[] }>('/collectibles/create', payload)

  return response.data
}

const deleteCollectible = async (id: string | number) => {
  await api.delete<void>(`/collectibles/${id}`)
}

const assignOwner = async (payload: { id: string | number; owner: string | number }) => {
  const { id, owner } = payload
  await api.put<void>(`/collectibles/${id}/assign`, {
    owner,
  })
}

export const CollectiblesApi = { getCollectiblesWithoutOwner, create, deleteCollectible, assignOwner }
