import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { TeamType } from '@types'

import { DigitizeForm } from '../../types'

const useDigitizeFormValidationSchema = () => {
  const { t } = useTranslation()

  // @ts-ignore
  const digitizeFormValidationSchema = yup.object<DigitizeForm>().shape({
    quantity: yup.number().min(1, t('collectibles.create.form.validation.quantity')),
    type: yup
      .mixed()
      .oneOf([TeamType.TEAM, TeamType.NATIONAL_TEAM], t('collectibles.create.form.validation.required'))
      .required(t('collectibles.create.form.validation.required')),
    team: yup.object().nonNullable().required(t('collectibles.create.form.validation.required')),
    brand: yup.string().required(t('collectibles.create.form.validation.required')),
    equipment: yup.string().required(t('collectibles.create.form.validation.required')),
    season: yup
      .string()
      .required(t('collectibles.create.form.validation.required'))
      .matches(/^[12][0-9]{3}\/[12][0-9]{3}$/, t('collectibles.create.form.validation.season')),
    year: yup
      .string()
      .required(t('collectibles.create.form.validation.required'))
      .matches(/^[12][0-9]{3}$/, t('collectibles.create.form.validation.year')),
    dorsal: yup
      .string()
      .matches(/^[0-9]+$/, t('collectibles.create.form.validation.dorsal'))
      .min(0, t('collectibles.create.form.validation.dorsal'))
      .max(3, t('collectibles.create.form.validation.dorsal')),
    name: yup.string(),
    isSigned: yup.boolean(),
    signer: yup.string().when('isSigned', {
      is: true,
      then: schema => schema.required(t('collectibles.create.form.validation.required')),
    }),
  })

  return { digitizeFormValidationSchema }
}

export { useDigitizeFormValidationSchema }
