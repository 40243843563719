import { api } from './axios'

const upload = async (blob: Blob) => {
  const form = new FormData()
  form.append('file', blob)
  const response = await api.post<string>('/challenges/upload', form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response.data
}

export const ChallengesApi = {
  upload,
}
