import { CSSProperties, useMemo } from 'react'

import { Media } from '../../types'

const MediaPreview = (props: {
  media: Media
  onClick?: () => void
  maxWidth?: number | string
  style?: CSSProperties
}) => {
  const { media, onClick, maxWidth, style } = props
  const preview = useMemo(() => {
    if (media.mimeType.startsWith('image')) {
      return <img src={media.url} width={maxWidth || 100} alt={`media ${media.id}`} />
    } else if (media.mimeType.startsWith('video')) {
      return (
        <video width={maxWidth || 240} controls>
          <source src={media.url} type={media.mimeType} />
        </video>
      )
    } else if (media.mimeType.startsWith('audio')) {
      return (
        <audio controls style={{ width: maxWidth || 240 }}>
          <source src={media.url} type={media.mimeType} />
        </audio>
      )
    } else {
      return (
        <a href={media.url} style={{ width: maxWidth || 240 }} target={'_blank'} title={'Copy'}>
          {media.url}
        </a>
      )
    }
  }, [media, maxWidth])

  return (
    <div
      style={{
        cursor: onClick ? 'pointer' : 'default',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...(style ?? {}),
      }}
      onClick={onClick}
    >
      {preview}
    </div>
  )
}

export { MediaPreview }
